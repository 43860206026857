<script>
import { mapState } from 'vuex';
import PFGwSModalComponent from '@/components/common/PFGwSModalComponent';

export default {
  name: 'PFGwSLogoComponent',
  data: () => ({
    GwSModal: false,
  }),
  components: {
    PFGwSModalComponent,
  },
  props: {
    growCountries: { type: Array, default: () => [] },
    localProfiles: { type: Array, default: () => [] },
    logoWidth: { type: Number, default: () => 120 },
    logoHeight: { type: Number, default: () => 46 },
    showCountryList: { type: Boolean, default: () => true },
    analyticsOrigin: { type: String, default: () => '' },
  },
  methods: {
    openModal() {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
      this.emitAdobeEvent(this.analyticsOrigin);
    },
    closeModal() {
      document.body.style.overflow = 'scroll';
      document.body.style.paddingRight = '0px';
    },
    emitAdobeEvent(name) {
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$eventBus.$emit('PFAACustomEvent', { eventName: 'ClickModal', custom2: `GwS_${name}` });
    },
  },
  computed: {
    ...mapState('fullData', {
      allCountries: (state) => state.allCountries,
    }),
    hasGrowCountries() {
      return this.growCountries?.length > 0;
    },
    countryNames() {
      return this.growCountries.map((c) => this.allCountries[c]);
    },
  },
};
</script>

<template>
  <div>
    <div
      automation-id="partnerProfilePage-logoGwS"
      class="GwS-container"
      @click="
        openModal();
        GwSModal = true;
      "
    >
      <img
        class="GwS-logo"
        :style="{ height: `${logoHeight}px`, width: `${logoWidth}px` }"
        src="@/assets/GwS_logo.png"
      />
    </div>

    <PFGwSModalComponent
      v-if="GwSModal"
      :growCountries="countryNames"
      :closePopup="
        () => {
          closeModal();
          GwSModal = false;
        }
      "
      :groupedAccount="localProfiles.length > 1"
      :showCountryList="showCountryList"
    />
  </div>
</template>

<style lang="less" scoped>
@import "../../assets/css/common.less";

.GwS-container {
  display: block !important;
  cursor: pointer;
  .GwS-logo {
    width: 120px;
    height: 46px; // 52
    position: relative;
    transition: all 0.2s ease;
  }
  .GwS-logo:hover {
    transform: scale(1.065);
  }
}
</style>
