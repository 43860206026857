<script>
import { mapState } from 'vuex';

export default {
  name: 'MultiSelectCheckboxDropDownComponent',
  props: {
    activeOptions: { type: [Array, Object], default: () => [] },
    disabledOptions: { type: [Array, Object], default: () => [] },
    filterType: { type: String, default: '' },
    highlightText: { type: Function, default: () => true },
    isShown: { type: Boolean, default: false },
  },
  computed: {
    ...mapState('profileSearch', {
      loading: (state) => state.loading,
    }),
    optionKey() {
      if (this.filterType === 'designation') {
        return 'designationDescr';
      }
      return 'title';
    },
  },
  data: () => ({
    canBePushedToStore: false,
  }),
  methods: {
    selectOption(_option) {
      this.$store.dispatch('profileSearch/setPathChangedFromUiFlag', true);
      const option = { ..._option };

      if (option.isSelected) {
        option.optionType = this.filterType;
        this.$store.dispatch('profileSearch/uncheckOption', option);
      } else {
        this.$store.dispatch('profileSearch/addSelectedOption', { optionType: this.filterType, value: option });
      }
      this.$store.commit('profileSearch/toggleOptionSelectedStatus', option);
    },
  },
  watch: {
    isShown() {
      this.$nextTick(() => {
        this.$refs.dsDropdown.scrollTop = 0;
      });
    },
    loading(val, oldVal) {
      if (val) {
        this.$refs.dsDropdown.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    },
  },
  created() {
    if (this.filterType !== 'designation') {
      this.$store.commit('profileSearch/updateFilterDistributionsByType', this.filterType);
    }
  },
};
</script>

<template>
  <div class="ds-dropdown__options-wrapper" ref="dsDropdown">
    <label
      v-for="shownOption in this.activeOptions"
      :for="shownOption.title"
      v-bind:key="shownOption.title"
      class="ds-checkbox-multiselect ds-checkbox"
      @click="selectOption(shownOption)"
      v-show="shownOption.isVisible !== false"
    >
      <input
        :id="shownOption.title"
        type="checkbox"
        class="ds-checkbox__control"
        :checked="shownOption.isSelected"
        v-bind:value="shownOption"
        @click.stop.prevent
      />
      <span class="ds-checkbox__box search-drop-down-margin">
        <i class="ds-icon ds-icon--checked"></i>
      </span>
      <span class="ds-checkbox__info ds-checkbox__label">
        <span
          class="ds-checkbox__title"
          v-html="highlightText(shownOption[optionKey])">
        </span>
        <div
          v-if="shownOption.description"
          class="ds-checkbox__description">
          {{ highlightText(shownOption.description) }}
        </div>
      </span>
    </label>
    <div v-show="this.activeOptions.length === 0" class="ds_no_result">
        {{$t('filter.no.results')}}
    </div>
    <!-- <hr
      v-if="disabledOptions && disabledOptions.length !== 0 && activeOptions && activeOptions.length !== 0"
    /> -->
    <label
      :for="disabledOption.title"
      class="ds-checkbox ds-checkbox-multiselect disabled-selection"
      v-for="disabledOption in disabledOptions"
      :key="disabledOption.title"
      v-show="disabledOption.isVisible !== false"
    >
      <input
        :id="disabledOption.title"
        type="checkbox"
        class="ds-checkbox__control"
        :disabled="true"
        @click.stop
      />
      <span class="ds-checkbox__box search-drop-down-margin disabled-selection-checkbox">
        <i class="ds-icon ds-icon-checked"></i>
      </span>
      <span class="ds-checkbox__info ds-checkbox__label">
        <span
          class="ds-checkbox__title ds-checkbox__title_disabled"
          v-html="highlightText(disabledOption[optionKey])">
        </span>
        <div
          v-if="disabledOption.description"
          class="ds-checkbox__description">
          {{ highlightText(disabledOption.description) }}
        </div>
      </span>
    </label>
  </div>
</template>
<style lang="less" scoped>
.ds-checkbox-multiselect {

  position:relative  !important;

}
.search-drop-down-margin {
  margin-left: 6px;
}
.disabled-selection {
  cursor: not-allowed;
}
.disabled-selection-checkbox {
  background-color: #eaeaea !important;
}
</style>
