<script>
import { mapState } from 'vuex';
import { useScreenSize } from '@/composables/useScreenSize';
import PFFilterConfigurationBarComponent from '../components/partnerNavigatorPage/PFFilterConfigurationBarComponent';
import PFBadgeBarComponent from '../components/common/PFBadgeBarComponent';
import PFFilterSortBarComponent from '../components/partnerNavigatorPage/PFFilterSortBarComponent';
import PFCardItemContainer from '../components/common/PFCardItemContainer';
import PFDisclaimerComponent from '@/components/common/PFDisclaimerComponent';

const IE_BROWSER_PAGE = 'browserNotSupportedPage';

export default {
  name: 'PFPartnerNavigatorPage',
  components: {
    PFFilterConfigurationBarComponent,
    PFBadgeBarComponent,
    PFFilterSortBarComponent,
    PFCardItemContainer,
    PFDisclaimerComponent,
  },
  setup() {
    const { isScreenSizeMoreSM, isScreenSizeMoreXL } = useScreenSize();
    return {
      isScreenSizeMoreSM,
      isScreenSizeMoreXL,
    };
  },

  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState('profileSearch', {
      pathChangedFromUiFlag: (state) => state.pathChangedFromUiFlag,
    }),
    ...mapState('systemMaintenance', {
      enableMaintenanceBanner: (state) => state.enableMaintenanceBanner,
    }),
  },
  beforeCreate() {
    if (/Trident\/|MSIE /i.test(navigator.userAgent)) {
      this.$router
        .replace({
          name: IE_BROWSER_PAGE,
        })
        .catch(() => {
        });
    }
  },
  watch: {
    $route: {
      handler(newValue, oldValue) {
        if (oldValue?.fullPath !== newValue.fullPath && newValue.name != 'home') {
          if (this.pathChangedFromUiFlag) {
            this.$store.dispatch('profileSearch/setPathChangedFromUiFlag', false);
          } else if (!newValue.path.startsWith('/profile/')) {
            this.$store.dispatch('profileSearch/restoreSelection');
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    closeDisclaimer() {
      this.$store.commit('systemMaintenance/setEnableMaintenanceBanner', false);
    },
  },
};
</script>

<template>
<div class="ds_filter-wrapper">
    <div class="ds_filter-container">
      <div class="ds-filter-sort">
        <PFFilterConfigurationBarComponent v-if="isScreenSizeMoreXL"/>
        <PFDisclaimerComponent v-show="enableMaintenanceBanner" :closeDisclaimer="closeDisclaimer"/>
        <div class="ds-filter-tocken-bar">
          <PFFilterSortBarComponent/>
          <div class="ds-filter__tockens-set" v-if="isScreenSizeMoreSM">
            <PFBadgeBarComponent/>
          </div>
        </div>
      </div>
      <PFCardItemContainer/>
    </div>
</div>
</template>

<style lang="less">

.ds_filter-wrapper{
  display: flex;
  width: 100%;
  justify-content: center;
}
.ds_filter-container {
  width: 1432px;
}

.ds-filter__tockens-set {
  width: 100%;
  margin: 16px auto 0 auto;
}
.ds-filter-tocken-bar{
  margin-top: 28px;
}
.ds_filter-container{
  margin: 0 24px
}
@media (min-width:640px) {
  .ds_filter-container{
    margin:  0 48px
  }
  .ds-filter-tocken-bar{
    margin-top: 20px
  }
}
@media (min-width:980px) {
  .ds_filter-container{
    margin:0 72px
  }
  .ds-filter-tocken-bar{
    margin-top: 20px;
  }
}
@media (min-width:1280px) {
  .ds_filter-container{
    margin:0 72px
  }
  .ds-filter-tocken-bar{
    margin-top: 32px;
  }
}
@media (min-width:1600px) {
  .ds_filter-container{
    margin:0 84px
  }
  .ds-filter-tocken-bar{
    margin-top: 32px;
  }
}
</style>
