<script>
import urlLinks from '../../plugins/linkUrl';

import '@sapudex/web-components/dist/Modal.js';
import '@sapudex/web-components/dist/Button.js';
import '@ui5/webcomponents-fiori/dist/Bar.js';
import '@sapudex/web-components/dist/ControlButton.js';

const CountryAmount = {
  minumum: 5,
  maximum: 25,
};

export default {
  name: 'PFGwSModalComponent',
  data: () => ({
    learnMore: urlLinks.HELP_TEXT_LEARN_MORE.GROW_WITH_SAP,
    maxWidth: 544,
    isOverflownFlag: false,
  }),
  props: {
    growCountries: { type: Array, default: () => [] },
    closePopup: { type: Function, default: () => {} },
    groupedAccount: { type: Boolean, default: false },
    showCountryList: { type: Boolean, default: true },
  },
  methods: {
    getColumnClassOnCountriesCount(countriesList) {
      if (countriesList?.length > CountryAmount.minumum && countriesList?.length <= CountryAmount.maximum) {
        return 'countries-list--column-2';
      }
      if (countriesList?.length > CountryAmount.maximum) {
        return 'countries-list--column-3';
      }
      return 'countries-list--column-1';
    },
    countryItem(idx, growCountries, country) {
      if (growCountries.length === 1) {
        return `${country}.`;
      }
      if (idx === growCountries.length - 1) {
        return `${this.$t('and')} ${country}.`;
      }
      if (idx === growCountries.length - 2) {
        return `${country} `;
      }
      return `${country}, `;
    },
  },
  computed: {
    maxWidthStyle() {
      return `${this.maxWidth}px`;
    },
    sortedCountries() {
      return [...this.growCountries].sort((a, b) => a.localeCompare(b));
    },
    growCountriesColumnClassOnCount() {
      return this.getColumnClassOnCountriesCount(this.growCountries);
    },
  },
};
</script>

<template>

<udex-modal automation-id="profileRecognitionModal" type="defalt" :open="true"
  @keydown.esc="closePopup">
          <ui5-bar class="header" slot="header" design="Header" data-sap-ui-fastnavgroup="true">
            <h3  automation-id="profileRecognitionModal-header" class="HeadingMediumXS-size" slot="startContent">{{$t('grow.title')}}</h3>
            <udex-control-button
            @click="closePopup"
             automation-id="profileRecognitionModal-closeDialog"
              class="closeDialog"
              icon="decline"
              slot="endContent"
              icon-only
              has-icon
              accessible-name="close"
            ></udex-control-button>
          </ui5-bar>

            <div class="solution-container">
              <div automation-id="profileRecognitionModal-container" class="grow-container" ref="growContainer">
                <div class="solution-section-title">
                  <img automation-id="profileRecognitionModal-logo" class="GwS-logo" src="@/assets/GwS_logo.png">
                </div>
                <p class="grow-p body-s-size" automation-id="profileRecognitionModal-description">
                  {{$t('grow.p1')}}
                  <udex-link :href="learnMore" target="_blank"  accessibleRole="Link">
                    {{$t('learn.more')}}
                  </udex-link>                
                </p>
                <!-- only show country list if profile is group profile -->
                <div v-if="groupedAccount && showCountryList" class="grow-p body-s-size" id="grow-p2">
                  <span ref="countriesLabel" automation-id="profileRecognitionModal-descriptionCountryList">{{ $tc('grow.p2', sortedCountries.length) }}&nbsp;</span>
                  <ul :class="growCountriesColumnClassOnCount" class="countries-list country-list-padding"  automation-id="profileRecognitionModal-countryList">
                    <li v-for="(country) in sortedCountries" :key="country">{{country}}</li>
                  </ul>
                </div>
              </div>
            </div>
          <ui5-bar class="footer" slot="footer" design="Footer" data-sap-ui-fastnavgroup="true">
            <udex-button automation-id="profileRecognitionModal-closeButton"  @click="closePopup" design="Emphasized" size="Large" slot="endContent">
              {{ $t("button.close") }}
            </udex-button>
          </ui5-bar>
        </udex-modal>

</template>

<style lang="less" scoped>
#grow-p2{
  margin-top: 16px
}
.countries-list {
  list-style-type: none;
  column-gap: 20px;
  &--column-1 {
    column-count: 1;
  }
  &--column-2 {
    column-count: 2;
  }
  &--column-3 {
    column-count: 3;
  }
}
.countries-list li::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M0 5 L6 5" stroke="%23007DB8" stroke-width="1" fill="transparent"/></svg>');
  background-repeat: no-repeat;
}
.countries-list li {
  padding-left: 15px;
  text-indent: -15px;
}

.solution-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.grow-container {
  max-width: v-bind('maxWidthStyle');
}

.grow-p {
  margin-bottom: 0px;

}
.solution-section-title {
  margin-bottom: 20px;
  .GwS-logo {
    height: 54px;
  }
  text-align: center;
}

.country-list-padding {
  padding-left: 23px;
}

 .closeDialog {
  --udex-button-icon-l-size: 24px;
  --udex-button-color-tertiary-standard-default-text-and-icon: var(--udexColorGrey7, #475E75);
}

 .footer udex-button {
  margin: 0 0 0 16px;
}

 ui5-bar::part(bar) {
  /* Alternativelly header and footer paddings can be reduced to utilize 'ui5-bar' paddings.
  In this case overwrite following variables:
    --udexModalAndOverlayModalFooterPaddingHorizontal
    --udexModalAndOverlayModalHeaderPaddingHorizontal
  */
  margin-left: -12px;
  margin-right: -12px;
  width: auto;
}

 h3 {
  margin: 0px;
}
@media (max-width: 600px) {
  .countries-list {
    column-count: 1;
  }
}
</style>
