<script>

import { mapState } from 'vuex';
import PFGwSLogoComponent from './PFGwSLogoComponent';
import PEPlatinumPopupComponent from '@/components/editor/popup/PEPlatinumPopupComponent';
import PFRiseWithSAPComponent from '@/components/common/PFRiseWithSAPComponent';

export default {
  name: 'PFRecognitionsComponent',
  data: () => ({
    partnerLevelModal: false,
  }),
  components: {
    PEPlatinumPopupComponent,
    PFGwSLogoComponent,
    PFRiseWithSAPComponent,
  },
  props: {
    growCountries: { type: Array, default: () => [] },
    localProfiles: { type: Array, default: () => [] },
  },
  methods: {
    openModal() {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
    },
    closeModal() {
      document.body.style.overflow = 'scroll';
      document.body.style.paddingRight = '0px';
    },
    emitSWAEvent(name) {
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$eventBus.$emit('PFAACustomEvent',
        { eventName: 'ClickModal', custom2: name });
    },
  },
  computed: {
    ...mapState('partnerProfile', {
      level: (state) => state.profileData.level,
      goldCountries: (state) => state.profileData.goldCountries,
      platinumCountries: (state) => state.profileData.uvarPlatinumCountries,
      solutionTransformed: (state) => state.solutionTransformed,
      isRiseValidated: (state) => state.profileData.isRiseValidated,
    }),
    showPlatinumLogo() {
      return this.level === 'PLATINUM';
    },
    showGoldLogo() {
      return this.level !== 'PLATINUM' && this.goldCountries && this.goldCountries.length > 0;
    },
    hasGrowCountries() {
      return this.growCountries?.length > 0;
    },
    ERPObject() {
      return this.solutionTransformed?.find((s) => s.solutionL1Key === 'PFERP');
    },
    hasCompetencyPrivateS4() {
      if (this.ERPObject && this.ERPObject.specializations) {
        const privateS4 = this.ERPObject?.specializations?.find((L2) => L2.solutionL2Key === 'PFERPHCPE');
        if (privateS4 && privateS4.level && privateS4.level !== 'Zero') {
          return true;
        }
      }
      return false;
    },
    showRecognitions() {
      return this.showGoldLogo || this.showPlatinumLogo || this.hasGrowCountries || this.isRiseValidated;
    },
  },
  watch: {
    showRecognitions: {
      handler(newValue) {
        this.$emit('update-recognitions-visible', newValue);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<template>
<div class="recognitions">
  <div automation-id="partnerProfilePage-recognitionsHeader" class="recognitions-header" v-if="showGoldLogo || showPlatinumLogo || hasGrowCountries || isRiseValidated">
    {{ $t('profile.recognitions.title') }}
  </div>
  <div automation-id="partnerProfilePage-partnerLevelContainer" class="GwS-level-container">
    <PFRiseWithSAPComponent v-if="isRiseValidated"/>
    <PFGwSLogoComponent v-if="hasGrowCountries"
    :growCountries="growCountries"
    :localProfiles="localProfiles"
    :analyticsOrigin="'At_A_Glance'"/>

    <div automation-id="partnerProfilePage-partnerLevel" class="partner-level--container" v-if="showPlatinumLogo"
      @click="openModal(); partnerLevelModal = true; emitSWAEvent('At_A_Glance_Medallion')">
      <img class="partner-level--logo" src="@/assets/SAP_PlatinumPartner_R.png">
    </div>
    <div automation-id="partnerProfilePage-partnerLevel" class="partner-level--container" v-else-if="showGoldLogo"
      @click="openModal(); partnerLevelModal = true; emitSWAEvent('At_A_Glance_Medallion')">
      <img class="partner-level--logo" src="@/assets/SAP_GoldPartner_grad_R.png">
    </div>
    <PEPlatinumPopupComponent v-if="partnerLevelModal"
      :closePopup="() => { closeModal(); partnerLevelModal = false; }"
      :goldCountries="goldCountries"
      :platinum-countries="platinumCountries"
      :showGoldLogo="showGoldLogo"
      :groupedAccount="localProfiles.length > 1"/>
  </div>
</div>
</template>

<style lang="less" scoped>
@import "../../assets/css/common.less";

.recognitions {
  display: -moz-box;
  display: -webkit-box;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  min-height: 0 !important;
  cursor: default;
}

.GwS-level-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 16px;
}

.padding-block {
  width: 20px;
}

.partner-level--container {
  width: 78px;
  height: 46px;
  cursor: pointer;
  .partner-level--logo {
    width: 78px;
    height: 46px;
    position: relative;
    transition: all 0.2s ease;
  }
  .partner-level--logo:hover {
    transform: scale(1.065)
  }
}

</style>
