<script>
// TODO Delete this component later. PFSearchBarComponent is now used instead in the home page.
import { mapState } from 'vuex';
import PFDropdownsFilterComponent from '../partnerNavigatorPage/PFDropdownsFilterContainer';
import PFSearchBarComponent from '../common/PFSearchBarComponent/PFSearchBarComponent';
import AutocompleteComponent from '../common/PFSearchBarComponent/AutocompleteComponent.vue';

const PARTNER_NAME = 'Partner Name';
const KEYWORD = 'Keyword';
const PARTNER_NAVIGATOR_PAGE = 'partnerNavigatorPage';
const DEFAULT_QUERY_TERM_TYPE = 'partner';
const KEYWORD_QUERY_TYPE = 'keyword';
const GUIDED_REQUEST_TYPE = 'results/count';

export default {
  name: 'PFGuidedSearchComponent',
  props: {

  },
  components: {
    PFDropdownsFilterComponent,
    PFSearchBarComponent,
    AutocompleteComponent,
  },
  data: () => ({
    timer: null,
    focused: false,
    showDropdown: false,
    updatedData: '',
    selectedItemsIdx: -1,
    keyCodes: {
      END: 35,
      HOME: 36,
      SHIFT: 16,
      COMMAND: 91,
      OPTION: 18,
      CONTROL: 17,
      DOWN: 40,
      ENTER: 13,
      ESCAPE: 27,
      LEFT: 37,
      RIGHT: 39,
      TAB: 9,
      UP: 38,
      CONTINUOUS_KEY_PRESSING: 229,
    },
  }),
  beforeUnmount() {
    clearTimeout(this.timer);
  },
  computed: {
    ...mapState('autocomplete', {
      searchResults: (state) => state.searchResults,
      showSearchSuggestion: (state) => state.showSearchSuggestion,
      fromHomePage: (state) => state.fromHomePage,
    }),
    ...mapState('profileSearch', {
      countResults: (state) => state.countResults,
    }),
    lastUsedQuery: {
      get() {
        return this.$store.state.autocomplete.lastUsedQuery;
      },
    },
    query() {
      return this.$store.state.profileSearch.query;
    },
    groupedSearchResults() {
      const titleItems = this.searchResults.filter((result) => result.title === 'title');
      const suggestionItems = this.searchResults.filter((result) => result.title !== 'title');
      return suggestionItems.concat(titleItems);
    },
    availableOption() {
      return this.$store.state.profileSearch.queryTermTypeState === DEFAULT_QUERY_TERM_TYPE
      || this.$store.state.profileSearch.queryTermTypeState === '' ? KEYWORD : PARTNER_NAME;
    },
    currentOption() {
      return this.$store.state.profileSearch.queryTermTypeState === KEYWORD_QUERY_TYPE ? KEYWORD : PARTNER_NAME;
    },
    hasText() {
      return this.query?.length > 0;
    },
  },
  watch: {
    query(val, oldVal) {
      if (oldVal != null && this.focused) {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.triggerSearchSuggestion();
          this.$store.commit('profileSearch/changeTriggeredQuery', val);
          this.$store.dispatch('profileSearch/setPathChangedFromUiFlag', true);
          this.$store.dispatch('profileSearch/homeSearchAddRouterQuery',
            {
              query: val,
              guidedSearch: true,
            });
        }, 1000);
      }
    },
  },
  methods: {
    closeDropDownList() {
      this.showDropdown = false;
      this.$emit('dropDownStateChange', this.showDropdown);
    },
    changeCurrentOption() {
      this.$store.commit('profileSearch/updateQueryTypeState', this.availableOption === KEYWORD ? KEYWORD_QUERY_TYPE : DEFAULT_QUERY_TERM_TYPE);
      this.closeDropDownList();
      // if (this.currentOption === PARTNER_NAME) {
      //   this.$store.dispatch('autocomplete/keyPressed', this.query);
      // }
      this.$store.dispatch('autocomplete/keyPressed', this.query);
      this.$store.commit('profileSearch/changeTriggeredQuery', this.query);
      this.$store.dispatch('profileSearch/setPathChangedFromUiFlag', true);
      this.$store.dispatch('profileSearch/homeSearchAddRouterQuery',
        { query: this.query, guidedSearch: true });
    },
    dropDownClicked() {
      this.showDropdown = !this.showDropdown;
      this.$emit('dropDownStateChange', this.showDropdown);
    },
    submitForm() {
      this.$store.dispatch('profileSearch/homeSearchAddRouterQuery',
        {
          query: this.query,
          guidedSearch: false,
        });
    },
    isNotCurrentPath() {
      return !(this.$router.currentRoute.value.name === PARTNER_NAVIGATOR_PAGE);
    },
    focus() {
      this.focused = true;
      this.triggerSearchSuggestion();
    },
    unfocus() {
      this.focused = false;
    },
    clearForm() {
      this.query = '';
    },
    updateQuery(event) {
      this.$store.dispatch('profileSearch/setQueryWithoutSearch', event.target.value);
      this.updatedData = event.target.value;
    },
    changeUpdatedData(newValue) {
      this.updatedData = newValue;
    },
    openSearchSuggestion() {
      this.$store.dispatch('autocomplete/openSearchSuggestion');
    },
    triggerSearchSuggestion() {
      if (this.$store.state.autocomplete.searchResults.length === 0) {
        this.$store.dispatch('autocomplete/getAutocompleteResults');
      } else {
        this.openSearchSuggestion();
      }
    },
    closeSearchSuggestion() {
      this.$store.dispatch('autocomplete/setShowSearchSuggestion', false);
    },
    onMagnifierClick() {
      this.$store.commit('profileSearch/changeTriggeredQuery', this.query);
      if (this.groupedSearchResults[this.selectedItemsIdx]
        && this.groupedSearchResults[this.selectedItemsIdx].title === 'title') {
        this.$store.commit('profileSearch/setSearchPartner', true);
      }
      this.clearFilter();
      this.discardSelected();
      this.$store.commit('autocomplete/setResults', []);
      const dataPayload = this.updatedData ? this.updatedData : '';
      this.$store.commit('autocomplete/setSearchSuggestion', false);
      if (this.isNotCurrentPath()) {
        this.$store.dispatch(
          'profileSearch/setQueryWithoutSearch',
          dataPayload,
        );
        this.$store.commit('profileSearch/setSearchFromHomePage', true);
      } else {
        this.$store.dispatch('profileSearch/setQuery', dataPayload);
      }
      this.submitForm();
      this.$store.dispatch('autocomplete/abortController');
      this.$store.commit('autocomplete/setFromHomePage', true);
      this.$eventBus.$emit('PFAACustomEvent',
        {
          eventName: 'SearchInitiated', custom2: this.query, custom3: window.location.search, custom4: true,
        });
    },
    discardSelected() {
      this.selectedItemsIdx = -1;
    },
    clearFilter() {
      this.$store.commit('profileSearch/cleanFilters');
      this.$store.commit('profileSearch/cleanResults');
      this.$store.commit('profileSearch/setQuery', null);
      this.$store.commit('profileSearch/setPageNumber', 1);
    },
    clearSearchField() {
      this.$store.commit('profileSearch/changeTriggeredQuery', '');
      this.$store.dispatch('autocomplete/clearResults');
      // as computed properties are cached we have to "update" them.
      if (this.query === '') {
        this.$store.commit('profileSearch/cleanFilters');
        this.$store.commit('profileSearch/setQuery', null);
      }
      if (this.isNotCurrentPath()) {
        this.$store.dispatch('profileSearch/setQueryWithoutSearch', '');
        this.updatedData = '';
        this.$store.dispatch('profileSearch/setPathChangedFromUiFlag', true);
        this.$store.dispatch('profileSearch/homeSearchAddRouterQuery',
          {
            query: this.query,
            guidedSearch: true,
          });
      } else {
        this.$store.commit('profileSearch/cleanFilters');
        this.$store.dispatch('profileSearch/setQuery', '');
      }
      this.discardSelected();
    },
    up() {
      if (!this.showSearchSuggestion) {
        return;
      }
      if (this.selectedItemsIdx === 0) {
        this.selectedItemsIdx--;
        this.$store.dispatch('profileSearch/setQueryWithoutSearch', this.lastUsedQuery);
        return;
      }
      if (this.selectedItemsIdx === -1) {
        this.selectedItemsIdx = this.searchResults.length - 1;
      } else {
        this.selectedItemsIdx--;
      }
      this.$store.dispatch('profileSearch/setQueryWithoutSearch',
        this.groupedSearchResults[this.selectedItemsIdx].value);
      this.updatedData = this.groupedSearchResults[this.selectedItemsIdx].value;
    },
    down() {
      if (!this.showSearchSuggestion) {
        return;
      }
      if (this.selectedItemsIdx === this.searchResults.length - 1) {
        this.discardSelected();
        this.$store.dispatch('profileSearch/setQueryWithoutSearch', this.lastUsedQuery);
        return;
      }
      this.selectedItemsIdx++;
      this.$store.dispatch('profileSearch/setQueryWithoutSearch',
        this.groupedSearchResults[this.selectedItemsIdx].value);
      this.updatedData = this.groupedSearchResults[this.selectedItemsIdx].value;
    },
    inputKeyup(event) {
      const isSpecialKey = Object.values(this.keyCodes)
        .findIndex((value) => value === event.keyCode) !== -1;
      if (isSpecialKey) {
        return;
      }
      this.discardSelected();
      // if (this.currentOption === PARTNER_NAME) {
      //   this.$store.dispatch('autocomplete/keyPressed', event.target.value);
      // }
      this.$store.dispatch('autocomplete/keyPressed', event.target.value);
    },
  },
};
</script>

<template>
    <div class="guide-search-container">
      <div class="guide-search-content">
        <div class="ds-select-box guide-dropdown"
        v-click-outside="closeDropDownList">
            <label class="ds-textField ds-select-box__wrapper guide-dropdown__wrapper">
                <input id="selectbox" class="ds-textField__input ds-select-box__wrapper__input" type="text"
                autocomplete="off"
                @click="dropDownClicked"/>
                <div class="ds-combo-box__placeholder">
                    <span class="editlist__placeholder__text home-filter-placeholder">
                      <span>{{currentOption}}</span>
                    </span>
                </div>
                <i class="ds-select-box__wrapper__icon--down ds-icon--dropdown down-arrow"
                :class="showDropdown ? 'rotate-180' : ''"></i>
                <div class="ds-select-box__wrapper__dropdown dropdown-item"
                :class="showDropdown ? 'display-block' : 'display-none'">
                <div class="ds-dropdown__inner"
                @click.prevent="changeCurrentOption">
                    <div class="ds-dropdown__inner__option option-text">{{availableOption}}</div>
                </div>
                </div>
            </label>
        </div>
        <div class="ds-textField guide-text color-selected-border"
        v-click-outside="closeSearchSuggestion">
                <input class="ds-textField__input" type="text" id="text-field" placeholder="(Optional)"
                ref="inputText"
                autocomplete="off"
                :value="query"
                @input="updateQuery"
                @keyup="inputKeyup"
                @keyup.esc="clearSearchField"
                @keydown.enter="onMagnifierClick"
                @keydown.up.prevent="up"
                @keydown.down.prevent="down"
                @focus="focus"
                @blur="unfocus"/>
              <i @click.prevent="clearSearchField" class="ds-icon ds-icon--clear clear-icon" :class="hasText ? 'display-block' : 'display-none'"></i>
              <i @click.prevent="onMagnifierClick" class="ds-icon ds-icon--search search-icon"></i>
        </div>
        <AutocompleteComponent
        @submit-form-handler="submitForm"
      />
      </div>
    </div>
</template>

<style scoped lang="less">
.guide-dropdown__wrapper{
  cursor: pointer;
}
.ds-select-box__wrapper__dropdown {
  -webkit-box-shadow: unset;
  box-shadow: unset;
}
.ds-select-box__wrapper {
  height: 100%;
}
.pf-autocomplete-menu-wrapper {
  position: absolute !important;
  top: 44px;
  max-width: 490px !important;
}
.blue-border-focused {
  border-color: #007db8 !important;
}
.rotate-180 {
  display: block !important;
  transform: matrix(1,0,0,-1,0,0);
}
.ds-dropdown__inner__option {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.ds-dropdown__inner__option:hover {
  background-color: #EAEAEA;
}
.dropdown-item {
    max-width: 170px !important;
    min-width: 10rem !important;
}
.option-text {
    width: 170px;
    height: 40px;
    font-weight: 390;
    font-size: 19px;
    line-height: 21px;
}
.down-arrow {
  display: block !important;
  top: 16px;
  color: #727272;
}
.guide-search-container {
  max-width: 1680px;
  margin-left: auto;
  margin-right: auto;
}
.guide-search-content {
  background-color: #ffffff;
  display: flex;
  height: 44px;
  max-width: 490px;
}
#selectbox {
  cursor: pointer;
  border-right: unset;
}
.ds-textField__input {
    border: unset;
    padding-right: 20%;
    min-height: 2.5rem;
    height: 100%;
}
.ds-textField__input::placeholder {
  width: 89px;
  height: 21px;
  &:extend(.font-expanded);
  font-size: 19px;
  line-height: 21px;

  color: #969696;
}
.ds-textField__input:focus::placeholder {
  color: transparent;
}
.guide-dropdown {
  max-width: 170px !important;
  min-width: 10rem !important;
  width: 100%;
}
.guide-text {
  width: 100%;
  margin-bottom: 0;
  display: flex;
  max-width: 340px;
  border-left: 1px solid #C6C6C6;
  border-radius: 0;
}
.ds-textField__input:focus {
  border-color: #c6c6c6;
}
.search-icon {
  position: absolute;
  top: 12px;
  right: 11px;
  height: 20.8359375px;
  width: 20.8359375px;
  color: #727272;
}
.clear-icon {
  position: absolute;
  top: 14px;
  right: 42px;
  height: 16px;
  width: 16px;
  color: #969696;
}

.search-icon::before {
  font-size: 21px !important;
}
.ds-icon:hover::before {
  color: #222222;
  cursor: pointer;
}
.ds-combo-box__placeholder {
  width: fit-content;
}
.home-filter-placeholder {
  font-size: 19px;
  line-height: 24px;
  color: #3C3C3C;
  padding-left: 8px;
  position: absolute;
  top: 50%;
  left: 2%;
  transform: translateY(-50%);
}
</style>
