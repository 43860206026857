import i18n from '@/localization/i18n';

export const getShowButtonText = (isAnyFilterSelected, countResults) => {
  if (isAnyFilterSelected) {
    return `${i18n.global.t('filter.show.button')} ${countResults} ${i18n.global.t('results.text', countResults)}`;
  }
  return i18n.global.t('filter.showResults.button');
};
// Function used to count selected options in the Accordion filters inside the mobile drawer
export const badgeCounter = (selectedOptions) => {
  const count = selectedOptions?.length;
  return count > 0 ? count : null;
};
