<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import responseHandlerUtil from '@/plugins/search/responseHandler';
import '@sapudex/web-components/dist/Tag.js';

export default {
  name: 'PFBadgeBarComponent',
  methods: {
    ...mapActions('profileSearch', {
      cleanFilters: 'cleanFilters',
    }),
    ...mapActions('solutionL2Store', {
      removeSelectedL1Item: 'removeSelectedL1Item',
      removeSelectedL2Item: 'removeSelectedL2Item',
    }),
    ...mapMutations('solutionL2Store', {
      sortSolution: 'sortSolution',
    }),
    uncheckOption(option) {
      this.$store.dispatch('profileSearch/uncheckOption', option);
      if (option.optionType === 'location') {
        this.$store.commit('profileSearch/toggleOptionSelectedStatus', option);
        if (option.title.includes(',')) {
          this.$store.commit('profileSearch/deleteActiveLocation', option.title);
        }
      }
    },
    addSpaceAfterComma(text) {
      return responseHandlerUtil.addSpaceAfterComma(text);
    },
    uncheckSolutionOption(option) {
      if (this.solutionBadge.get(option).length > 0) {
        this.removeSelectedL2Item({ L1: this.solutionBadge.get(option), L2: option });
      } else {
        this.removeSelectedL1Item({ L1: option });
      }
      this.sortSolution();
    },
    getSolutionTitle(SolutionId) {
      const L1Title = this.reverseL2L1Mapping.get(SolutionId);
      if (L1Title) {
        return this.solutionDataMap.get(L1Title).L2Data.get(SolutionId).title;
      }
      return this.solutionDataMap.get(SolutionId).title;
    },
  },
  computed: {
    ...mapState('profileSearch', {
      selectedOptions: (state) => [
        ...state.selectedOptions.industry,
        ...state.selectedOptions.engagement,
        ...state.selectedOptions.location,
        ...state.selectedOptions.designation,
        ...state.selectedOptions.isCompetency,
      ],
      optionDisplayTitles: (state) => state.optionDisplayTitles,
    }),
    ...mapState('solutionL2Store', {
      solutionBadge: (state) => state.solutionBadge,
      solutionDataMap: (state) => state.solutionDataMap,
      reverseL2L1Mapping: (state) => state.reverseL2L1Mapping,
    }),
    clearTagClass() {
      return this.selectedOptions.length > 0 || [...this.solutionBadge.keys()].length > 0 ? 'visible' : '';
    },
  },
};
</script>

<template>
  <div class="tags-bar">
    <div v-for="option in solutionBadge.keys()" :item="option" :key="option">
      <udex-tag
        :text="addSpaceAfterComma(getSolutionTitle(option))"
        design="Grey"
        size="Medium"
        icon="decline"
        @iconClick="uncheckSolutionOption(option)"
        @selectionChange="uncheckSolutionOption(option)"
      ></udex-tag>
    </div>
    <div v-for="option in selectedOptions" v-bind:item="option" :key="option.title">
      <udex-tag
        :text="addSpaceAfterComma(optionDisplayTitles[option.title] ?? option.title)"
        design="Grey"
        size="Medium"
        icon="decline"
        @iconClick="uncheckOption(option)"
        @selectionChange="uncheckOption(option)"
      ></udex-tag>
    </div>
    <div class="clear-tag" :class="clearTagClass">
      <udex-tag text="Clear All" design="Text" size="Medium" icon="decline" @iconClick="cleanFilters()" @selectionChange="cleanFilters()"></udex-tag>
    </div>
  </div>
</template>

<style lang="less">
.tags-bar{
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

}
.clear-tag {
  display: none;
  &.visible {
    display: block;
  }
}
</style>
