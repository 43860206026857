<script>
import { mapState } from 'vuex';
import "@sapudex/web-components/dist/MessageStrip.js"

export default {
  name: 'PFDisclaimerComponent',
  props: {
    closeDisclaimer: { type: Function, default: () => {} },
  },
  computed: {
    ...mapState('systemMaintenance', {
      message: (state) => state.message,
    }),
  },
  methods: {
    getUserTimezone() {
      return new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
    },
  },
  mounted() {
    this.$el.addEventListener('close', this.closeDisclaimer);
  },
  destroyed() {
    this.$el.removeEventListener('close', this.closeDisclaimer);
  },

};
</script>

<template>
  <div>
    <udex-message-strip class="disclaimer-container regular-width" v-if="message" design="Warning" status-type="Text">
      <span class="disclaimer-msg" style="vertical-align: middle">
        <span v-if="message">
          {{message}}
        </span>
        <span v-else>
          {{$t('disclaimer.text.first.line.boldtext')}}
        </span>
      </span>
    </udex-message-strip>
    <udex-message-strip class="disclaimer-container mobile-width" v-if="message" design="Warning" status-type="Icon">
    <span class="disclaimer-msg" style="vertical-align: middle">
      <span v-if="message">
        {{message}}
      </span>
      <span v-else>
        {{$t('disclaimer.text.first.line.boldtext')}}
      </span>
    </span>
  </udex-message-strip>
  </div>
</template>

<style lang="less" scoped>
.disclaimer-msg {
  &:extend(.body-s-size);
}
.disclaimer-container {
  width: 89% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-top: 12px;
}
@media(min-width: 800px) {
  .regular-width {
    display: block;
  }
  .mobile-width {
    display: none;
  }
}
@media(max-width: 799px) {
  .regular-width {
    display: none;
  }
  .mobile-width {
    display: block;
  }
  .disclaimer-msg {
    height: unset;
  }
}
</style>
