<script>
import PFQPPSDetailModalComponent from '@/components/common/PFQPPSDetailModalComponent';

const SHOW_MAX_QPPS = 6;
const QPPS_CATEGORY = 'Country_VPUC';

export default {
  name: 'PFLocationQPPSComponent',
  components: {
    PFQPPSDetailModalComponent,
  },
  props: {
    localProfile: { type: Boolean, default: true },
    getQPPS: { type: Array, default: () => [] },
  },
  data: () => ({
    showQPPSDetailModal: false,
    selectedQPPSDetail: {},
    showAllQPPS: false,
    maxQPPS: SHOW_MAX_QPPS,
    QPPSCategory: QPPS_CATEGORY,
  }),
  computed: {
    showQPPS() {
      const sortedQPPS = this.sortByTitle(this.getQPPS.slice());
      if (this.getQPPS.length > this.maxQPPS && !this.showAllQPPS) {
        return sortedQPPS.slice(0, 6);
      }
      return sortedQPPS;
    },
    countQPPS() {
      return this.getQPPS.filter((item) => item.packageType !== 'Validated Partner Use Cases').length;
    },
    countVPUC() {
      return this.getQPPS.filter((item) => item.packageType === 'Validated Partner Use Cases').length;
    },
  },
  methods: {
    openDetailsModal(country) {
      this.selectedQPPSDetail = country;
      this.toggleDetailModal(true);
    },
    openInNewTab(url) {
      const win = window.open(url, '_blank');
      win.focus();
    },
    toggleDetailModal(flag) {
      this.showQPPSDetailModal = flag;
    },
    toggleShowMore(event) {
      this.showAllQPPS = !this.showAllQPPS;
      event.target.blur();
    },
    sortByTitle(solutions) {
      return solutions.sort((a, b) => a.packageName.localeCompare(b.packageName));
    },
    emitSWAEvent(QPPSCategory, QPPSName, QPPSCountry) {
      if (!this.editMode) {
        // eslint-disable-next-line vue/custom-event-name-casing
        this.$eventBus.$emit('PFAACustomEvent',
          {
            eventName: 'ClickModal', custom2: QPPSCategory, custom3: QPPSName, custom4: QPPSCountry,
          });
      }
    },
    onResize() {
      for (let idx = 0; idx < this.$refs.QPPSLocationContent.length; idx++) {
        const titleHeight = this.$refs.QPPSLocationContent[idx].clientHeight;
        const textLine = Math.floor((120 - titleHeight) / 24);
        this.$refs.QPPSLocationGuideText[idx].style.display = textLine > 0 ? '-webkit-box' : 'none';
        this.$refs.QPPSLocationGuideText[idx].style['-webkit-line-clamp'] = textLine;
      }
    },
  },
};
</script>

<template>
  <div v-if="getQPPS && getQPPS.length>0" class="pf-QPPS-location-container ds-m-b-xl">
    <h3 v-if="!localProfile" class="ds-heading--l ds-m-b-l ds-m-t-xl section-header-font-size">
      {{this.$showVPUCFeature ?
        $tc('accelerate.pacakge.header', getQPPS.length) : $t('QPPS.location.title')}}
    </h3>
    <div v-else class="pf-QPPS-location-title">{{this.$showVPUCFeature ?
      $tc('accelerate.pacakge.header', getQPPS.length) : $t('QPPS.location.title')}}</div>
    <div class="QPPS-VPUC-icon-container">
      <div v-if="countQPPS > 0 && countVPUC > 0" class="qpps-icon-container ds-m-t-m">
        <i class="ds-icon ds-icon--package"></i>
        <div class="ds-m-l-xxs">{{$tc('QPPS.modal.title', countQPPS)}}</div>
      </div>
      <div v-if="countQPPS > 0 && countVPUC > 0"  class="vpuc-icon-container ds-m-t-m">
        <img class="vpuc-icon" src="../../assets/VPUC-icon.png" alt="">
        <div class="ds-m-l-xxs">{{$tc('VPUC.table.header', countVPUC)}}</div>
      </div>
    </div>
    <div class="ds-flexRow ds-m-none ds-p-none pf-QPPS-location-content-flexRow"
    :class="getQPPS && getQPPS.length < 3 ? 'center-items' : ''">
      <div v-for="(item, idx) in showQPPS" :key="item.country + '-' + idx"
        class="ds-flexCol-12 ds-flexCol-md-6 ds-flexCol-lg-4 ds-m-none ds-p-none ds-m-t-m pf-QPPS-location-content-flex-container">
        <div class="pf-QPPS-location-content-container ds-p-m" @click="openDetailsModal(item); emitSWAEvent(QPPSCategory, item.packageName, item.country)">
          <div class="pf-QPPS-location-content-title" ref="QPPSLocationContent">
            <resize-observer @notify="this.onResize"></resize-observer>
            <img v-if="item.packageType === 'Validated Partner Use Cases'" class="vpuc-icon" src="../../assets/VPUC-icon.png" alt="">
            <i v-else class="ds-icon ds-icon--package"></i>
            <div class="pf-QPPS-location-title-text" v-html="$sanitize(item.packageName)"></div>
          </div>
          <div class="pf-QPPS-location-content-text ds-m-t-m"
          ref="QPPSLocationGuideText"
          v-html="$sanitize(item.teaserText)"></div>
        </div>
      </div>
    </div>
    <button
      v-if="this.getQPPS.length > this.maxQPPS"
      class="pf-QPPS-button-more-resources ds-button ds-button--secondary ds-m-t-l"
      @click="toggleShowMore">
      {{ showAllQPPS ?
      (this.$showVPUCFeature ? $t('QPPS.button.less.package') : $t('QPPS.button.less.solution'))
      : (this.$showVPUCFeature ? $t('QPPS.button.more.package') : $t('QPPS.button.more.solution')) }}
    </button>
    <PFQPPSDetailModalComponent
      v-if="showQPPSDetailModal"
      :showBackButton="false"
      :toggleModal="toggleDetailModal"
      :QPPSDetail="selectedQPPSDetail"/>
  </div>
</template>

<style lang="less" scoped>
.pf-QPPS-location-container {
  .ds-icon--package {
    margin-top: auto;
    margin-bottom: auto;
  }
  .ds-icon--package::before {
    font-size: 32px;
    color: @color-link;
  }
}
.QPPS-VPUC-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 21px;
  &:extend(.font-expanded);
  .vpuc-icon {
    height: 21px;
  }
  .ds-icon--package::before {
    font-size: 21px;
    color: @color-link;
  }
  .vpuc-icon-container {
    margin-left: 17px;
  }
  .qpps-icon-container {
    margin-right: 17px;
  }
  .qpps-icon-container, .vpuc-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.vpuc-icon {
  height: 32px;
}
.pf-QPPS-button-more-resources {
  &:extend(.font-normal);
  max-width: unset;
  cursor: pointer;

}
.pf-QPPS-location-content-flexRow {
    max-width: 940px;
    margin-left: auto !important;
    margin-right: auto !important;
}
.pf-QPPS-location-title {
  width: 100%;
  display: block;
  text-align: center;
  font-size: 21px;
  line-height: 27px;
  &:extend(.font-expanded);
}
.pf-QPPS-location-content-flex-container {
  max-width: 400px !important;
}
.pf-QPPS-location-content-container {
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
  height: 180px;
  border: solid 1px #eaeaea;
  cursor: pointer;
  border-color: @color-slight-gray;
  box-shadow: 0 10px 20px 0  rgba(0, 0, 0, 0.05);
  .pf-QPPS-location-content-title {
    align-items: center;
    display: flex;
    float: start;
  }
  .pf-QPPS-location-title-text {
    &:extend(.font-expanded);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 16px;
    margin-left: 13px;
    line-height: 24px;
    margin-top: auto;
    margin-bottom: auto;
  }
  .pf-QPPS-location-content-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-height: 72px;
    text-overflow: ellipsis;
    &:extend(.font-normal);
    font-size: 16px;
    line-height: 24px;
  }
}
.pf-QPPS-location-content-container:hover {
  border: solid 1px @color-blue-border;
  box-shadow: 0 40px 40px 0 rgba(0, 0, 0, 0.1);
  transform: scale(1.05);
  transition: transform .25s, visibility .25s ease-in;
}
</style>
