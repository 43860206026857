<script>
import { mapState } from 'vuex';
import PFCardItemComponent from './PFCardItemComponent';
import PFPaginationComponent from '../partnerNavigatorPage/PFPaginationComponent';

export default {
  name: 'PFCardItemContainer',
  components: {
    PFCardItemComponent,
    PFPaginationComponent,
  },
  computed: {
    ...mapState('profileSearch', {
      data: (state) => state.searchResults,
      loading: (state) => state.loading,
    }),
  },
};
</script>

<template>
  <div class="flex-ie-container">
    <div v-if="data.length > 0 || loading" class="ds-card-container">
      <div class="ds-card">
        <div class="search-result-row">
          <div v-for="(res, idx) in data" class="new-ds-card__tile" :key="res.profileId">
            <PFCardItemComponent :data="res" :idx="idx" />
          </div>
        </div>
      </div>
      <div>
        <PFPaginationComponent />
      </div>
    </div>
    <div v-else class="not-found-image-container">
      <div class="search-page-no-result-img">
        <img alt="" class="not-found-img" src="../../assets/no-search-results.png" />
      </div>
      <div class="no-search-result-title">{{ $t("no.search.result.title") }}</div>
      <div class="no-search-result-text">{{ $t("no.search.result.text") }}</div>
    </div>
  </div>
</template>

<style lang="less">
.search-result-row {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 48px;
  justify-content: center;
}
.add-height {
  height: 84px;
}
.consultant-cloud-container {
  min-height: 54px;
}

.ds-card-container {
  margin-top: 56px;
  margin-bottom: 68px;
}

@media (max-width: 1599px) {
  .search-result-row {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .ds-card-container .ds-card {
    max-width: unset !important;
  }
}

@media (max-width: 1279px) {
  .ds-card-container .search-result-row {
    width: unset !important;
  }
  .ds-card-container {
  margin-bottom: 48px;
}
}

@media (max-width: 979px) {
  .ds-card-container {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .search-result-row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 32px;
  }
  .ds-card-container .ds-card {
    display: block;
  }
  .consultant-cloud-container {
    height: unset;
    margin-top: 37px;
    min-height: 48px !important;
  }
  .consultant-number {
    font-size: 24px !important;
    line-height: 26px !important;
  }
}

@media (max-width: 639px) {
  .search-result-row {
    grid-template-columns: auto;
    justify-content: unset;
  }
  .add-height {
    height: 47px;
  }
  .consultant-cloud-container {
    margin-top: 6px;
    height: 46px;
    min-height: unset !important;
  }
  .ds-card-container {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.not-found-image-container {
  width: 100%;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;

  .search-page-no-result-img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
  .not-found-img {
    width: 240px;
    height: 240px;
  }
  .no-search-result-title {
    &:extend(.HeadingMediumXL-size);
    color: @udexCoreHeadingDark;
    margin-bottom: 16px;
  }
  .no-search-result-text {
    &:extend(.body-l-size);
    color: @udexCoreTextDark;
  }

  @media (max-width: 639px) {
    .search-page-no-result-img {
      margin-bottom: 24px;
    }
    .no-search-result-title {
      font-size: @udexTypographyHeadingMediumMFontSize;
    }
    .no-search-result-text {
      font-size: @udexTypographyBodySFontSize;
    }
  }
}
.ds-card-container {
  .search-result-row {
    width: 100%;
    max-width: 1432px;
  }

  .ds-card {
    padding-right: 0;
    justify-content: center;
  }

  .ds-card {
    .ds-card__tile {
      max-width: 100%;
      padding-bottom: 0 !important;
    }
  }
}

.flex-ie-container {
  flex: 50%;
}

@media (max-width: 830px) {
  .flex-ie-container {
    flex: 100%;
  }
}
</style>
