import axios from 'axios';
import router from '../../router/routes';
import addessUtil from '../../plugins/profile/address';
import orderingUtil from '../../plugins/profile/ordering';
import errorHandlerUtil from '../../plugins/profile/errorHandler';
import * as ChangedDataCompare from './changedDataCompare';

function resourceComparator(a, b) {
  return a.name.localeCompare(b.name);
}

function isEmptyString(str) {
  return typeof (str) === 'string' && str.length === 0;
}

function notEmptyString(str) {
  return typeof (str) === 'string' && str.length !== 0;
}

const ROOT_ENDPOINT = '/sap/details/api/partnerProfile/';
const ROOT_SECURED_ENDPOINT = '/sap/details/apis/partnerProfile/';
const READ_ENDPOINT = `${ROOT_ENDPOINT}findByPartnerProfileId/`;
const EDIT_ENDPOINT = `${ROOT_SECURED_ENDPOINT}findByPartnerProfileId/`;
const POST_ENDPOINT = `${ROOT_SECURED_ENDPOINT}bulk`;
const POSTFIX = '/edit';

const isNotEmptyObject = (object) => Object.keys(object).length !== 0;
const isMapNotEmptyObject = (object) => object.size !== 0;

const removeIdFiled = (item) => delete item.id;
const removeODPFiled = (item) => {
  delete item.businessModels;
  delete item.cloudImpls;
  delete item.totalCloudImpls;
  delete item.totalConsultants;
};
const removeNullField = (item) => {
  Object.entries(item).forEach(([key, value]) => {
    if (value === null) {
      delete item[key];
    }
  });
};

const removeEmptyField = (item) => {
  Object.entries(item).forEach(([key, value]) => {
    if (Array.isArray(value) && value.length === 0) {
      delete item[key];
    } else if (value && value.constructor.name === 'Object' && Object.keys(value).length === 0) {
      delete item[key];
    }
  });
};

const indastryConsumer = (industry) => {
  removeIdFiled(industry);
  industry.industry = { id: industry.industryId };
  delete industry.industryId;
  delete industry.title;
};

const languageConsumer = (language) => {
  removeIdFiled(language);
  delete language.createdBy;
  delete language.text;
  delete language.updatedAt;
  delete language.updatedBy;
};

const applyConsumerToEach = (data, consumer) => {
  if (data) {
    data.forEach(consumer);
  }
};

const localProfileConsumer = (countryData) => {
  delete countryData.consultants;
  delete countryData.existInOdp;
  delete countryData.existInPf;
  delete countryData.solutions;
  removeIdFiled(countryData);
  removeODPFiled(countryData);
  removeNullField(countryData);
  removeEmptyField(countryData);
  applyConsumerToEach(countryData.industries, indastryConsumer);
  applyConsumerToEach(countryData.profileSocialMedias, removeIdFiled);
  applyConsumerToEach(countryData.resources, removeIdFiled);
};

const buildPostPayload = (payload) => {
  if (payload.id) {
    delete payload.status;
  }
  delete payload.version;
  delete payload.competencies;
  delete payload.consultants;
  removeIdFiled(payload);
  removeODPFiled(payload);
  removeNullField(payload);
  removeEmptyField(payload);
  if (payload.hqAddress) {
    removeNullField(payload.hqAddress);
  }
  applyConsumerToEach(payload.languages, languageConsumer);
  applyConsumerToEach(payload.solutions, removeIdFiled);
  applyConsumerToEach(payload.brandNames, removeIdFiled);
  applyConsumerToEach(payload.industries, indastryConsumer);
  applyConsumerToEach(payload.localProfiles, localProfileConsumer);
  applyConsumerToEach(payload.profileSocialMedias, removeIdFiled);
  applyConsumerToEach(payload.resources, removeIdFiled);
  return payload;
};

const excludeSolutions = (payload) => {
  delete payload.solutions;
  return payload;
};

const pathIndustryModelResolver = (industry) => (industry.id === undefined
  ? { industry: { id: industry.industryId } }
  : { id: industry.id });

const pathLanguageModelResolver = (language, originalLanguages) => {
  if (language.id) {
    return { id: language.id };
  }
  const originalData = originalLanguages.find((item) => item.language === language.language);
  if (originalData && originalData.id) {
    return { id: originalData.id };
  }
  return { language: language.language };
};

const prepareLocalProfiles = (localProfileData) => {
  if (localProfileData) {
    return localProfileData
      .map(JSON.stringify) // make a clone an object
      .map(JSON.parse)
      .map((countryData) => {
        if (countryData.industries) {
          countryData.industries = countryData.industries.map(pathIndustryModelResolver);
        }
        removeODPFiled(countryData);
        removeNullField(countryData);
        return countryData;
      });
  }
  return [];
};

const removeUnchangedLocalData = (localProfileData, changedCountryData, profileOriginalData) => {
  if (localProfileData) {
    return localProfileData
      .map(JSON.stringify) // make a clone an object
      .map(JSON.parse)
      .filter((countryData) => changedCountryData.has(countryData.country))
      .map((countryData) => {
        Object.entries(countryData).forEach(([key]) => {
          if (key !== 'id' && key !== 'version' && key !== 'country' && !profileOriginalData.has(countryData.country + key)) {
            delete countryData[key];
          }
        });
        if (countryData.id) {
          delete countryData.country;
        } else {
          delete countryData.version;
        }
        return countryData;
      })
      .filter((countryData) => Object.keys(countryData).length > 2);
  }
  return {};
};

const reorderGlobalResources = (context, payload, this_) => {
  this_.commit('partnerProfile/setOriginalData', {
    profileData: context.profileData.resources,
    key: 'resources',
  });
  let profileResources = context.profileData.resources;
  const draggedResourceId = profileResources[payload.source].id;
  const targetResourceId = profileResources[payload.target].id;
  const savedIndexes = profileResources.map((resource) => resource.id);
  profileResources.forEach((resource, index) => { resource.id = index.toString(); });
  profileResources = orderingUtil.orderData((item) => item.id, payload, profileResources, 'id');
  profileResources.forEach((resource, index) => { resource.id = savedIndexes[index]; });
  profileResources[payload.source].id = targetResourceId;
  profileResources[payload.target].id = draggedResourceId;
  context.profileData.resources = profileResources;
};

const reorderLocalProfileResources = (context, payload, this_) => {
  const countryName = payload.id;
  context.profileData.localProfiles.forEach((lProfile) => {
    if (lProfile.country === countryName) {
      this_.commit('partnerProfile/setOriginalData', {
        profileData: lProfile.resources,
        key: `${countryName}resources`,
      });
      const draggedResourceId = lProfile.resources[payload.source].id;
      const targetResourceId = lProfile.resources[payload.target].id;
      const savedIndexes = lProfile.resources.map((resource) => resource.id);
      lProfile.resources.forEach((resource, index) => { resource.id = index.toString(); });
      lProfile.resources = orderingUtil.orderData((item) => item.id, payload, lProfile.resources, 'id');
      lProfile.resources.forEach((resource, index) => { resource.id = savedIndexes[index]; });
      lProfile.resources[payload.source].id = targetResourceId;
      lProfile.resources[payload.target].id = draggedResourceId;
    }
  });
};

const PartnerProfileStorage = {
  namespaced: true,
  state: {
    profileData: {
      hqAddress: {},
    },
    profileChangedData: new Map(),
    loading: false,
    videoContainerLoading: false,
    changedData: false,
    errorFields: [],
    isMessageShow: false,
    saveSuccess: false,
    publishSuccess: false,
    errorPath: '',
    customErrorMessage: '',
    expandedCountry: [],
    mountedComponent: [],
    profileOriginalData: new Map(),
    changedCountryData: new Set(),
    publishComment: '',
    loadChangelog: false,
    redirectAfterSave: false,
    originalLanguages: [],
    csrfToken: '',
    originalPublishStatus: '',
    scrollBarWidth: 0,
    loadingProfileData: false,
    solutionTransformed: [],
    ERPCompetency: [],
    expandedL1s: [],
  },
  actions: {
    findEditableProfileByProfileId(context, profileId) {
      context.commit('setLoading', true);
      const endpoint = `${EDIT_ENDPOINT}${profileId}${POSTFIX}`;
      context.commit('setLoadChangelog', true);
      context.dispatch('findByProfileId', { profileId, endpoint });
    },

    async findByProfileId(context, { profileId, endpoint }) {
      context.commit('setLoading', true);
      context.commit('setLoadingProfileData', true);
      context.commit('setResults', {});
      const destinationEndPoint = endpoint || `${READ_ENDPOINT}${profileId}`;
      await axios.get(destinationEndPoint, {
        headers: {
          'x-csrf-token': 'fetch',
        },
      })
        .then((result) => {
          context.dispatch('dispatchResponseHandler', result);
          context.commit('setCsrfToken', result.headers['x-csrf-token']);
        })
        .catch((err) => errorHandlerUtil.handle(err, router));
      await context.commit('setLoading', false);
      await context.commit('setLoadingProfileData', false);
    },
    createL2sEntries(context) {
      const solutionMap = context.rootState.solutionL2Store.solutionDataMap;
      context.commit('createL2sEntries', solutionMap);
      context.commit('createERPSplitCompetency', solutionMap);
    },
    async findHqAddressPlaceDetails(context, placeId) {
      const endPoint = `/sap/search/api/search/place/details?placeId=${placeId}`;
      await axios.get(endPoint)
        .then((result) => context.commit('updateHqAddressByGoogleValue', result.data))
        .catch((err) => { throw new Error(`Response error: ${err}`); });
    },

    publishProfile(context) {
      context.commit('setOriginalPublishStatus', context.state.profileData.status);
      context.commit('updateStatus', 'PUBLISHED');
      if (context.state.profileData.id) {
        context.dispatch('patchProfileData', true);
      } else {
        context.commit('updateComment', context.state.publishComment);
        context.dispatch('postProfileData');
      }
    },

    removeHqAddress(context) {
      context.commit('removeHqAddress');
    },

    async patchProfileData(context, isPublish) {
      const partnerData = context.state.profileData;
      const partnerChangedData = context.state.profileChangedData;
      // store data for case, if backed returns error
      const originalDataBackup = new Map(context.state.profileOriginalData);
      if (isMapNotEmptyObject(partnerChangedData)) {
        context.commit('setLoading', true);
        context.commit('removeUnchangedField');
        context.commit('setPartnerChangedDataVersion');
        partnerChangedData.set('version', partnerData.version);
        const postObject = Object.fromEntries(partnerChangedData);
        const industries = [];
        if (partnerChangedData.get('industries') !== undefined) {
          partnerChangedData.get('industries')
            .forEach((industry) => {
              industries.push(pathIndustryModelResolver(industry));
            });
          postObject.industries = industries;
        }
        const languages = [];
        if (partnerChangedData.get('languages') !== undefined) {
          partnerChangedData.get('languages')
            .forEach((language) => {
              languages.push(pathLanguageModelResolver(language, context.state.originalLanguages));
            });
          postObject.languages = languages;
        }
        if (postObject.localProfiles) {
          postObject.localProfiles = removeUnchangedLocalData(postObject.localProfiles,
            context.state.changedCountryData,
            context.state.profileOriginalData);
          postObject.localProfiles = prepareLocalProfiles(postObject.localProfiles);
          postObject.localProfiles = addessUtil.prepareLocalAddress(postObject.localProfiles);
        }
        let updateMethod = 'updateSaveSuccess';
        let errorPath = 'save.error';
        if (isPublish) {
          postObject.comment = context.state.publishComment;
          updateMethod = 'updatePublishSuccess';
          errorPath = 'publish.error';
        }
        context.commit('removeBackendErrorField');
        await axios.patch(`${ROOT_SECURED_ENDPOINT}${partnerData.id}`, postObject, {
          headers: {
            'x-csrf-token': context.state.csrfToken,
          },
        })
          .then((result) => {
            context.dispatch('dispatchUpdateHandler', result);
            context.commit('setLoading', false);
            context.commit(updateMethod, true);
            context.commit('updateIsMessageShow', true);
            context.commit('updatePublishComment', '');
          })
          .catch((err) => {
            context.commit('restoreProfileOriginalData', originalDataBackup);
            context.commit('setLoading', false);
            context.commit('setErrorPath', errorPath);
            context.commit('updateIsMessageShow', true);
            if (isPublish) {
              context.commit('updateStatus', context.state.originalPublishStatus);
            }
            context.dispatch('handleBackendErrorResponse', err);
            throw new Error(err);
          });
      }
    },

    async postProfileData(context) {
      let payload = ChangedDataCompare.deepCopyFunction(context.state.profileData);
      if (isNotEmptyObject(payload)) {
        context.commit('setLoading', true);
        const modifiedLocalProfiles = addessUtil.prepareLocalAddress(payload.localProfiles, true);
        payload.localProfiles = modifiedLocalProfiles;
        payload = buildPostPayload(payload);
        payload = excludeSolutions(payload);
        context.commit('removeBackendErrorField');
        let updateMethod = 'updateSaveSuccess';
        let errorPath = 'save.error';
        if (payload.status === 'PUBLISHED') {
          updateMethod = 'updatePublishSuccess';
          errorPath = 'publish.error';
        }
        await axios.post(POST_ENDPOINT, payload, {
          headers: {
            'x-csrf-token': context.state.csrfToken,
          },
        })
          .then((result) => {
            context.dispatch('dispatchUpdateHandler', result);
            context.commit('setLoading', false);
            context.commit(updateMethod, true);
            context.commit('updateIsMessageShow', true);
          })
          .catch((err) => {
            context.commit('setLoading', false);
            context.commit('setErrorPath', errorPath);
            if (payload.status === 'PUBLISHED') {
              context.commit('updateStatus', context.state.originalPublishStatus);
            }
            context.commit('updateIsMessageShow', true);
            context.dispatch('handleBackendErrorResponse', err);
            throw new Error(err);
          });
      }
    },
    handleBackendErrorResponse(context, err) {
      if (!window.navigator.onLine) {
        context.commit('setCustomErrorMessage', 'network.error');
      } else if (err.response.status === 400) {
        if (Array.isArray(err.response.data.parameters.validationErrors)) {
          context.commit('setCustomErrorMessage', 'validation.error');
          err.response.data.parameters.validationErrors.forEach((e) => {
            const patchProfileDataErr = {
              id: `${e.property}Err`,
              field: e.property,
              message: e.message,
            };
            if (e.property === 'hqAddress.fullAddress'
              || e.property === 'hqAddress.latitude'
              || e.property === 'hqAddress.longitude') {
              patchProfileDataErr.id = 'hqAddressErr';
              patchProfileDataErr.field = 'hqAddress';
            }
            if (e.property === 'webSiteUrl' && e.message === 'This is not a valid URL') {
              patchProfileDataErr.id = 'notValidUrlErr';
            }
            if (e.property === 'languages') {
              patchProfileDataErr.id = 'globalSupportedLanguagesMandatoryErr';
            }
            // eslint-disable-next-line no-underscore-dangle
            this._vm.$eventBus.$emit(patchProfileDataErr.id);
            context.commit('addErrorField', patchProfileDataErr);
          });
        } else {
          context.commit('setCustomErrorMessage', 'contact.admin');
        }
      } else if (err.response.status === 409) {
        context.commit('setCustomErrorMessage', 'profile.updated.sametime');
      } else if (err.response.status === 503 || err.response.status === 404) {
        context.commit('setCustomErrorMessage', 'come.later');
      } else if (err.response.data) {
        context.commit('setCustomErrorMessage', 'contact.admin');
      }
    },
    reorderLocalIndustries(context, { source, target, id }) {
      context.commit('reorderLocalIndustries', { source, target, id });
      context.commit('setChangedCountryData', id);
    },
    reorderFocusIndustries(context, { source, target }) {
      context.commit('reorderFocusIndustries', { source, target });
    },
    updatePublishComment(context, comment) {
      context.commit('updatePublishComment', comment);
    },
    removeFocusIndustry(context, industryId) {
      context.commit('removeFocusIndustry', industryId);
    },
    removeLocalIndustry(context, { industryId, countryName }) {
      context.commit('removeLocalIndustry', { industryId, countryName });
      context.commit('setChangedCountryData', countryName);
    },
    reorderResourceses(context, { source, target, id }) {
      context.commit('reorderResourceses', { source, target, id });
      if (id !== undefined) {
        context.commit('setChangedCountryData', id);
      }
    },
    reorderSupportedLanguages(context, { source, target }) {
      context.commit('reorderSupportedLanguages', { source, target });
    },
    removeSupportedLanguages(context, language) {
      context.commit('removeSupportedLanguages', language);
    },
    dispatchResponseHandler(context, results) {
      if (results.data.publishDate && context.state.loadChangelog) {
        context.dispatch('changelog/getChangelog', results.data.profileId, { root: true });
        context.commit('setLoadChangelog', false);
      }
      context.commit('setResults', results.data);
    },
    dispatchUpdateHandler(context, results) {
      if (results.data.publishDate && !context.state.redirectAfterSave) {
        context.dispatch('changelog/reloadChangelog', results.data.profileId, { root: true });
      }
      context.commit('setPfResults', results.data);
    },
    updateStatus(context, status) {
      context.commit('updateStatus', status);
    },
    updateName(context, newName) {
      context.commit('updateName', newName);
    },
    updateLogo(context, logoUrl) {
      context.commit('updateLogo', logoUrl);
    },
    updateVideoUrl(context, videoUrl) {
      context.commit('updateVideoUrl', videoUrl);
    },
    updateVideoImageUrl(context, videoImageUrl) {
      context.commit('updateVideoImageUrl', videoImageUrl);
    },
    updateHqEmail(context, newEmail) {
      context.commit('updateHqEmail', newEmail);
    },
    updateHqPhone(context, newPhone) {
      context.commit('updateHqPhone', newPhone);
    },
    updateSocialMedias(context, { socialMedias, countryName }) {
      if (countryName === 'global') {
        context.commit('updateGlobalSocialMedias', socialMedias);
      } else {
        context.commit('updateLocalSocialMedias', { socialMedias, countryName });
        context.commit('setChangedCountryData', countryName);
      }
    },
    updateWebsiteUrl(context, newWebsiteUrl) {
      context.commit('updateWebsiteUrl', newWebsiteUrl);
    },
    updateValueProposition(context, newValueProposition) {
      context.commit('updateValueProp', newValueProposition);
    },
    updateValuePropositionSummary(context, newValuePropositionSummary) {
      context.commit('updateValuePropSummary', newValuePropositionSummary);
    },
    updateFocusIndustries(context, newFocusIndustries) {
      context.commit('updateFocusIndustries', newFocusIndustries);
    },
    updateLocalIndustries(context, { industries, countryName }) {
      context.commit('updateLocalIndustries', { industries, countryName });
      context.commit('setChangedCountryData', countryName);
    },
    updateSupportedLanguages(context, newSupportedLanguages) {
      context.commit('updateSupportedLanguages', newSupportedLanguages);
    },
    updateLocationValueProposition(context, { valueProp, countryName }) {
      context.commit('updateLocationValueProposition', { valueProp, countryName });
      context.commit('setChangedCountryData', countryName);
    },
    updateServiceValuePropositionSummary(context, newValuePropositionSummary) {
      context.commit('updateServiceValuePropSummary', newValuePropositionSummary);
    },
    updateProfileResource(context, { resource, countryName, index }) {
      context.commit('updateProfileResource', { resource, countryName, index });
      context.commit('setChangedCountryData', countryName);
    },
    removeProfileResource(context, { countryName, index }) {
      context.commit('removeProfileResource', { countryName, index });
      context.commit('setChangedCountryData', countryName);
    },
    addProfileResource(context, { resource, countryName }) {
      context.commit('addProfileResource', { resource, countryName });
      context.commit('setChangedCountryData', countryName);
    },
    addErrorField(context, errorField) {
      context.commit('addErrorField', errorField);
    },
    removeErrorField(context, errorField) {
      context.commit('removeErrorField', errorField);
    },
    updateSaveSuccess(context, value) {
      context.commit('updateSaveSuccess', value);
    },
    updatePublishSuccess(context, value) {
      context.commit('updatePublishSuccess', value);
    },
    updateAllMessages(context, value) {
      context.commit('updateAllMessages', value);
    },
    toggleSelectAddress(context, selectedId) {
      context.commit('toggleSelectAddress', selectedId);
    },
    updateAddressField(context, {
      fieldName, countryName, addressId, value,
    }) {
      context.commit('updateAddressField', {
        fieldName, countryName, addressId, value,
      });
      context.commit('setChangedCountryData', countryName);
    },
    reorderAddresses(context, { countryName, source, target }) {
      context.commit('reorderAddresses', { countryName, source, target });
      context.commit('setChangedCountryData', countryName);
    },
    toggleCountryAccordion(context, expandedCountry) {
      context.commit('toggleCountryAccordion', expandedCountry);
    },
    toggleL1(context, L1) {
      context.commit('toggleL1', L1);
    },
    updateContactEmail(context, val) {
      context.commit('updateContactEmail', val);
    },
    addMountedComponent(context, val) {
      context.commit('addMountedComponent', val);
    },
    addKeyword(context, val) {
      context.commit('addKeyword', val);
    },
    removeKeyword(context, val) {
      context.commit('removeKeyword', val);
    },
  },
  mutations: {
    createERPSplitCompetency(context, solutionMap) {
      const temp_ERPCompetency = [];

      context.profileData.competencies
        .filter((item) => item.solutionL1Key === 'PFERP')
        .forEach((item) => {
          item.specializations.forEach((spe) => {
            if (spe.level && spe.level.toUpperCase() !== 'ZERO') {
              const temp_ERP = {
                level: spe.level,
                hasCompetency: true,
                competencyName: spe.name,
                ERPComp: true,
                specializationData: {
                  countries: { ...spe.countries },
                  level: spe.level,
                  industries: { ...spe.industries },
                  name: spe.name,
                  solutionL1Key: spe.solutionL1Key,
                  solutionL2Key: spe.solutionL2Key,
                },
                solutionL2Name: spe.name,
                solutionL1Key: spe.solutionL1Key,
                solutionL2Key: spe.solutionL2Key,
                hasSpecialization: true,
                specializationAvailable: solutionMap.get(spe.solutionL1Key)
                  .L2Data.get(spe.solutionL2Key).specializationAvailable,
              };

              temp_ERPCompetency.push(temp_ERP);
            }
          });
        });

      temp_ERPCompetency.sort((a, b) => solutionMap.get(a.solutionL1Key).L2Data.get(a.solutionL2Key).order
      - solutionMap.get(b.solutionL1Key).L2Data.get(b.solutionL2Key).order);

      context.ERPCompetency = temp_ERPCompetency;
    },
    createL2sEntries(context, solutionMap) {
      const temp_sols = new Map();

      context.profileData.solutions.forEach((sol) => {
        if (!temp_sols.has(sol.solutionL1Key)) {
          temp_sols.set(sol.solutionL1Key, {
            solutionL1Key: sol.solutionL1Key,
            solutionL1Name: sol.solutionL1Name,
            L2Solution: [],
            hasCompetency: false,
            specializations: [],
            competencyAvailable: solutionMap.get(sol.solutionL1Key)?.competencyAvailable,
          });
        }
        const currentSol = temp_sols.get(sol.solutionL1Key);
        currentSol.L2Solution.push({
          solutionL2Key: sol.solutionL2Key,
          solutionL2Name: sol.solutionName,
          hasSpecialization: false,
          specializationData: {},
          specializationAvailable: solutionMap.get(sol.solutionL1Key)?.L2Data.get(sol.solutionL2Key).specializationAvailable,
        });
      });

      context.profileData.competencies.forEach((comp) => {
        if (temp_sols.has(comp.solutionL1Key)) {
          const currentSol = temp_sols.get(comp.solutionL1Key);
          currentSol.countries = { ...comp.countries };
          currentSol.level = comp.level;
          currentSol.industries = { ...comp.industries };
          // don't set this key for ERP object because there is no L1 level competency for ERP anymore
          if (comp.solutionL1Key !== 'PFERP') {
            currentSol.hasCompetency = true;
          }
          currentSol.competencyName = comp.name;

          comp.specializations.forEach((spe) => {
            currentSol.L2Solution.forEach((l2sol) => {
              if (spe.solutionL2Key === l2sol.solutionL2Key) {
                l2sol.hasSpecialization = true;
                l2sol.specializationData = { ...spe };
                currentSol.specializations.push({ ...spe });
              }
            });
          });
        }
      });

      const transformedSolutions = Array.from(temp_sols.values())
        .filter((sol) => sol.solutionL1Key !== 'PFOTH')
        .sort((left, right) => {
          if (left.hasCompetency && !right.hasCompetency) return -1;
          if (!left.hasCompetency && right.hasCompetency) return 1;
          const order = { EXPERT: 0, ADVANCED: 1, ESSENTIAL: 2 };
          if (left.hasCompetency && right.hasCompetency
            && order[left.level?.toUpperCase()] !== order[right.level?.toUpperCase()]) {
            return order[left.level?.toUpperCase()] - order[right.level?.toUpperCase()];
          }
          return solutionMap.get(left.solutionL1Key).order - solutionMap.get(right.solutionL1Key).order;
        });

      transformedSolutions.forEach((sol) => {
        const L2Data = solutionMap.get(sol.solutionL1Key)?.L2Data;
        sol.specializations.sort((left, right) => L2Data.get(left.solutionL2Key).order - L2Data.get(right.solutionL2Key).order);
        sol.L2Solution.sort((left, right) => {
          if (left.hasSpecialization && !right.hasSpecialization) return -1;
          if (!left.hasSpecialization && right.hasSpecialization) return 1;
          return L2Data.get(left.solutionL2Key).order - L2Data.get(right.solutionL2Key).order;
        });
      });

      context.solutionTransformed = transformedSolutions;
    },

    removeUnchangedField(context) {
      if (context.profileOriginalData.size === 0) {
        context.profileChangedData.clear();
      }
      context.profileChangedData.forEach((value, key) => {
        if (key !== 'localProfiles') {
          if (context.profileOriginalData.has(key)) {
            context.profileOriginalData.delete(key);
          } else {
            context.profileChangedData.delete(key);
          }
        }
      });
      if (context.profileOriginalData.size === 0) {
        context.profileChangedData.delete('localProfiles');
      }
    },
    setPartnerChangedDataVersion(context) {
      context.profileChangedData.set('version', context.profileData.version);
    },
    setResults(context, results) {
      // Using multi-chain assignment to ensure profileData references the same object as context.profileData for further modifications.
      // eslint-disable-next-line no-multi-assign
      const profileData = context.profileData = results;

      const pred = (r) => !(r.type === 'PACKAGES' && r.link.startsWith('https://www.sap.com/partner'));

      profileData.resources = profileData.resources?.filter(pred);

    // eslint-disable-next-line no-unused-expressions
    profileData.localProfiles?.forEach((localProfile) => {
      localProfile.resources = localProfile.resources?.filter(pred);
    });

    if (!context.profileData.hqAddress) {
      context.profileData.hqAddress = {};
    }
    if (!context.profileData.profileSocialMedias) {
      context.profileData.profileSocialMedias = [];
    }
    if (!context.profileData) {
      context.profileData = {};
    }

    context.originalLanguages = profileData.languages;

    context.profileChangedData.clear();
    context.profileOriginalData.clear();
    context.changedCountryData.clear();
    context.changedData = false;
    },

    setPfResults(context, results) {
      context.profileData = results;
      if (!context.profileData.hqAddress) {
        context.profileData.hqAddress = {};
      }
      context.originalLanguages = context.profileData.languages;
      context.profileChangedData.clear();
      context.profileOriginalData.clear();
      context.changedCountryData.clear();
      context.changedData = false;
    },
    updateLocalProfileData(context, value) {
      context.profileData.localProfiles = value;
    },
    updateProfileResource(context, { resource, countryName, index }) {
      if (countryName === 'global') {
        this.commit('partnerProfile/setOriginalData', {
          profileData: context.profileData.resources,
          key: 'resources',
        });
        context.profileData.resources[index] = resource;
        context.profileData.resources.sort(resourceComparator);
        this.commit('partnerProfile/compareArrayOfObjectByKey', {
          profileData: context.profileData.resources,
          key: 'resources',
          keyProperty: ['description', 'link', 'name', 'type'],
        });
        context.profileChangedData.set('resources', context.profileData.resources);
      } else {
        let localResource;
        context.profileData.localProfiles.forEach((lProfile) => {
          if (lProfile.country === countryName) {
            localResource = lProfile.resources;
            this.commit('partnerProfile/setOriginalData', {
              profileData: localResource,
              key: `${countryName}resources`,
            });
            lProfile.resources[index] = resource;
            lProfile.resources.sort(resourceComparator);
            localResource = lProfile.resources;
          }
        });
        this.commit('partnerProfile/compareArrayOfObjectByKey', {
          profileData: localResource,
          key: `${countryName}resources`,
          keyProperty: ['description', 'link', 'name', 'type'],
        });
        context.profileChangedData.set('localProfiles', context.profileData.localProfiles);
      }
    },
    removeProfileResource(context, {
      countryName,
      index,
    }) {
      if (countryName === 'global') {
        this.commit('partnerProfile/setOriginalData', {
          profileData: context.profileData.resources,
          key: 'resources',
        });
        context.profileData.resources.splice(index, 1);
        this.commit('partnerProfile/compareArrayOfObjectByKey', {
          profileData: context.profileData.resources,
          key: 'resources',
          keyProperty: ['description', 'link', 'name', 'type'],
        });
        context.profileChangedData.set('resources', context.profileData.resources);
      } else {
        let localResource;
        context.profileData.localProfiles.forEach((lProfile) => {
          if (lProfile.country === countryName) {
            localResource = lProfile.resources;
            this.commit('partnerProfile/setOriginalData', {
              profileData: localResource,
              key: `${countryName}resources`,
            });
            lProfile.resources.splice(index, 1);
            localResource = lProfile.resources;
          }
        });
        this.commit('partnerProfile/compareArrayOfObjectByKey', {
          profileData: localResource,
          key: `${countryName}resources`,
          keyProperty: ['description', 'link', 'name', 'type'],
        });
        context.profileChangedData.set('localProfiles', context.profileData.localProfiles);
      }
    },
    addProfileResource(context, {
      resource,
      countryName,
    }) {
      if (countryName === 'global') {
        if (!context.profileData.resources) {
          context.profileData.resources = [];
        }
        this.commit('partnerProfile/setOriginalData', {
          profileData: context.profileData.resources,
          key: 'resources',
        });
        context.profileData.resources.push(resource);
        context.profileData.resources.sort(resourceComparator);
        this.commit('partnerProfile/compareArrayOfObjectByKey', {
          profileData: context.profileData.resources,
          key: 'resources',
          keyProperty: ['description', 'link', 'name', 'type'],
        });
        context.profileChangedData.set('resources', context.profileData.resources);
      } else {
        let localResource;
        context.profileData.localProfiles.forEach((lProfile) => {
          if (lProfile.country === countryName) {
            if (!lProfile.resources) {
              lProfile.resources = [];
            }
            localResource = lProfile.resources;
            this.commit('partnerProfile/setOriginalData', {
              profileData: localResource,
              key: `${countryName}resources`,
            });
            lProfile.resources.push(resource);
            lProfile.resources.sort(resourceComparator);
            localResource = lProfile.resources;
          }
        });
        this.commit('partnerProfile/compareArrayOfObjectByKey', {
          profileData: localResource,
          key: `${countryName}resources`,
          keyProperty: ['description', 'link', 'name', 'type'],
        });
        context.profileChangedData.set('localProfiles', context.profileData.localProfiles);
      }
    },
    updateHqPhone(context, newPhone) {
      this.commit('partnerProfile/setOriginalData', { profileData: context.profileData.hqAddress, key: 'hqAddress' });
      context.profileData.hqAddress.phone = newPhone;
      this.commit('partnerProfile/compareObjectByKey', { key: 'hqAddress', keyProperty: ['phone', 'email', 'fullAddress'] });
      context.profileChangedData.set('hqAddress', context.profileData.hqAddress);
    },
    updateHqEmail(context, newEmail) {
      this.commit('partnerProfile/setOriginalData', { profileData: context.profileData.hqAddress, key: 'hqAddress' });
      context.profileData.hqAddress.email = newEmail;
      this.commit('partnerProfile/compareObjectByKey', { key: 'hqAddress', keyProperty: ['phone', 'email', 'fullAddress'] });
      context.profileChangedData.set('hqAddress', context.profileData.hqAddress);
    },
    removeHqAddress(context) {
      this.commit('partnerProfile/setOriginalData', { profileData: context.profileData.hqAddress, key: 'hqAddress' });

      context.profileData.hqAddress.fullAddress = null;
      context.profileData.hqAddress.city = null;
      context.profileData.hqAddress.regionText = null;
      context.profileData.hqAddress.countryCodeText = null;
      context.profileData.hqAddress.countryCode = null;
      context.profileData.hqAddress.alternativeCountryName = null;
      context.profileData.hqAddress.latitude = null;
      context.profileData.hqAddress.longitude = null;
      context.profileData.hqAddress.addressLines = null;
      context.profileData.hqAddress.addressName = null;

      this.commit('partnerProfile/compareObjectByKey', { key: 'hqAddress', keyProperty: ['phone', 'email', 'fullAddress'] });
      context.profileChangedData.set('hqAddress', context.profileData.hqAddress);
    },
    updateHqAddressByGoogleValue(context, placeDetails) {
      this.commit('partnerProfile/setOriginalData', { profileData: context.profileData.hqAddress, key: 'hqAddress' });
      context.profileData.hqAddress.fullAddress = placeDetails.fullAddress;
      context.profileData.hqAddress.city = placeDetails.city;
      context.profileData.hqAddress.regionText = placeDetails.region;
      context.profileData.hqAddress.countryCodeText = placeDetails.country;
      context.profileData.hqAddress.countryCode = placeDetails.countryCode;
      context.profileData.hqAddress.alternativeCountryName = placeDetails.alternativeCountryName;
      context.profileData.hqAddress.latitude = placeDetails.latitude;
      context.profileData.hqAddress.longitude = placeDetails.longitude;
      context.profileData.hqAddress.addressLines = placeDetails.addressLines;
      context.profileData.hqAddress.addressName = placeDetails.addressName;

      this.commit('partnerProfile/compareObjectByKey', { key: 'hqAddress', keyProperty: ['phone', 'email', 'fullAddress'] });
      context.profileChangedData.set('hqAddress', context.profileData.hqAddress);
    },
    updateGlobalSocialMedias(context, socialMedias) {
      const sm = context.profileData.profileSocialMedias;
      this.commit('partnerProfile/setOriginalSocialMediasData', { key: 'profileSocialMedias', socialMedias: sm });
      Object.keys(socialMedias)
        .forEach((socialMediaType) => {
          if (sm) {
            const element = sm.find((item) => item.socialMediaType === socialMediaType);
            if (element) {
              if (isEmptyString(socialMedias[socialMediaType])) {
                sm.splice(sm.indexOf(element), 1);
              } else if (socialMedias[socialMediaType] !== null) {
                element.link = socialMedias[socialMediaType];
              }
            } else if (notEmptyString(socialMedias[socialMediaType])) {
              sm.push({ socialMediaType, link: socialMedias[socialMediaType] });
            }
          } else if (socialMedias[socialMediaType] !== null) {
            const link = socialMedias[socialMediaType];
            context.profileData.profileSocialMedias = new Array({ socialMediaType, link });
          }
        });
      this.commit('partnerProfile/compareSocialMediasData', { key: 'profileSocialMedias', socialMedias: sm });
      context.profileChangedData.set('profileSocialMedias', context.profileData.profileSocialMedias);
    },
    updateLocalSocialMedias(context, { socialMedias, countryName }) {
      let localSocialMedias;
      Object.keys(socialMedias)
        .forEach((socialMediaType) => {
          context.profileData.localProfiles.forEach((lProfile) => {
            if (lProfile.country === countryName) {
              localSocialMedias = lProfile.profileSocialMedias;
              const key = `${countryName}profileSocialMedias`;
              this.commit('partnerProfile/setOriginalSocialMediasData', { key, socialMedias: localSocialMedias });
              if (lProfile.profileSocialMedias) {
                const element = lProfile.profileSocialMedias
                  .find((sMedia) => socialMediaType === sMedia.socialMediaType);
                if (element) {
                  if (isEmptyString(socialMedias[socialMediaType])) {
                    const smIndex = lProfile.profileSocialMedias.indexOf(element);
                    lProfile.profileSocialMedias.splice(smIndex, 1);
                  } else if (socialMedias[socialMediaType] !== null) {
                    element.link = socialMedias[socialMediaType];
                  }
                } else if (notEmptyString(socialMedias[socialMediaType])) {
                  const link = socialMedias[socialMediaType];
                  lProfile.profileSocialMedias.push({ socialMediaType, link });
                }
              } else if (socialMedias[socialMediaType] !== null) {
                const link = socialMedias[socialMediaType];
                lProfile.profileSocialMedias = [{ socialMediaType, link }];
              }
              localSocialMedias = lProfile.profileSocialMedias;
            }
          });
        });
      const key = `${countryName}profileSocialMedias`;
      this.commit('partnerProfile/compareSocialMediasData', { key, socialMedias: localSocialMedias });
      context.profileChangedData.set('localProfiles', context.profileData.localProfiles);
    },
    updateStatus(context, status) {
      this.commit('partnerProfile/setOriginalData', {
        profileData: context.profileData.status,
        key: 'status',
      });
      context.profileData.status = status;
      this.commit('partnerProfile/compareProperty', {
        profileData: status,
        key: 'status',
      });
      context.profileChangedData.set('status', status);
    },
    updateName(context, name) {
      this.commit('partnerProfile/setOriginalData', {
        profileData: context.profileData.name,
        key: 'name',
      });
      context.profileData.name = name;
      this.commit('partnerProfile/compareProperty', {
        profileData: name,
        key: 'name',
      });
      context.profileChangedData.set('name', name);
    },
    updateLogo(context, logoUrl) {
      this.commit('partnerProfile/setOriginalData', {
        profileData: context.profileData.logoUrl,
        key: 'logoUrl',
      });
      context.profileData.logoUrl = logoUrl;
      this.commit('partnerProfile/compareProperty', {
        profileData: logoUrl,
        key: 'logoUrl',
      });
      context.profileChangedData.set('logoUrl', logoUrl);
    },
    updateVideoUrl(context, videoUrl) {
      this.commit('partnerProfile/setOriginalData', {
        profileData: context.profileData.videoUrl,
        key: 'videoUrl',
      });
      context.profileData.videoUrl = videoUrl;
      this.commit('partnerProfile/compareProperty', {
        profileData: videoUrl,
        key: 'videoUrl',
      });
      context.profileChangedData.set('videoUrl', videoUrl);
    },
    updateVideoImageUrl(context, videoImageUrl) {
      this.commit('partnerProfile/setOriginalData', {
        profileData: context.profileData.videoImageUrl,
        key: 'videoImageUrl',
      });
      context.profileData.videoImageUrl = videoImageUrl;
      this.commit('partnerProfile/compareProperty', {
        profileData: videoImageUrl,
        key: 'videoImageUrl',
      });
      context.profileChangedData.set('videoImageUrl', videoImageUrl);
    },
    updateWebsiteUrl(context, newWebsiteUrl) {
      this.commit('partnerProfile/setOriginalData', {
        profileData: context.profileData.webSiteUrl,
        key: 'webSiteUrl',
      });
      context.profileData.webSiteUrl = newWebsiteUrl;
      this.commit('partnerProfile/compareProperty', {
        profileData: newWebsiteUrl,
        key: 'webSiteUrl',
      });
      context.profileChangedData.set('webSiteUrl', newWebsiteUrl);
    },
    updateFocusIndustries(context, newFocusIndustries) {
      let data = newFocusIndustries;
      if (!Array.isArray(data)) {
        data = [newFocusIndustries];
      }
      this.commit('partnerProfile/setOriginalData', {
        profileData: context.profileData.industries,
        key: 'industries',
      });
      context.profileData.industries = data;
      this.commit('partnerProfile/compareArrayOfObjectByKey', {
        profileData: data,
        key: 'industries',
        keyProperty: ['industryId'],
      });
      context.profileChangedData.set('industries', data);
    },
    updateLocalIndustries(context, { industries, countryName }) {
      const localIndustry = context.profileData.localProfiles
        .filter((profile) => profile.country === countryName)[0].industries;
      this.commit('partnerProfile/setOriginalData', { profileData: localIndustry, key: `${countryName}industries` });
      context.profileData.localProfiles
        .filter((localProfile) => localProfile.country === countryName)
        .forEach((localProfile) => {
          localProfile.industries = Array.isArray(industries) ? industries : [industries];
        });
      this.commit('partnerProfile/compareArrayOfObjectByKey', {
        profileData: Array.isArray(industries) ? industries : [industries],
        key: `${countryName}industries`,
        keyProperty: ['industryId'],
      });
      context.profileChangedData.set('localProfiles', context.profileData.localProfiles);
    },
    updateSupportedLanguages(context, newSupportedLanguages) {
      let data = newSupportedLanguages;
      if (!Array.isArray(data)) {
        data = [newSupportedLanguages];
      }
      this.commit('partnerProfile/setOriginalData', {
        profileData: context.profileData.languages,
        key: 'languages',
      });
      context.profileData.languages = data;
      this.commit('partnerProfile/compareArrayOfObjectByKey', {
        profileData: data,
        key: 'languages',
        keyProperty: ['language'],
      });
      context.profileChangedData.set('languages', data);
    },
    updateValueProp(context, newValue) {
      this.commit('partnerProfile/setOriginalData', {
        profileData: context.profileData.valuePreposition,
        key: 'valuePreposition',
      });
      context.profileData.valuePreposition = newValue;
      this.commit('partnerProfile/compareProperty', {
        profileData: newValue,
        key: 'valuePreposition',
      });
      context.profileChangedData.set('valuePreposition', newValue);
    },
    updateValuePropSummary(context, newValue) {
      this.commit('partnerProfile/setOriginalData', {
        profileData: context.profileData.valuePrepositionSummary,
        key: 'valuePrepositionSummary',
      });
      context.profileData.valuePrepositionSummary = newValue;
      this.commit('partnerProfile/compareProperty', {
        profileData: newValue,
        key: 'valuePrepositionSummary',
      });
      context.profileChangedData.set('valuePrepositionSummary', newValue);
    },
    reorderLocalIndustries(context, payload) {
      const localProfile = context.profileData.localProfiles.filter((profile) => profile.country === payload.id)[0];
      this.commit('partnerProfile/setOriginalData', {
        profileData: localProfile.industries,
        key: `${payload.id}industries`,
      });
      localProfile.industries = orderingUtil.orderData((item) => item.industryId, payload, localProfile.industries, 'industryId');
      this.commit('partnerProfile/compareArrayOfObjectByKey', {
        profileData: localProfile.industries,
        key: `${payload.id}industries`,
        keyProperty: ['industryId'],
      });
      context.profileChangedData.set('localProfiles', context.profileData.localProfiles);
    },
    reorderFocusIndustries(context, payload) {
      this.commit('partnerProfile/setOriginalData', {
        profileData: context.profileData.industries,
        key: 'industries',
      });
      context.profileData.industries = orderingUtil.orderData((item) => item.industryId, payload,
        context.profileData.industries, 'industryId');
      this.commit('partnerProfile/compareArrayOfObjectByKey', {
        profileData: context.profileData.industries,
        key: 'industries',
        keyProperty: ['industryId'],
      });
      context.profileChangedData.set('industries', context.profileData.industries);
    },
    reorderResourceses(context, payload) {
      if (payload.id === undefined) {
        reorderGlobalResources(context, payload, this);
        this.commit('partnerProfile/compareArrayOfObjectByKey', {
          profileData: context.profileData.resources,
          key: 'resources',
          keyProperty: ['id'],
        });
        context.profileChangedData.set('resources', context.profileData.resources);
      } else {
        reorderLocalProfileResources(context, payload, this);
        let localProfileResources;
        context.profileData.localProfiles.forEach((lProfile) => {
          if (lProfile.country === payload.id) {
            localProfileResources = lProfile.resources;
          }
        });
        this.commit('partnerProfile/compareArrayOfObjectByKey', {
          profileData: localProfileResources,
          key: `${payload.id}resources`,
          keyProperty: ['description', 'link', 'name', 'type'],
        });
        context.profileChangedData.set('localProfiles', context.profileData.localProfiles);
      }
    },
    reorderSupportedLanguages(context, payload) {
      this.commit('partnerProfile/setOriginalData', {
        profileData: context.profileData.languages,
        key: 'languages',
      });
      context.profileData.languages = orderingUtil.orderData((item) => item.language, payload,
        context.profileData.languages, 'language');
      this.commit('partnerProfile/compareArrayOfObjectByKey', {
        profileData: context.profileData.languages,
        key: 'languages',
        keyProperty: ['language'],
      });
      context.profileChangedData.set('languages', context.profileData.languages);
    },
    updatePublishComment(context, comment) {
      context.publishComment = comment;
    },
    removeFocusIndustry(context, industryId) {
      this.commit('partnerProfile/setOriginalData', {
        profileData: context.profileData.industries,
        key: 'industries',
      });
      const industries = context.profileData.industries.filter((industry) => industry.industryId !== industryId);
      context.profileData.industries = industries;
      this.commit('partnerProfile/compareArrayOfObjectByKey', {
        profileData: industries,
        key: 'industries',
        keyProperty: ['industryId'],
      });
      context.profileChangedData.set('industries', industries);
    },
    removeLocalIndustry(context, {
      industryId,
      countryName,
    }) {
      const condition = (industry) => industry.industryId !== industryId;
      let localIndustry = context.profileData.localProfiles
        .filter((profile) => profile.country === countryName)[0].industries;
      this.commit('partnerProfile/setOriginalData', {
        profileData: localIndustry,
        key: `${countryName}industries`,
      });
      context.profileData.localProfiles
        .filter((localProfile) => localProfile.country === countryName)
        .forEach((localProfile) => {
          localProfile.industries = localProfile.industries.filter(condition);
          localIndustry = localProfile.industries;
        });
      this.commit('partnerProfile/compareArrayOfObjectByKey', {
        profileData: localIndustry,
        key: `${countryName}industries`,
        keyProperty: ['industryId'],
      });
      context.profileChangedData.set('localProfiles', context.profileData.localProfiles);
    },
    removeSupportedLanguages(context, language) {
      this.commit('partnerProfile/setOriginalData', {
        profileData: context.profileData.languages,
        key: 'languages',
      });
      const languages = context.profileData.languages.filter((item) => item.language !== language);
      context.profileData.languages = languages;
      this.commit('partnerProfile/compareArrayOfObjectByKey', {
        profileData: languages,
        key: 'languages',
        keyProperty: ['language'],
      });
      context.profileChangedData.set('languages', languages);
    },
    updateLocationValueProposition(context, {
      valueProp,
      countryName,
    }) {
      context.profileData.localProfiles = context.profileData.localProfiles.map((countryProfile) => {
        if (countryProfile.country === countryName) {
          this.commit('partnerProfile/setOriginalData', {
            profileData: countryProfile.valueProposition,
            key: `${countryName}valueProposition`,
          });
          this.commit('partnerProfile/compareProperty', {
            profileData: valueProp,
            key: `${countryName}valueProposition`,
          });
          return {
            ...countryProfile,
            valueProposition: valueProp,
          };
        }
        return countryProfile;
      });
      context.profileChangedData.set('localProfiles', context.profileData.localProfiles);
    },
    updateServiceValuePropSummary(context, newValue) {
      this.commit('partnerProfile/setOriginalData', {
        profileData: context.profileData.serviceSummary,
        key: 'serviceSummary',
      });
      context.profileData.serviceSummary = newValue;
      this.commit('partnerProfile/compareProperty', {
        profileData: newValue,
        key: 'serviceSummary',
      });
      context.profileChangedData.set('serviceSummary', newValue);
    },
    updateBrandNames(context, newValue) {
      const keyProperty = ['id', 'brandName', 'shortDescription', 'isFavoriteName'];
      if (context.profileData.brandNames) {
        this.commit('partnerProfile/sortBrandName');
      }
      this.commit('partnerProfile/setOriginalData', {
        profileData: context.profileData.brandNames,
        key: 'brandNames',
      });
      context.profileData.brandNames = newValue;
      this.commit('partnerProfile/compareArrayOfObjectByKey', { profileData: newValue, key: 'brandNames', keyProperty });
      context.profileChangedData.set('brandNames', newValue);
    },
    sortBrandName(context) {
      context.profileData.brandNames.sort((a, b) => a.brandName.localeCompare(b.brandName));
      let favouriteNameIdx = null;
      context.profileData.brandNames.forEach((name, idx) => {
        if (name.isFavoriteName) {
          favouriteNameIdx = idx;
        }
      });
      if (favouriteNameIdx) {
        const favouriteNameObj = context.profileData.brandNames[favouriteNameIdx];
        context.profileData.brandNames.splice(favouriteNameIdx, 1);
        context.profileData.brandNames.unshift(favouriteNameObj);
      }
    },
    setLoading(context, value) {
      context.loading = value;
    },
    addErrorField(context, errorField) {
      if (!context.errorFields.some((err) => err.id === errorField.id)) {
        context.errorFields.push(errorField);
      }
    },
    removeErrorField(context, errId) {
      context.errorFields = context.errorFields.filter((err) => err.id !== errId);
    },
    removeBackendErrorField(context) {
      context.errorFields = context.errorFields.filter((err) => !err.id.startsWith('backend'));
    },
    updateIsMessageShow(context, value) {
      context.isMessageShow = value;
    },
    updateSaveSuccess(context, value) {
      context.saveSuccess = value;
    },
    updatePublishSuccess(context, value) {
      context.publishSuccess = value;
    },
    updateAllMessages(context, value) {
      context.saveSuccess = value;
      context.publishSuccess = value;
      context.isMessageShow = value;
    },
    setErrorPath(context, value) {
      context.errorPath = value;
    },
    setCustomErrorMessage(context, value) {
      context.customErrorMessage = value;
    },
    toggleSelectAddress(context, selectedId) {
      const [, countryName, addressNumber] = selectedId.split('-');
      this.commit('partnerProfile/setChangedCountryData', countryName);
      let myAddresses = context.profileData.localProfiles
        .filter((countryProfile) => countryProfile.country === countryName)[0].addresses;
      const key = `${countryName}addresses`;
      this.commit('partnerProfile/setOriginalData', { profileData: myAddresses, key });
      myAddresses = myAddresses.map((address) => {
        if (address.addressNumber === addressNumber) {
          return {
            ...address,
            selected: !address.selected,
          };
        }
        return address;
      });
      const keyProperty = ['addressNumber', 'selected', 'phone', 'email', 'locationName'];
      this.commit('partnerProfile/compareArrayOfObjectByKey', { profileData: myAddresses, key, keyProperty });
      context.profileData.localProfiles = context.profileData.localProfiles.map((countryProfile) => {
        if (countryProfile.country === countryName) {
          return {
            ...countryProfile,
            addresses: myAddresses,
          };
        }
        return countryProfile;
      });
      context.profileChangedData.set('localProfiles', context.profileData.localProfiles);
    },
    updateAddressField(context, {
      fieldName, countryName, addressId, value,
    }) {
      let myAddresses = context.profileData.localProfiles
        .filter((countryProfile) => countryProfile.country === countryName)[0].addresses;
      const key = `${countryName}addresses`;
      this.commit('partnerProfile/setOriginalData', { profileData: myAddresses, key });
      myAddresses = myAddresses
        .map((address) => {
          if (address.addressNumber === addressId) {
            address[fieldName] = value;
            return address;
          }
          return address;
        });
      const keyProperty = ['addressNumber', 'selected', 'phone', 'email', 'locationName'];
      this.commit('partnerProfile/compareArrayOfObjectByKey', { profileData: myAddresses, key, keyProperty });
      context.profileData.localProfiles = context.profileData.localProfiles.map((countryProfile) => {
        if (countryProfile.country === countryName) {
          return {
            ...countryProfile,
            addresses: myAddresses,
          };
        }
        return countryProfile;
      });
      context.profileChangedData.set('localProfiles', context.profileData.localProfiles);
    },
    reorderAddresses(context, { countryName, source, target }) {
      context.profileData.localProfiles = context.profileData.localProfiles.map((countryProfile) => {
        if (countryProfile.country === countryName
          && countryProfile.addresses !== undefined
          && countryProfile.addresses.length > 0) {
          const key = `${countryName}addresses`;
          const keyProperty = ['addressNumber', 'selected', 'phone', 'email', 'locationName'];
          const payload = { source, target };
          const addressNumberFuntion = (address) => address.addressNumber;

          this.commit('partnerProfile/setOriginalData', { profileData: countryProfile.addresses, key });
          countryProfile.addresses = orderingUtil.orderData(addressNumberFuntion, payload, countryProfile.addresses, 'addressNumber');
          this.commit('partnerProfile/compareArrayOfObjectByKey', { profileData: countryProfile.addresses, key, keyProperty });
          return countryProfile;
        }
        return countryProfile;
      });
      context.profileChangedData.set('localProfiles', context.profileData.localProfiles);
    },
    toggleCountryAccordion(context, expandedCountry) {
      context.expandedCountry = expandedCountry;
    },
    toggleL1(context, L1) {
      context.expandedL1s = L1;
    },
    updateContactEmail(context, val) {
      this.commit('partnerProfile/setOriginalData', {
        profileData: context.profileData.contactEmail,
        key: 'contactEmail',
      });
      context.profileData.contactEmail = val;
      this.commit('partnerProfile/compareProperty', {
        profileData: val,
        key: 'contactEmail',
      });
      context.profileChangedData.set('contactEmail', context.profileData.contactEmail);
    },
    addMountedComponent(context, val) {
      if (context.mountedComponent.indexOf(val) === -1) {
        context.mountedComponent.push(val);
      }
    },
    addKeyword(context, keyword) {
      this.commit('partnerProfile/setOriginalData', {
        profileData: context.profileData.tags,
        key: 'tags',
      });
      if (!context.profileData.tags) {
        context.profileData.tags = [];
      }
      if (context.profileData.tags.indexOf(keyword) === -1) {
        context.profileData.tags.push(keyword);
        this.commit('partnerProfile/compareArrayOfTags');
        context.profileChangedData.set('tags', context.profileData.tags);
      }
    },
    restoreProfileOriginalData(context, data) {
      context.profileOriginalData = data;
      this.commit('partnerProfile/updatePublishComment', '');
    },
    removeKeyword(context, keyword) {
      this.commit('partnerProfile/setOriginalData', {
        profileData: context.profileData.tags,
        key: 'tags',
      });
      if (context.profileData.tags.indexOf(keyword) !== -1) {
        context.profileData.tags.splice(context.profileData.tags.indexOf(keyword), 1);
        this.commit('partnerProfile/compareArrayOfTags');
        context.profileChangedData.set('tags', context.profileData.tags);
      }
    },
    setOriginalSocialMediasData(context, { key, socialMedias }) {
      if (!context.profileOriginalData.has(key)) {
        const socialMediasMap = new Map();
        if (socialMedias) {
          socialMedias.forEach((element) => {
            socialMediasMap.set(element.socialMediaType, element.link);
          });
        }
        context.profileOriginalData.set(key, socialMediasMap);
        context.changedData = context.profileOriginalData.size > 0;
      }
    },
    compareSocialMediasData(context, {
      key,
      socialMedias,
    }) {
      if (ChangedDataCompare.compareSocialMediaArray(context.profileOriginalData.get(key), socialMedias)) {
        console.log(`${key} doesn't change`);
        context.profileOriginalData.delete(key);
        context.changedData = context.profileOriginalData.size > 0;
      }
    },
    setOriginalData(context, { profileData, key }) {
      if (!context.profileOriginalData.has(key)) {
        context.profileOriginalData.set(key, ChangedDataCompare.deepCopyFunction(profileData));
        context.changedData = context.profileOriginalData.size > 0;
      }
    },
    setChangedCountryData(context, countryName) {
      context.changedCountryData.add(countryName);
    },
    compareArrayOfObjectByKey(context, {
      profileData,
      key,
      keyProperty,
    }) {
      if (ChangedDataCompare.compareArrayOfObject(context.profileOriginalData.get(key), profileData, keyProperty)) {
        console.log(`${key} doesn't change`);
        context.profileOriginalData.delete(key);
        context.changedData = context.profileOriginalData.size > 0;
      }
    },
    compareObjectByKey(context, {
      key,
      keyProperty,
    }) {
      if (ChangedDataCompare.compareSimpleObjectKeyProperty(
        context.profileOriginalData.get(key),
        context.profileData[key], keyProperty,
      )) {
        console.log(`${key} doesn't change`);
        context.profileOriginalData.delete(key);
        context.changedData = context.profileOriginalData.size > 0;
      }
    },
    compareProperty(context, {
      profileData,
      key,
    }) {
      if (ChangedDataCompare.compareStrings(context.profileOriginalData.get(key), profileData)) {
        console.log(`${key} doesn't change`);
        context.profileOriginalData.delete(key);
        context.changedData = context.profileOriginalData.size > 0;
      }
    },
    compareArrayOfTags(context) {
      if (ChangedDataCompare.compareArray(context.profileOriginalData.get('tags'), context.profileData.tags)) {
        context.profileOriginalData.delete('tags');
        console.log('tag does not change');
        context.changedData = context.profileOriginalData.size > 0;
      }
    },
    setLoadChangelog(context, val) {
      context.loadChangelog = val;
    },
    updateChangedData(context, val) {
      context.changedData = val;
    },
    updateRedirectAfterSave(context, val) {
      context.redirectAfterSave = val;
    },
    setCsrfToken(context, val) {
      context.csrfToken = val;
    },
    setVideoContainerLoading(context, val) {
      context.videoContainerLoading = val;
    },
    setOriginalPublishStatus(context, val) {
      context.originalPublishStatus = val;
    },
    setScrollBarWidth(context, val) {
      context.scrollBarWidth = val;
    },
    updateComment(context, val) {
      context.profileData.comment = val;
    },
    setLoadingProfileData(context, val) {
      context.loadingProfileData = val;
    },
  },
  getters: {
    getLocalProfileByCountry: (state) => (country) => state.profileData.localProfiles
      .find((localProfile) => localProfile.country === country),
  },
};

export default PartnerProfileStorage;
