<script>
import { mapActions, mapState } from 'vuex';
import '@sapudex/web-components/dist/RadioButton.js';

const sortOptions = require('@/plugins/search/sortOptions');

export default {
  name: 'PFAccordionSortMobileComponent',
  data: () => ({
    typesSort: sortOptions.SORT_OPTIONS,
  }),

  computed: {
    ...mapState('profileSearch', {
      countResults: (state) => state.countResults,
      selectedOptions: (state) => state.selectedOptions,
      selectedSortOption: (state) => state.selectedSortingOptionText,
      selectedSort: (state) => state.sorting,
      query: (state) => state.query,
    }),
    ...mapState('solutionL2Store', {
      solutionBadge: (state) => state.solutionBadge,
    }),
    ...mapState('filtersDrawer', {
      expandedItem: (state) => state.expandedAccordionItem,
    }),
  },
  methods: {
    ...mapActions('profileSearch', {
      cleanFilters: 'cleanFilters',
    }),
    ...mapActions('filtersDrawer', {
      setExpandedAccordionItem: 'setExpandedAccordionItem',
    }),
    changeSortType(key, value) {
      this.$store.dispatch('profileSearch/setSelectedSortingOptionText', key);
      this.$store.dispatch('profileSearch/setSorting', value);
      this.$eventBus.$emit('PFAACustomEvent', { eventName: 'SearchInitiated', custom2: this.query });
    },
  },
};
</script>

<template>
  <udex-accordion-item
    automation-id="filterDrawer-accordionItem"
    title="Sort"
    :expanded.prop="expandedItem === title"
    @click="setExpandedAccordionItem(expandedItem === title ? null : title)"
  >
    <div class="vertical-wrapper">
      <udex-radio-button
        v-for="(value, key) in typesSort"
        :key="key"
        name="sort-group"
        class="sort-radio-buttons"
        v-bind="{ checked: value === selectedSort ? true : null }"
        :text="key"
        @change="changeSortType(key, value)"
        @click.stop
        automation-id="filterDrawer-radioButton"
      ></udex-radio-button>
    </div>
  </udex-accordion-item>
</template>

<style lang="less" scoped>
.vertical-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: var(--udexSpacer8);
}
</style>
