<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import PFHomeFilterComponent from '@/components/homepage/PFHomeFilterComponent';
import PFGuidedSearchComponent from '@/components/homepage/PFGuidedSearchComponent';
import PFDisclaimerComponent from '@/components/common/PFDisclaimerComponent';
import PFSearchBarComponent from '@/components/common/PFSearchBarComponent/PFSearchBarComponent';

const LARGESCREEN = 685;

export default {
  name: 'PFBannerComponent',
  props: {
    title: { type: String },
    description: { type: String },
    link: { type: String },
  },
  data: () => ({
    LOCATION: {
      filterType: 'location',
      type: 'locationCheckbox',
      title: 'search.filter.location',
      img: '/src/img/homepage-location.png',
    },
    PRODUCTION: {
      filterType: 'products',
      type: 'checkbox',
      title: 'search.filter.solution',
      img: '/src/img/homepage-solution.png',
    },
    INDUSTRY: {
      filterType: 'industry',
      type: 'checkbox',
      title: 'search.filter.industry',
      img: '/src/img/homepage-industry.png',
    },
    DESIGNATION: {
      filterType: 'designation',
      type: 'checkbox',
      title: 'search.filter.designation',
      img: '/src/img/homepage-designation.png',
    },
    isShownFilter: false,
    showDropdown: false,
    lock: false,
    keyCodes: {
      END: 35,
      HOME: 36,
      SHIFT: 16,
      COMMAND: 91,
      OPTION: 18,
      CONTROL: 17,
      DOWN: 40,
      ENTER: 13,
      ESCAPE: 27,
      LEFT: 37,
      RIGHT: 39,
      TAB: 9,
      UP: 38,
      CONTINUOUS_KEY_PRESSING: 229,
    },
    maxBannerItem: 2,
  }),
  components: {
    PFHomeFilterComponent,
    PFGuidedSearchComponent,
    PFDisclaimerComponent,
    PFSearchBarComponent,
  },
  mounted() {
    this.onResize();
  },
  computed: {
    ...mapState('profileSearch', {
      selectedIndustry: (state) => state.selectedOptions.industry,
      selectedLocation: (state) => state.selectedOptions.location,
      selectedDesignation: (state) => state.selectedOptions.designation,
      query: (state) => state.query,
      selectedOptions: (state) => state.selectedOptions,
      queryTermTypeState: (state) => state.queryTermTypeState,
      countResults: (state) => state.countResults,
      loading: (state) => state.loading,
    }),
    ...mapState('solutionL2Store', {
      solutionBadge: (state) => state.solutionBadge,
    }),
    ...mapState('systemMaintenance', {
      enableMaintenanceBanner: (state) => state.enableMaintenanceBanner,
    }),
    selectedSolutionSorted() {
      return [...this.solutionBadge.keys()]?.length > 0
        ? [...this.solutionBadge.keys()].sort((a, b) => a.localeCompare(b)) : [];
    },
    selectedLocationSorted() {
      return [...this.selectedLocation].sort((a, b) => a.title.localeCompare(b.title));
    },
    selectedIndustrySorted() {
      return [...this.selectedIndustry].sort((a, b) => a.title.localeCompare(b.title));
    },
    selectedDesignationSorted() {
      return [...this.selectedDesignation];
    },
    checkFilterSelected() {
      return [...this.solutionBadge.keys()]?.length > 0
       || [...this.selectedLocation]?.length > 0
       || [...this.selectedIndustry]?.length > 0
       || [...this.selectedDesignation]?.length > 0;
    },
  },
  methods: {
    ...mapActions('profileSearch', {
      cleanFilterByType: 'cleanFilterByType',
    }),
    ...mapActions('solutionL2Store', {
      unselectAllSolution: 'unselectAllSolution',
      removeSelectedL1Item: 'removeSelectedL1Item',
      removeSelectedL2Item: 'removeSelectedL2Item',
    }),
    ...mapMutations('solutionL2Store', {
      sortSolution: 'sortSolution',
    }),
    uncheckOption(option) {
      this.$store.dispatch('profileSearch/uncheckOption', option);
      this.$store.commit('profileSearch/toggleOptionSelectedStatus', option);
      if (option.optionType === 'location') {
        if (option.title.includes(',')) {
          this.$store.commit('profileSearch/deleteActiveLocation', option.title);
        }
      }
    },
    uncheckSolutionOption(option) {
      if (this.solutionBadge.get(option).length > 0) {
        this.removeSelectedL2Item({ L1: this.solutionBadge.get(option), L2: option });
      } else {
        this.removeSelectedL1Item({ L1: option });
      }
      this.sortSolution();
    },
    submitForm() {
      this.$eventBus.$emit('PFAACustomEvent',
        {
          eventName: 'SearchInitiated', custom2: this.query, custom3: window.location.search, custom4: true,
        });
      this.$store.dispatch('profileSearch/homeSearchAddRouterQuery',
        {
          query: this.query,
          guidedSearch: false,
        });
    },
    cleanFilterUpdateLocation(type) {
      this.cleanFilterByType(type);
    },
    cleanSolutionUpdateLocation() {
      this.unselectAllSolution(true);
    },
    updateDropdownState(val) {
      this.showDropdown = val;
    },
    onResize() {
      this.textWidth = this.$refs.homeFilterItem.offsetWidth;
      if (this.textWidth > LARGESCREEN) {
        this.maxBannerItem = 2;
      } else {
        this.maxBannerItem = 1;
      }
    },
    closeDisclaimer() {
      this.$store.commit('systemMaintenance/setEnableMaintenanceBanner', false);
    },
  },
};
</script>

<template>
  <div class="pf-banner-container">
    <PFDisclaimerComponent v-show="enableMaintenanceBanner" :closeDisclaimer="closeDisclaimer"/>
    <div class="pf-banner">
      <div class="pf-home-text-container">
        <div >
          <div class="ds-heading--xl ds-m-b-s heading-body-title" v-html="$t('header.banner.label.title')"></div>
          <div class="heading-body-text ds-body--l" v-html="$t('header.banner.label.text')"></div>
        </div>
      </div>
    </div>
    <div class="home-search-container">
    <div class="home-filter-dropdown home-filter-container" ref="homeFilterItem">
      <resize-observer @notify="this.onResize"></resize-observer>
      <PFHomeFilterComponent
      :filterType="LOCATION.filterType"
      :type="LOCATION.type"
      :title="LOCATION.title"
      :imgSrc="LOCATION.img"
      :activeOptions="selectedLocationSorted"
      textKey="title"
      :removeItem="uncheckOption"
      :cleanFilterByType="cleanFilterByType"
      :maxTabShown="maxBannerItem"
      />
    </div>
    <div class="home-filter-dropdown home-filter-container">
      <PFHomeFilterComponent
      :filterType="PRODUCTION.filterType"
      :type="PRODUCTION.type"
      :title="PRODUCTION.title"
      :imgSrc="PRODUCTION.img"
      :activeOptions="selectedSolutionSorted"
      :removeItem="uncheckSolutionOption"
      :cleanFilterByType="cleanSolutionUpdateLocation"
      :maxTabShown="maxBannerItem"
      />
    </div>
    <div class="home-filter-dropdown home-filter-container">
      <PFHomeFilterComponent
      :filterType="DESIGNATION.filterType"
      :type="DESIGNATION.type"
      :title="DESIGNATION.title"
      :imgSrc="DESIGNATION.img"
      :activeOptions="selectedDesignationSorted"
      textKey="designationDescr"
      :removeItem="uncheckOption"
      :cleanFilterByType="cleanFilterByType"
      :maxTabShown="maxBannerItem"
      />
    </div>
    <div class="home-filter-dropdown home-filter-container">
      <PFHomeFilterComponent
      :filterType="INDUSTRY.filterType"
      :type="INDUSTRY.type"
      :title="INDUSTRY.title"
      :imgSrc="INDUSTRY.img"
      :activeOptions="selectedIndustrySorted"
      textKey="title"
      :removeItem="uncheckOption"
      :cleanFilterByType="cleanFilterUpdateLocation"
      :maxTabShown="maxBannerItem"
      />
    </div>
    <div class="home-filter-dropdown ds-m-b-l">
      <PFGuidedSearchComponent @dropDownStateChange="updateDropdownState" />
    </div>
    <div class="home-filter-dropdown search-button-layout"
    :class="{'large-margin-top' : showDropdown}">
      <div class="button-container">
      <button class="ds-button ds-button--primary .ds-button--icon-button search-button" type="button"
      @click.prevent="submitForm">
      <i v-if="loading && this.checkFilterSelected" class="ds-loading-indicator ds-m-r-xs"></i>
      <i v-else class="ds-icon--search ds-icon--big ds-m-r-xs"></i>
        <span class="search-button-text">
          {{(!this.checkFilterSelected || loading) ? 'Search Partner' : 'Show '+countResults+' Results'}}
        </span></button>
      </div>
    </div>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "../../assets/css/common.less";
.home-search-container {
  height: 400px;
  @media (min-width: 686px){
    height: 458px;
  }
}
.heading-body-title {
  max-width: 470px;
}
.home-filter-container {
  margin-bottom: 1.1rem;
}
.heading-body-text{
  max-width: 502px;
  font-size: 19px !important;
  line-height: 29px !important;
  &:extend(.font-normal);
}
.pf-banner {
  padding-left: 6.91%;
  padding-right: 6.91%;
  padding-top: 90px;
  padding-bottom: 24px;
}
.ds-loading-indicator--container {
  z-index: 90;
}
.search-button-layout {
  margin-bottom: 136px;
}
.seach-page-title-container {
  width: 100%;
  position: relative;
}
.search-page-title {
  display: block;
  max-width: 510px;
  height: 29px;
  font-weight: 400;
  font-size: 35px;
  line-height: 31px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 84px;
}
.large-margin-top {
  margin-top: 60px;
}
.home-filter-dropdown {
  width: 100%;
  position: relative;
  padding-left: 6.91%;
  padding-right: 6.91%;
}
.search-button{
  display: flex;
  max-width: none !important;
  line-height: 31px;
}
.search-button-text{
  font-weight: 390;
  font-size: 21px;
  line-height: 31px;
}
.pf-banner-container {
  background: linear-gradient(90deg, rgba(0, 0, 0, 90%) 0%, rgba(0, 0, 0, 0) 90%),

  url(../../assets/mainbanner_sm_new.avif);
  @media (min-width: 450px){
      background: linear-gradient(90deg, rgba(0, 0, 0, 90%) 0%, rgba(0, 0, 0, 0) 90%),

      url(../../assets/mainbanner_md_new.avif);
      background-repeat: no-repeat;
      background-size: 100% 100%, cover;
      background-position-x: center;
      background-position-y: 40%;
      width: 100%;
      height: 100%;
      min-height: 400px;
  }
  @media (min-width: 686px){
      background: linear-gradient(90deg, rgba(0, 0, 0, 90%) 0%, rgba(0, 0, 0, 0) 80%),

      url(../../assets/mainbanner_lg_new.avif);
      background-repeat: no-repeat;
      background-size: 100% 100%, cover;
      background-position-x: center;
      background-position-y: 40%;
      width: 100%;
      height: 100%;
      min-height: 400px;
  }
  background-repeat: no-repeat;
  background-size: 100% 100%, cover;
  background-position-x: center;
  background-position-y: 40%;
  width: 100%;
  height: 100%;
  min-height: 400px;

  .ds-heading--xl {
    color: @color-white;
    &:extend(.font-normal);
    font-weight: bold ;
    font-size: 33px ;
  }

  .ds-body--l {
    color: @color-white;
    font-weight: 400 ;
    font-size: 21px ;
  }

  .pf-home-text-container {
    max-width: 1680px;
    margin-left: auto;
    margin-right: auto;
  }

  .button-container {
    max-width: 1680px;
    margin-left: auto;
    margin-right: auto;
  }
}

</style>
