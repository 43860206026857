<template>
  <svg :width="width" :height="height" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" automation-id="accept-icon">
    <g id="accept">
      <path id="Icon"
        d="M5.8125 12C5.60417 12 5.42708 11.9271 5.28125 11.7812L2.25 8.875C2.08333 8.70833 2 8.52083 2 8.3125C2 8.08333 2.07292 7.89583 2.21875 7.75C2.38542 7.58333 2.58333 7.5 2.8125 7.5C2.97917 7.5 3.15625 7.57292 3.34375 7.71875L5.8125 10.0938L12.625 3.25C12.7917 3.08333 12.9792 3 13.1875 3C13.3958 3 13.5833 3.08333 13.75 3.25C13.9167 3.41667 14 3.60417 14 3.8125C14 4.04167 13.9271 4.22917 13.7812 4.375L6.40625 11.7812C6.26042 11.9271 6.0625 12 5.8125 12Z"
        :fill="color" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'AcceptIcon',
  props: {
    width: {
      type: [String, Number],
      default: 16
    },
    height: {
      type: [String, Number],
      default: 16
    },
    color: {
      type: String,
      default: '#223548'
    }
  }
};
</script>
