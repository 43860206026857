<script>
import '@sapudex/web-components/dist/Modal.js';
import '@sapudex/web-components/dist/Button.js';
import '@sapudex/web-components/dist/Link.js';
import '@sapudex/web-components/dist/ControlButton.js';
import '@ui5/webcomponents-fiori/dist/Bar.js';
import '@ui5/webcomponents/dist/Table.js';
import '@ui5/webcomponents/dist/TableColumn.js';
import '@ui5/webcomponents/dist/TableRow.js';
import '@ui5/webcomponents/dist/TableCell.js';

const urlLinks = require('@/plugins/linkUrl');

const QPPS_CATEGORY = 'At_A_Glance_Detail_VPUC';
const QPPS_LEARN_MORE = 'QPPS_Learn_More';

export default {
  name: 'PFQPPSModalComponent',
  data: () => ({
    faqLink: urlLinks.LEARN_MORE_QPPS,
    VPUCLearnMore: urlLinks.LEARN_MORE_VPUC,
    showQPPSDetailModal: false,
    selectedQPPSDetail: {},
    QPPSCategory: QPPS_CATEGORY,
    QPPSLearnMore: QPPS_LEARN_MORE,
    QPPSContainerWidth: 200,
    QPPSImgWidth: '194.62px',
    QPPSImgHeight: '46px',
    VPUCImgHeight: '46px',
    VPUCImgWidth: '177px',
    QPPSCountFontSize: '36px',
    QPPSCountLineHeight: '40px',
  }),
  props: {
    toggleModal: { type: Function, default: () => { } },
    QPPSCount: { type: Number, default: 0 },
    VPUCCount: { type: Number, default: 0 },
    openDetailsModal: { type: Function, default: () => { } },
    getQPPS: { type: Array, default: () => [] },
    getVPUC: { type: Array, default: () => [] },
  },
  computed: {
    QPPSContainerWidthStyle() {
      return `${this.QPPSContainerWidth}px`;
    },
    CountContainerWidthCurr() {
      return this.$refs?.countContainer?.clientWidth;
    },
  },
  methods: {
    openInNewTab(url) {
      const win = window.open(url, '_blank');
      win.focus();
    },
    emitSWAEvent(QPPSCategory, QPPSName, QPPSCountry) {
      if (!this.editMode) {
        // eslint-disable-next-line vue/custom-event-name-casing
        this.$eventBus.$emit('PFAACustomEvent', {
          eventName: 'ClickModal',
          custom2: QPPSCategory,
          custom3: QPPSName,
          custom4: QPPSCountry,
        });
      }
    },
  },
};
</script>

<template>
  <udex-modal id="modal" type="defalt" :open="true" ref="pfQPPSmodal" tabindex="0" @keydown.esc="toggleModal(false)"
    block-layer-hidden on-desktop media-range="XL" automation-id="partnerQPPSModal">
    <ui5-bar class="header" slot="header" design="Header" data-sap-ui-fastnavgroup="true">
      <h3 slot="startContent" automation-id="partnerQPPSModal-heading">
        {{
        this.$showVPUCFeature
        ? $tc("accelerate.pacakge.header", QPPSCount + VPUCCount)
        : $tc("QPPS.modal.title", QPPSCount + VPUCCount)
        }}
      </h3>
      <udex-control-button @click="toggleModal(false)" class="closeDialog" icon="decline" has-icon slot="endContent"
        icon-only accessible-name="close" automation-id="partnerQPPSModal-headerCloseButton"></udex-control-button>
    </ui5-bar>

    <div class="profile-QPPS-text margin-bottom-fixed">
      <div ref="countContainer" class="count-container">
        <div class="QPPS-count-container" v-if="QPPSCount > 0">
          <div class="profile-QPPS-count-text certer-text" automation-id="partnerQPPSModal-QPPSCount">
            {{ QPPSCount }}
          </div>
          <div class="QPPS-img-container certer-text">
            <img alt="" class="QPPS-img" src="../../assets/SAP_Qualified_PartnerPackageSolution_R.png"
              automation-id="partnerQPPSModal-QPPSImage" />
          </div>
        </div>
        <div class="VPUC-count-container" v-if="VPUCCount > 0">
          <div class="profile-QPPS-count-text certer-text" automation-id="partnerQPPSModal-VPUCCount">
            {{ VPUCCount }}
          </div>
          <div class="QPPS-img-container certer-text">
            <img automation-id="partnerQPPSModal-VPUCImage" alt="" class="VPUC-img"
              src="../../assets/SAP_Validated_Partner_Use_Case_R.png" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="QPPSCount > 0" class="profile-QPPS-text ds-m-t-l">
      <div class="package-table-header" automation-id="partnerQPPSModal-QPPSDescriptionHeader">
        {{ $tc("QPPS.modal.title", getQPPS.length) }}
      </div>
      <div class="profile-QPPS-text" automation-id="partnerQPPSModal-QPPSDescriptionText">
        {{ $t("profile.QPPS.modal.text") }}
        <udex-link automation-id="partnerQPPSModal-QPPSDescriptionLearnMoreLink" @click.prevent="openInNewTab(faqLink)"
          accessibleRole="Link">
          {{ $t("learn.more") }}
        </udex-link>
      </div>
    </div>

    <ui5-table v-if="QPPSCount > 0">
      <ui5-table-row v-for="(country, idx) in getQPPS" :key="country.countryCode + '-' + idx">
        <ui5-table-cell v-if="country.QPPS.length > 0" class="IE-tbl-layout vertical-align-cell">
          <span class="solution-competency-item-container" automation-id="partnerQPPSModal-QPPSCountryName">{{
            country.countryName
            }}</span>
        </ui5-table-cell>
        <ui5-table-cell v-if="country.QPPS.length > 0" class="IE-tbl-layout-list vertical-align-cell">
          <div class="profile-specialization-text" v-for="(product, idx) in country.QPPS"
            :key="product.country + '-' + 'product' + '-' + idx" @click="
              openDetailsModal(product);
            emitSWAEvent(QPPSCategory, product.packageName, product.country);
            ">
            <udex-link icon="slim-arrow-right" icon-placement="end" @click.prevent="showAllToggle = !showAllToggle"
              automation-id="partnerQPPSModal-OPPSProfileSpecializationLink">
              {{ product.packageName }}
            </udex-link>
          </div>
        </ui5-table-cell>
      </ui5-table-row>
      <ui5-table-column slot="columns-1">
        <span class="IE-tbl-header"></span>
      </ui5-table-column>
      <ui5-table-column slot="columns-2" min-width="500" popin-text="" demand-popin class="table-header-text-alignment">
        <span class="IE-tbl-header"></span>
      </ui5-table-column>
    </ui5-table>

    <div v-if="VPUCCount > 0" class="profile-VPUC-text ds-m-t-l">
      <div class="package-table-header" automation-id="partnerQPPSModal-VPUCDescriptionHeader">
        {{ $tc("VPUC.table.header", getVPUC.length) }}
      </div>
      <div class="profile-QPPS-text" automation-id="partnerQPPSModal-VPUCDescriptionText">
        {{ $t("VPUC.modal.description") }}
        <udex-link @click.prevent="openInNewTab(VPUCLearnMore)"
          automation-id="partnerQPPSModal-VPUCDescriptionLearnMoreLink" accessibleRole="Link">
          {{ $t("learn.more") }}
        </udex-link>
      </div>
    </div>

    <ui5-table v-if="VPUCCount > 0">
      <ui5-table-row v-for="(country, idx) in getVPUC" :key="country.countryCode + '-' + idx">
        <ui5-table-cell v-if="country.VPUC.length > 0" class="IE-tbl-layout vertical-align-cell">
          <span class="solution-competency-item-container" automation-id="partnerQPPSModal-VPUCCountryName">{{
            country.countryName
            }}</span>
        </ui5-table-cell>
        <ui5-table-cell v-if="country.VPUC.length > 0" class="IE-tbl-layout-list vertical-align-cell">
          <div class="profile-specialization-text" v-for="(product, idx) in country.VPUC"
            :key="product.country + '-' + 'product' + '-' + idx" @click="
              openDetailsModal(product);
            emitSWAEvent(QPPSCategory, product.packageName, product.country);
            ">
            <udex-link icon="slim-arrow-right" icon-placement="end" @click.prevent="showAllToggle = !showAllToggle"
              automation-id="partnerQPPSModal-VPUCProfileSpecializationLink">
              {{ product.packageName }}
            </udex-link>
          </div>
        </ui5-table-cell>
      </ui5-table-row>
      <ui5-table-column slot="columns">
        <span class="IE-tbl-header"></span>
      </ui5-table-column>
      <ui5-table-column slot="columns" min-width="500" popin-text="" demand-popin class="table-header-text-alignment">
        <span class="IE-tbl-header"></span>
      </ui5-table-column>
    </ui5-table>

    <ui5-bar class="footer" slot="footer" design="Footer" data-sap-ui-fastnavgroup="true">
      <udex-button @click="toggleModal(false)" design="Emphasized" size="Large" slot="endContent"
        automation-id="partnerQPPSModal-footerCloseButton">
        {{ $t("button.close") }}
      </udex-button>
    </ui5-bar>
  </udex-modal>
</template>

<style lang="less" scoped>
ui5-table-row:last-child tr{
  border-bottom-width: 0 !important;
  ;
}
.count-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.package-table-header {
  &:extend(.HeadingMediumXXXS-size);
  font-size: 18px;
  line-height: 20px;
}

.learn-more-link {
  &:extend(.body-s-size);
  cursor: pointer;
  color: #0070f2;
}

.VPUC-count-container,
.QPPS-count-container {
  width: v-bind("QPPSContainerWidthStyle");
}

.QPPS-count-container {
  margin-right: 20px;
}

.QPPS-img {
  width: v-bind("QPPSImgWidth");
  height: v-bind("QPPSImgHeight");
}

.VPUC-img {
  width: v-bind("VPUCImgWidth");
  height: v-bind("VPUCImgHeight");
}

.certer-text {
  text-align: center;
}

.profile-VPUC-text {
  margin-top: 56px !important;
}

.profile-QPPS-count-text {
  margin-bottom: 6px;
  &:extend(.HeadingRegularL-size);
  font-size: v-bind("QPPSCountFontSize");
  line-height: v-bind("QPPSCountLineHeight");
  color: #223548;
}

.margin-bottom-fixed {
  margin-bottom: 32px;
}

.profile-QPPS-text {
  &:extend(.body-s-size);
  color: #223548 !important;
  max-width: 532px;
  margin-top: 10px;
}

.profile-specialization-text {
  &:extend(.body-s-size);
  font-size: 16px;
  line-height: 24px;
  color: @color-grey-0;
  cursor: pointer;
  max-width: 350px;
  margin-bottom: 8px;
}

.profile-specialization-text:last-child {
  margin-bottom: unset;
}

.profile-specialization-text:hover {
  color: @color-link;
}

.QPPS-img-container {
  height: 47px;
}

.IE-tbl-header {
  height: 0;
}

#modal h3 {
  &:extend(.HeadingMediumXS-size);
  color: #000000;
}

.solution-competency-item-container {
  &:extend(.body-s-size);
  color: #223548;
  padding-left: 0 !important;
}

.vertical-align-cell {
  vertical-align: top;
}

#modal ui5-bar {
  height: auto;
}

#modal ui5-bar::part(bar) {
  margin-left: -12px;
  margin-right: -12px;
  width: auto;
}

#modal ui5-bar.header::part(bar) {
  margin-right: -24px;
}

#modal .closeDialog {
  --udex-button-icon-l-size: 24px;
  --udex-button-color-tertiary-standard-default-text-and-icon: var(--udexColorGrey7, #475e75);
}

#modal .IE-tbl-layout {
  width: 170px;
}

#modal .IE-tbl-layout-list {
  width: 359px;
}

@media (max-width: 639px) {
  .solution-competency-item-container {
    &:extend(.body-s-size);
    color: #223548;
    margin-left: -10px;
  }
}

@media (max-width: 550px) {
  .solution-competency-item-container {
    &:extend(.body-s-size);
    color: #223548;
    margin-left: -10px;
  }

  .profile-QPPS-count-text {
    &:extend(.HeadingRegularL-size);
    margin-bottom: 6px;
    font-size: 36px;
    line-height: 40px;
    color: #223548;
  }

  .QPPS-img {
    width: 135px;
    height: 32px;
  }

  .VPUC-img {
    width: 123px;
    height: 32px;
  }

  .QPPS-img-container {
    height: unset;
  }
}

@media (min-width: 627px) {
  .IE-tbl-layout {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .IE-tbl-layout-list {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

@media (max-width: 626px) {
  .IE-tbl-layout {
    padding-top: 12px;
  }

  .IE-tbl-layout-list {
    padding-top: 0;
    padding-bottom: 4px;
  }
}
</style>
