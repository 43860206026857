<script>
import { mapActions, mapState } from 'vuex';
import { onClickOutside, useScrollLock } from '@vueuse/core';
import {
  ref, watch, onMounted, computed,
} from 'vue';
import { useScreenSize } from '@/composables/useScreenSize';
import PFCloudCompetencyToggleComponent from './PFCloudCompetencyToggleComponent';
import PFAccordionSortMobileComponent from '../partnerNavigatorPage/PFAccordionSortMobileComponent';
import PFAccordionFilterMobileComponent from '../partnerNavigatorPage/PFAccordionFilterMobileComponent';
import PFAccordionSolutionFilterMobileComponent from '../partnerNavigatorPage/PFAccordionSolutionFilterMobileComponent';
import '@sapudex/web-components/dist/RadioButton.js';
import '@sapudex/web-components/dist/Search.js';
import '@sapudex/web-components/dist/Checkbox.js';
import '@sapudex/web-components/dist/List.js';
import '@ui5/webcomponents/dist/BusyIndicator.js';

const sortOptions = require('@/plugins/search/sortOptions');

export default {
  name: 'PFMobileFilterDrawerComponent',
  components: {
    PFCloudCompetencyToggleComponent,
    PFAccordionSortMobileComponent,
    PFAccordionFilterMobileComponent,
    PFAccordionSolutionFilterMobileComponent,
  },
  data: () => ({
    typesSort: sortOptions.SORT_OPTIONS,
    lastEvent: null,
    selectedItemObject: { type: Object, default: () => {} },
    searchValue: '',
  }),
  setup() {
    const target = ref(null);
    const bodyRef = ref(null);
    const isDrawerOpen = ref(false);
    const componentKey = ref(0);
    const { isScreenSizeMoreSM } = useScreenSize();
    const lockScroll = useScrollLock(bodyRef);
    const filterDrawerButtonSize = computed(() => (isScreenSizeMoreSM.value ? 'Medium' : 'Small'));

    onClickOutside(target, () => {
      isDrawerOpen.value = false;
      componentKey.value += 1;
    });

    watch(isDrawerOpen, (newValue) => {
      lockScroll.value = newValue;
    });
    onMounted(() => {
      bodyRef.value = document.body;
    });

    return {
      target,
      isDrawerOpen,
      componentKey,
      filterDrawerButtonSize,
    };
  },

  mounted() {
    document.addEventListener('keydown', this.handleEscKey);
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleEscKey);
  },
  computed: {
    ...mapState('profileSearch', {
      countResults: (state) => state.countResults,
      query: (state) => state.query,
      loading: (state) => state.loading,
    }),
    drawerToggle() {
      return this.isDrawerOpen ? 'opened' : 'closed';
    },

    getShowButtonText() {
      return `${this.$t('filter.show.button')} ${this.countResults} ${this.$tc('results.text', this.countResults)}`;
    },
    noSearchResults() {
      return this.filteredActiveOptions.length === 0 && this.filteredDisabledOptions.length === 0;
    },
  },
  methods: {
    ...mapActions('profileSearch', {
      cleanFilters: 'cleanFilters',
    }),
    onReset() {
      this.cleanFilters();
      this.componentKey += 1;
    },
    handleEscKey(event) {
      if (event.key === 'Escape') {
        this.isDrawerOpen = false;
      }
    },
    closeDrawer() {
      this.isDrawerOpen = false;
    },
    changeSortType(key, value) {
      this.$store.dispatch('profileSearch/setSelectedSortingOptionText', key);
      this.$store.dispatch('profileSearch/setSorting', value);
      this.$eventBus.$emit('PFAACustomEvent', { eventName: 'SearchInitiated', custom2: this.query });
    },
  },
};
</script>

<template>
  <div automation-id="filterDrawer-drawerContainer" id="filter-and-sort" class="filter-and-sort">
    <udex-button automation-id="filterDrawer-filterAndSortButton" id="drawer-trigger" @click="this.isDrawerOpen = true" :size.prop="this.filterDrawerButtonSize"> {{
      this.$t("mobile.filter.button")
    }}</udex-button>

    <div automation-id="filterDrawer-drawerWrapper" id="drawer" class="filter-drawer-wrapper" :class="drawerToggle">
      <ui5-busy-indicator
        size="Medium"
        ref="target"
        class="filter-busy-indicator"
        :active.prop="loading"
        :delay="200"
        id="drawer-busy-indicator"
        automation-id="filterDrawer-busyIndicator"
      >
        <div class="filter-drawer" automation-id="filterDrawer-drawer">
          <div class="filter-header" automation-id="filterDrawer-drawerHeader">
            <div class="filter-buttons">
              <udex-button class="show-results" @click="closeDrawer" automation-id="filterDrawerHeader-showResultButton">{{ getShowButtonText }} </udex-button>
              <udex-button class="clear-filters" @click="onReset" design="Transparent" automation-id="filterDrawerHeader-showResetButton">{{
                this.$t("filter.reset.button")
              }}</udex-button>
            </div>
            <udex-button id="close-button" icon="decline" design="Transparent" @click="closeDrawer" automation-id="filterDrawerHeader-closeButton"></udex-button>
          </div>
          <div class="filter-content">
            <div class="accordion-wrapper" role="list">
              <!-- Sorting -->
              <PFAccordionSortMobileComponent />
              <!-- Location filter -->
              <PFAccordionFilterMobileComponent
                :key="componentKey"
                :title="$t('search.filter.location')"
                filter-type="location"
                search-bar
              />
              <!-- Solution filter -->
              <PFAccordionSolutionFilterMobileComponent
                :key="componentKey"
                :title="$t('search.filter.solution')"
                filter-type="product"
                search-bar
              />
              <!-- SAP Recognitions filter  -->
              <PFAccordionFilterMobileComponent :title="$t('search.filter.designation')" filter-type="designation" />

              <!-- Focus Industry filter  -->
              <PFAccordionFilterMobileComponent
                :key="componentKey"
                :title="$t('search.filter.industry')"
                filter-type="industry"
                search-bar
              />
              <!-- Partnership Types filter  -->
              <PFAccordionFilterMobileComponent :title="$t('search.filter.business.model')" filter-type="engagement" />
            </div>
          </div>
          <!-- Cloud competency toggle  -->
          <PFCloudCompetencyToggleComponent
            :title="$t('search.filter.cloud.competency')"
            class="cloud-competency-toggle"
            titleFontSize="medium"
          />
        </div>
      </ui5-busy-indicator>
    </div>
  </div>
</template>

<style lang="less" scoped>
.filter-and-sort {
  --filter-drawer-width: 500px;
}
udex-search.checkbox-search {
  margin: 5px;
}
.cloud-competency-toggle {
  margin-top: 8px;
}
.filter-and-sort {
  font-family: var(--sapFontFamily);
  z-index: 100;
}
.filter-drawer-wrapper {
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  display: none;
  width: 100%;
  container-type: inline-size;
}
.filter-drawer {
  position: relative;
  width: var(--filter-drawer-width);
  right: 0;
  height: 100%;
  background: #fff;
  padding: var(--udexSpacer16) var(--udexSpacer32);
  overflow: visible;
}
.filter-busy-indicator {
  display: flex;
  background-color: var(--sapBackgroundColor);
  position: absolute;
  width: var(--filter-drawer-width);
  right: 0;
  height: auto;
  min-height: 100%;
}

.filter-drawer-wrapper.opened {
  display: grid;
  height: 100%;
  justify-content: end;
  overflow-y: auto;
}
.filter-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accordion-wrapper {
  padding: var(--udexSpacer16) 0;
}
.show-results {
  margin-right: 8px;
}
@media (max-width: 580px) {
  .filter-busy-indicator,
  .filter-drawer {
    width: 100vw;
  }
}
</style>
