import { computed } from 'vue';
import { useWindowSize } from '@vueuse/core';

const screenSizes = Object.freeze({
  xs: 375,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1329,
  '3xl': 1440,
  '4xl': 1600,
});

export const useScreenSize = () => {
  const { width: windowWidth } = useWindowSize();

  return {
    isScreenSizeMoreXS: computed(() => windowWidth.value > screenSizes.xs),
    isScreenSizeLessSM: computed(() => windowWidth.value < screenSizes.sm),
    isScreenSizeMoreSM: computed(() => windowWidth.value >= screenSizes.sm),
    isScreenSizeLessMD: computed(() => windowWidth.value < screenSizes.md),
    isScreenSizeMoreMD: computed(() => windowWidth.value >= screenSizes.md),
    isScreenSizeLessLG: computed(() => windowWidth.value < screenSizes.lg),
    isScreenSizeMoreLG: computed(() => windowWidth.value > screenSizes.lg),
    isScreenSizeLessXL: computed(() => windowWidth.value < screenSizes.xl),
    isScreenSizeMoreXL: computed(() => windowWidth.value >= screenSizes.xl),
    isScreenSizeLess2XL: computed(() => windowWidth.value < screenSizes['2xl']),
    isScreenSizeMore2XL: computed(() => windowWidth.value >= screenSizes['2xl']),
    isScreenSizeLess3XL: computed(() => windowWidth.value < screenSizes['3xl']),
  };
};

export default useScreenSize;
