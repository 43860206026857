<script>
import { mapState } from 'vuex';
import PFQPPSModalComponent from './PFQPPSModalComponent';
import PFQPPSDetailModalComponent from './PFQPPSDetailModalComponent';

const QPPS_CATEGORY = 'At_A_Glance_VPUC';

export default {
  name: 'PFQPPSGlanceComponent',
  data: () => ({
    showQPPSModal: false,
    showQPPSDetailModal: false,
    selectedQPPSDetail: {},
    QPPSCategory: QPPS_CATEGORY,
    showBackButton: true,
  }),
  components: {
    PFQPPSModalComponent,
    PFQPPSDetailModalComponent,
  },
  props: {
    editMode: { type: Boolean, default: false },
  },
  methods: {
    toggleModal(flag) {
      this.showQPPSModal = flag;
    },
    toggleDetailModal(flag) {
      this.showQPPSDetailModal = flag;
    },
    openDetailsModal(country) {
      this.selectedQPPSDetail = country;
      this.toggleDetailModal(true);
      this.toggleModal(false);
    },
    closeDetailModal() {
      this.toggleDetailModal(false);
      this.toggleModal(true);
    },
    emitSWAEvent(QPPSCategory) {
      if (!this.editMode) {
        // eslint-disable-next-line vue/custom-event-name-casing
        this.$eventBus.$emit('PFAACustomEvent',
          {
            eventName: 'ClickModal', custom2: QPPSCategory,
          });
      }
    },

  },
  computed: {
    ...mapState('partnerProfile', {
      localProfiles: (state) => state.profileData.localProfiles,
    }),
    getQPPSAndVPUC() {
      const results = {
        count: 0, countQPPS: 0, countVPUC: 0, countryQPPS: [], countryVPUC: [],
      };
      this.localProfiles?.filter((profile) => profile.packagedSolutions
            && profile.packagedSolutions.length > 0).forEach((profile) => {
        const countryQPPS = {
          countryName: profile.countryName,
          countryCode: profile.country,
          QPPS: [],
        };
        const countryVPUC = {
          countryName: profile.countryName,
          countryCode: profile.country,
          VPUC: [],
        };
        profile.packagedSolutions.forEach((solution) => {
          if (solution.packageType === 'Validated Partner Use Cases') {
            countryVPUC.VPUC.push(solution);
            results.countVPUC++;
          } else {
            countryQPPS.QPPS.push(solution);
            results.countQPPS++;
          }
          results.count++;
        });
        if (countryQPPS.QPPS.length > 0) {
          results.countryQPPS.push(countryQPPS);
        }
        if (countryVPUC.VPUC.length > 0) {
          results.countryVPUC.push(countryVPUC);
        }
      });
      return results;
    },
  },
  watch: {
    getQPPSAndVPUC: {
      handler(newValue) {
        this.$emit('update-qpps-vpuc-exist', newValue.count > 0);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<template>
  <div class="pf-QPPS-container" v-if="getQPPSAndVPUC.count>0">
    <div class="pf-QPPS-data-container partner-detail-at-glance-font"
      @click="toggleModal(true);emitSWAEvent(QPPSCategory)">
      <span class="font-bold">{{getQPPSAndVPUC.count}}</span>
      <span v-html="this.$showVPUCFeature ? $tc('QPPS.glance.title', getQPPSAndVPUC.count)
        : $tc('QPPS.glance.old.title', getQPPSAndVPUC.count)"></span>
      <img alt="Right Arrow" class="pf-QPPS-right-arrow" src="../../assets/Right_Arrow_Icon.png"/>
    </div>
    <PFQPPSModalComponent v-if="showQPPSModal"
      :openDetailsModal="openDetailsModal"
      :toggleModal="toggleModal"
      :QPPSCount="getQPPSAndVPUC.countQPPS"
      :VPUCCount="getQPPSAndVPUC.countVPUC"
      :getQPPS="getQPPSAndVPUC.countryQPPS"
      :getVPUC="getQPPSAndVPUC.countryVPUC"/>
    <PFQPPSDetailModalComponent
      v-if="showQPPSDetailModal"
      :toggleModal="toggleDetailModal"
      :QPPSDetail="selectedQPPSDetail"
      :backButtonAction="closeDetailModal"
      :showBackButton="showBackButton"
      :QPPSCount="getQPPSAndVPUC.countQPPS"
      :VPUCCount="getQPPSAndVPUC.countVPUC"/>
  </div>
</template>

<style lang="less" scoped>
@import "../../assets/css/common.less";

.pf-QPPS-container {
  display: -moz-box;
  display: -webkit-box;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  min-height: 0 !important;
  cursor: default;
  margin-top: 8px;
}
.pf-QPPS-data-container {
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  align-items: center;
  width: fit-content;
}
.pf-QPPS-data-container:hover {
  cursor: pointer;
  color: @color-link;
}
.pf-QPPS-right-arrow {
  width: 16px;
  height: 16px;
  margin: 4px 0;
}

</style>
