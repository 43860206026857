<script>

const urlLinks = require('@/plugins/linkUrl');

export default {
  name: 'PFForbiddenPage',
  props: {
    isFromPfEditor: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    forbiddenCodes: [],
    isDefaultMessage: true,
    learnMoreLinks: urlLinks.LEARN_MORE_LINKS,
  }),
  mounted() {
    if (this.$route.params.forbiddenCodes) {
      this.forbiddenCodes = this.$route.params.forbiddenCodes;
    }
    this.isDefaultMessage = this.forbiddenCodes.length === 0;
  },
  methods: {
    containsForbiddenErrorCode(code) {
      return this.forbiddenCodes.some((item) => item === code);
    },
  },
};
</script>

<template>
  <div>
    <div class="header"> {{ $t('forbidden.header') }}</div>
    <div v-if="isDefaultMessage" class="text"
         v-html="isFromPfEditor ? $t('forbidden.default.editor.text') : $t('forbidden.default.text')"></div>
    <div v-if="containsForbiddenErrorCode('NOT_OWN_PROFILE')" class="text">
      {{ $t('forbidden.wrong.partner.text') }}
      <a :href="learnMoreLinks.NOT_OWN_PROFILE" rel="noopener noreferrer" target="_blank">
        {{ $t('learn.more') }}
      </a>
    </div>
    <div v-if="containsForbiddenErrorCode('NOT_AUTHORIZED')" class="text">
      {{ $t('forbidden.permission.text') }}
      <a :href="learnMoreLinks.PERMISSION" rel="noopener noreferrer" target="_blank">
        {{ $t('learn.more') }}
      </a>
    </div>
    <div v-if="containsForbiddenErrorCode('DUE_DILIGENCE_FLAG')" class="text">
      {{ $t('forbidden.due.diligence.text') }}
      <a :href="learnMoreLinks.DUE_DILIGENCE" rel="noopener noreferrer" target="_blank">
        {{ $t('learn.more') }}
      </a>
    </div>
    <div v-if="containsForbiddenErrorCode('NO_VALID_PARTNER_TYPE')" class="text">
      {{ $t('forbidden.partner.type.status.text') }}
      <a :href="learnMoreLinks.PARTNER_TYPE" rel="noopener noreferrer" target="_blank">
        {{ $t('learn.more') }}
      </a>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../assets/css/common.less";

.header {
  font-family: @sapFontFamily;
  line-height: @udexTypographyHeadingLineHeight;
  font-size: @udexTypographyHeadingMediumXLFontSize;
  font-weight: @udexTypographyFontWeightMedium;
  color: @udexCoreHeadingDark;
  margin-bottom: 16px;
  text-align: center;
}

.text {
  font-size: 20px;
  font-family: "72 Brand Variable";
  font-weight: 400;
  line-height: 30px;
  color: @udexCoreTextDark;
  text-align: center;
  margin-bottom: 40px;
  max-width: 754px;
}

@media (max-width: 360px) {
  .header {
    font-family: @sapFontFamily;
    line-height: @udexTypographyHeadingLineHeight;
    font-size: @udexTypographyHeadingMediumMFontSize;
    font-weight: @udexTypographyFontWeightMedium;
  }
  .text {
    font-size: @udexTypographyBodySFontSize;
    font-family: "72 Brand Variable";
    font-weight: @udexTypographyFontWeightRegular;
    line-height: 24px;
    margin-bottom: 32px;
  }
}

</style>
