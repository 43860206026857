<script>
import PFSiteLogoComponent from '@/components/common/PFSiteLogoComponent';
import PFHelpComponent from '@/components/common/PFHelpComponent';
import PFUserProfileComponent from '@/components/common/PFUserProfileComponent';

export default {
  name: 'PEHeaderSmallComponent',
  components: {
    PFSiteLogoComponent,
    PFHelpComponent,
    PFUserProfileComponent,
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  data: () => ({
    toggleChecked: true,
  }),
  methods: {
    handleScroll() {
      const header = document.getElementsByClassName('edit-header')[0];
      const logo = this.$refs.headerLogo;
      const helpIcon = this.$refs.helpIcon;
      const userIcon = this.$refs.userIcon;
      if (window.scrollY > 70) {
        header.style.height = '110px';
        logo.style.visibility = 'visible';
        helpIcon.style.visibility = 'visible';
        userIcon.style.visibility = 'visible';
        logo.style.opacity = 1;
        userIcon.style.opacity = 1;
        helpIcon.style.opacity = 1;
      } else {
        header.style.height = '80px';
        logo.style.visibility = 'hidden';
        helpIcon.style.visibility = 'hidden';
        userIcon.style.visibility = 'hidden';
        logo.style.opacity = 0;
        userIcon.style.opacity = 0;
        helpIcon.style.opacity = 0;
      }
    },
    switchToEditMode() {
      this.goToPath(`/editor/${this.$route.params.profileId}/edit`);
    },
    switchToPreviewMode() {
      this.goToPath(`/editor/${this.$route.params.profileId}/preview`);
    },
    goToPath(path) {
      if (this.isNotCurrentPath(path)) {
        this.$router.push({
          path,
        });
      }
    },
    isNotCurrentPath(path) {
      return !(this.$router.currentRoute.value.fullPath === path);
    },
    onToggleChange(event) {
      if (event.target.checked) {
        this.switchToEditMode();
      } else {
        this.switchToPreviewMode();
      }
    },
  },
  mounted() {
    const url = this.$route.path;
    if (url.endsWith('edit')) {
      this.toggleChecked = true;
    } else if (url.endsWith('preview')) {
      this.toggleChecked = false;
    }
  },
  computed: {

  },
  watch: {
    $route: {
      handler(to) {
        if (to.path.endsWith('edit')) {
          this.toggleChecked = true;
        } else if (to.path.endsWith('preview')) {
          this.toggleChecked = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<template>
  <div class="small-screen-header">
    <div class="small-screen-header-grey-container">
      <div class="edit-header--logo__sm" ref="headerLogo">
        <PFSiteLogoComponent :editorHeader="true" class="editor-site-logo-conatainer" />
      </div>
      <div class="edit-header-icon-bar">
        <div class="pe-page-header__help-block__sm" ref="helpIcon">
          <PFHelpComponent :editorHeader="true" />
        </div>
        <div class="pe-page-header__profile-block__sm" ref="userIcon">
          <PFUserProfileComponent :editorHeader="true" />
        </div>
      </div>
    </div>
    <div class="toggle-button-container">
      <div class="ds-toggle-button pe-toggle-button">
        <label class="ds-toggle-button-label pe-toggle-button-label">
          <input
            class="ds-toggle-button__input"
            type="checkbox"
            :checked="toggleChecked"
            id="PE-header-toggle"
            @change="onToggleChange($event)"
          />
          <div class="ds-toggle-button__toggle pe-toggle-button__toggle ds-m-r-xs"></div>
          <div class="ds-toggle-button__on pe-toggle-text font-normal">
            {{ $t('editor.header.mode.edit') }}
          </div>
          <div class="ds-toggle-button__off pe-toggle-text font-normal">
            {{ $t('editor.header.mode.preview') }}
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .small-screen-header {
    height: 100%;
    .small-screen-header-grey-container {
      display: flex;
      padding: 0 24px;
    }
    .toggle-button-container {
      width: 100%;
      position: absolute;
      bottom: 21px;
    }
    .edit-header-icon-bar {
    justify-content: flex-end;
    width: 100%;
    }
    .pe-toggle-button {
      flex: none;
      margin-left: calc(50% - 75px);
    }
    .edit-header--logo__sm {
      visibility: hidden;
      position: relative;
      opacity: 0;
      transition: visibility 0.3s, opacity 0.3s linear;
    }
    .pe-page-header__help-block__sm {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.3s, opacity 0.3s linear;
    }
    .pe-page-header__profile-block__sm {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.3s, opacity 0.3s linear;
    }
    .ds-sap-logo {
      margin: 0 !important;
    }
  }
</style>
