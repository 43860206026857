// The state of accordion items in a filter drawer.
// It keeps track of the currently expanded accordion and close it whne the next accordion is open .
const FiltersDrawerStore = {
  namespaced: true,
  state: {
    expandedAccordionItem: null,
  },
  actions: {
    setExpandedAccordionItem({ commit }, title) {
      commit('setExpandedAccordion', title);
    },
  },
  mutations: {
    setExpandedAccordion(state, title) {
      state.expandedAccordionItem = title;
    },
  },
};

export default FiltersDrawerStore;
