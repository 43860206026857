<script>

import '@sapudex/web-components/dist/Modal.js';
import '@sapudex/web-components/dist/Button.js';
import '@ui5/webcomponents-fiori/dist/Bar.js';
import '@sapudex/web-components/dist/Divider.js';
import "@sapudex/web-components/dist/ControlButton.js";

export default {
  name: 'PFBrandNamesModalComponent',
  props: {
    brandNames: { type: Array, default: () => [] },
    toggleAlternativeNamesModal: { type: Function, default: () => {} },
    alternativeNamesModalOpen: { type: Boolean, default: false },
  },
  data: () => ({}),
  computed: {
    editedBrandNames() {
      const tempBrandNames = this.brandNames.slice();
      tempBrandNames.sort((a, b) => a.brandName.localeCompare(b.brandName));
      for (let i = 0; i < tempBrandNames.length; i++) {
        if (tempBrandNames[i].isFavoriteName) {
          const buffer = tempBrandNames[i];
          tempBrandNames.splice(i, 1);
          tempBrandNames.unshift(buffer);
          break;
        }
      }
      return tempBrandNames;
    },
  },
};
</script>

<template>
  <div class="alt-names">
    <udex-modal id="modal" type="default" :open="alternativeNamesModalOpen">
        <ui5-bar class="header" slot="header" design="Header" data-sap-ui-fastnavgroup="true">
            <h3 class="HeadingMediumXS-size" slot="startContent">{{$t('additional.names')}}</h3>
            <udex-control-button
            @click="toggleAlternativeNamesModal"
              class="closeDialog"
              icon="decline"
              has-icon
              icon-only
              slot="endContent"
              accessible-name="close"
            ></udex-control-button>
          </ui5-bar>
        <div class="content-wrapper">
          <div class="body-s-size alt-name-main-first">
            {{ $tc('alt.name.main.first', brandNames.length) }}
          </div>
          <div
            class="alt-name-main-content-list"
            v-for="(info, idx) of editedBrandNames"
            :key="info.id"
          >

            <div class="HeadingMediumXXXXS-size alt-name-main-content-name">
              {{ info.brandName }}
            </div>
            <div class="body-s-size alt-name-main-content-description">
              {{ info.shortDescription }}
            </div>
            <udex-divider v-if="idx !== editedBrandNames.length - 1"/>
          </div>
        </div>

        <ui5-bar class="footer" slot="footer" design="Footer" data-sap-ui-fastnavgroup="true">
            <udex-button @click="toggleAlternativeNamesModal" design="Emphasized" size="Large" slot="endContent">
              {{ $t("button.close") }}
            </udex-button>
        </ui5-bar>

    </udex-modal>
  </div>
</template>

<style lang="less" scoped>

#modal .closeDialog {
  --udex-button-icon-l-size: 24px;
  --udex-button-color-tertiary-standard-default-text-and-icon: var(--udexColorGrey7, #475E75);
}

#modal .footer udex-button {
  margin: 0 0 0 16px;
}

#modal ui5-bar::part(bar) {
  /* Alternativelly header and footer paddings can be reduced to utilize 'ui5-bar' paddings.
  In this case overwrite following variables:
    --udexModalAndOverlayModalFooterPaddingHorizontal
    --udexModalAndOverlayModalHeaderPaddingHorizontal
  */
  margin-left: -12px;
  margin-right: -12px;
  width: auto;
}

#modal h3 {
  margin: 0px;
}

.alt-names{
  // min-width: 150px;
  // width: 100%;

.partner-name--brand-names {
  position: static;
}
.partner-name--brand-names :hover {
  color: @color-gray-1;
}
.partner-name--brand-names-text {
  &:extend(.font-normal);
  font-size: 16px;
  color: @color-gray-2;
}
.alt-names-modal {
  display: none;
}
.ds-modal--active {
  z-index: 200;
}
.modal-container-inner-upload {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 250px);
}
.alt-names-modal-box {
  max-width: 669px;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  max-height: 572px;
  min-height: 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
}
.ds-modal__box__header__heading {
  padding-right: 40px !important;
  &:extend(.font-expanded);
  font-size: 27px;
  line-height: 35px;
}
  .content-wrapper {
    max-width: 544px;
    }

.alt-name-main-first {
  // &:extend(.font-normal);
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
}
.alt-name-main-content-name {
  color: @udexColorGrey9;
  font-size: 16px;
  line-height: 24px;
  margin-top: 12px;
}
.alt-name-main-content-description {
  // &:extend(.font-normal);
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 12px;
}
.modal-icon {
  line-height: 200%;
  color: @color-gray-2;
}
.modal-icon:hover {
  color: @color-gray-1;
  cursor: pointer;
}
.alt-name-by-favourite-and-more{
  font-size: 12px;
}
}
</style>
