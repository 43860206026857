<script>
import { mapState } from 'vuex';
import '@sapudex/web-components/dist/Modal.js';
import '@sapudex/web-components/dist/Button.js';
import '@ui5/webcomponents-fiori/dist/Bar.js';
import '@ui5/webcomponents/dist/Table.js';
import '@ui5/webcomponents/dist/TableColumn.js';
import '@ui5/webcomponents/dist/TableRow.js';
import '@ui5/webcomponents/dist/TableCell.js';
import '@sapudex/web-components/dist/ControlButton.js';

const urlLinks = require('@/plugins/linkUrl');

export default {
  name: 'PFIECompetencyModalComponent',
  data: () => ({
    learnMoreLink: urlLinks.HELP_TEXT_LEARN_MORE.COMPETENCIES,
    showProfileCompetencyModal: { status: false, current: 0 },
    styles: { boxShadow: '', backgroundColor: '' },
  }),
  props: {
    toggleModal: { type: Function, default: () => {} },
    ieCompetency: { type: Object, default: () => ({}) },
  },
  computed: {
    ...mapState('partnerProfile', {
      solutionTransformed: (state) => state.solutionTransformed,
    }),
    solutionsL1() {
      return this.ieCompetency.solutionsL1
        .map((s) => this.solutionTransformed.find((st) => st.solutionL1Key === s.id))
        .sort((a, b) => a.sortOrder - b.sortOrder);
    },
  },
  methods: {
    toggleProfileCompetencyModal(val, obj = -1) {
      if (obj !== -1) {
        this.showProfileCompetencyModal.current = obj;
      }
      this.showProfileCompetencyModal.status = val;
      if (val) {
        this.emitAdobeEvent(this.solutionsL1[obj].solutionL1Name);
      }
    },
    emitAdobeEvent(name) {
      if (this.$route.name === 'profilePage') {
        this.$eventBus.$emit('PFAACustomEvent',
          { eventName: 'ClickModal', custom2: `IECompetency_Solution_${name}` });
      }
    },
    openInNewTab(url) {
      const win = window.open(url, '_blank');
      win.focus();
    },
  },
};
</script>

<template>
    <udex-modal
  id="modal"
  type="defalt"
  :open="true"
  @keydown.esc="toggleModal(false)"
  automation-id="partnerIECompetencyModal"

  >
  <ui5-bar class="header" slot="header" design="Header" data-sap-ui-fastnavgroup="true">
    <h3 slot="startContent" class="IE-modal-title"   automation-id="partnerIECompetencyModal-header"
    >{{ieCompetency.name}}</h3>
    <udex-control-button
    @click="toggleModal(false)"
      class="closeDialog"
      slot="endContent"
      icon="decline"
      icon-only
      has-icon
      accessible-name="close"
      automation-id="partnerIECompetencyModal-headerCloseButton"
    ></udex-control-button>
    </ui5-bar>

  <div class="profile-competency-text profile-competency-table">
    <div class="profile-competency-level-description" automation-id="partnerIECompetencyModal-levelDescription">
                      {{ `${this.$t('ie.competency.modal.description')}`
                      + ` "${ieCompetency.name}", `
                      + ieCompetency.description.charAt(0).toLowerCase() + ieCompetency.description.slice(1) }}
                      <udex-link
                        @click.prevent="openInNewTab(learnMoreLink)" automation-id="partnerIECompetencyModal-levelLearnMoreLink" accessibleRole="Link">
                        {{ $t("learn.more") }}
                      </udex-link>    </div>
<ui5-table
>
        <ui5-table-row>
            <ui5-table-cell class="IE-tbl-layout vertial-align-cell">
                <span class="solution-competency-item-container" automation-id="partnerIECompetencyModal-solutionCompetencyTitle">{{$t('solution.competencies')}}</span>
            </ui5-table-cell>

            <ui5-table-cell class="IE-tbl-layout-list vertial-align-cell">
                <div class="solution-item-container" v-for="(solution, idx) in solutionsL1"
                      :key="solution.solutionL1Key + '-' + idx">
                      <div class="solution-competency-item-container" automation-id="partnerIECompetencyModal-solutionCompetencyName">
                       &#8226;&nbsp;
                        {{ solution.solutionL1Name }}&nbsp;
                      </div>
                </div>
            </ui5-table-cell>
        </ui5-table-row>
        <ui5-table-row>
            <ui5-table-cell class="IE-tbl-layout vertial-align-cell">
                <span class="profile-competency-level-description mid-cell-bot guide-text-hours"  v-html="$t('customers.past.months')" automation-id="partnerIECompetencyModal-customersCelTitle"></span>
            </ui5-table-cell>

            <ui5-table-cell class="IE-tbl-num-layout">
                <span class="IE-num-col" automation-id="partnerIECompetencyModal-customersProjectsNumber"> {{ ieCompetency.projectsNum }}</span>
            </ui5-table-cell>
        </ui5-table-row>
        <ui5-table-column slot="columns">
            <span class="IE-tbl-header"></span>
        </ui5-table-column>

        <ui5-table-column slot="columns"
        min-width="500" popin-text="" demand-popin class="table-header-text-alignment">
            <span class="IE-tbl-header"></span>
        </ui5-table-column>

</ui5-table>
          </div>

  <ui5-bar class="footer" slot="footer" design="Footer" data-sap-ui-fastnavgroup="true">
    <udex-button @click="toggleModal(false)" design="Emphasized" size="Large" slot="endContent" automation-id="partnerIECompetencyModal-footerCloseButton">
      {{ $t("button.close") }}
    </udex-button>
  </ui5-bar>
</udex-modal>
</template>

<style lang="less" scoped>

.IE-tbl-header {
  height: 0px;
}
.IE-modal-title {
  &:extend(.HeadingMediumXS-size);
  color: #000000;
}
.solution-competency-item-container {
  &:extend(.body-s-size);
  color: #223548;
}

@media (min-width: 627px) {
  .IE-tbl-layout {
    padding-top:12px;
    padding-bottom: 12px;
  }
  .IE-tbl-num-layout {
    padding-top:12px;
    vertical-align: top;
    padding-bottom: 12px;
  }
  .IE-tbl-layout-list {
    padding-top:12px;
    padding-bottom: 12px;
  }
}
.IE-num-col {
      &:extend(.HeadingMediumXXXXS-size);
      color: #223548;
      line-height: 26px !important;
}

@media (max-width: 626px) {
  .solution-item-container {
    padding-left: 12px;
  }
  .IE-num-col {
    padding-left: 12px;
  }
  .IE-tbl-layout {
    padding-top:12px;
  }
  .IE-tbl-num-layout {
    vertical-align: top;
  }
  .IE-tbl-layout-list {
    padding-top:0px;
    padding-bottom: 4px;
  }
}

.vertial-align-cell {
  vertical-align: top;
}

#modal ui5-bar::part(bar){
  /* Alternativelly header and footer paddings can be reduced to utilize 'ui5-bar' paddings.
  In this case overwrite following variables:
    --udexModalAndOverlayModalFooterPaddingHorizontal
    --udexModalAndOverlayModalHeaderPaddingHorizontal
  */
  margin-left: -12px;
  margin-right: -12px;
  width: auto;
}
#modal ui5-bar.header::part(bar) {
  margin-right: -24px;
}
#modal .footer udex-button {
  margin: 0 0 0 16px;
}
#modal .closeDialog {
  --udex-button-icon-l-size: 24px;
  --udex-button-color-tertiary-standard-default-text-and-icon: var(--udexColorGrey7, #475E75);
}

.ds-icon--checked::before {
  font-size: 19px;
}
.inline-word-text {
  display: inline-block;
}
.no-split-text {
  display: inline-flex;
  align-content: center;
  white-space: nowrap;
  text-indent: 0px;
}
.cursor-pointer {
  cursor: pointer;
}
.profile-competency-level-description {
  &:extend(.body-s-size);
  color: #223548;
}

.mid-cell-bot {
  border-color: #eaeaea !important;
}

.solution-item {
  &:extend(.body-s-size);
  text-indent: -12px;
  padding-left: 12px;
}

.col-data {
  &:extend(.HeadingMediumXXXXS-size);
  vertical-align: middle;
  border-color: #eaeaea !important;
}

.competency-level-img {
  height: 30px;
  width: 170px;
}

.profile-competency-text {
  max-width: 544px;
}

.solution-competency-item {
  &:extend(.body-s-size);
  color: #223548 !important;
}

.solution-competency-item:hover {
  // color: @color-link__hover !important;
  cursor: default;
}
</style>
