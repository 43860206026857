<script>
import PFCloudCompetencyToggleComponent from '../common/PFCloudCompetencyToggleComponent';
import PFSolutionFilterComponent from './PFSolutionFilterComponent';
import PFMultiSelectCheckboxDropDownComponent from './PFMultiSelectCheckboxDropDownComponent';
import PFSortDropdownComponent from './PFSortDropdownComponent';

export default {
  name: 'PFFilterConfigurationBarComponent',
  components: {
    PFSolutionFilterComponent,
    PFCloudCompetencyToggleComponent,
    PFMultiSelectCheckboxDropDownComponent,
    PFSortDropdownComponent,
  },
};
</script>

<template>
  <div class="filters-result-page">
    <div class="filters-container">
      <PFMultiSelectCheckboxDropDownComponent :title="$t('search.filter.location')" :filterType="'location'" />
      <PFSolutionFilterComponent
        :filter-name="$t('search.filter.solution')"
        :search-placeholder="$t('filter.search.input.placeholder')"
      />
      <PFMultiSelectCheckboxDropDownComponent :title="$t('search.filter.designation')" :filterType="'designation'" />
      <PFMultiSelectCheckboxDropDownComponent :title="$t('search.filter.industry')" :filterType="'industry'" />
      <PFMultiSelectCheckboxDropDownComponent :title="$t('search.filter.business.model')" :filterType="'engagement'" />
      <PFCloudCompetencyToggleComponent :title="$t('search.filter.cloud.competency')" titleFontSize="small" />
    </div>
    <PFSortDropdownComponent />
  </div>
</template>

<style lang="less" scoped>
.filters-result-page {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  height: 34px;
}
.filters-container {
  display: flex;
  gap: 32px;
}
</style>
