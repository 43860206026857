<script>
import PFPartnerLogoComponent from '@/components/common/PFPartnerLogoComponent';
import PFPartnerTitleComponent from '@/components/common/PFPartnerTitleComponent';
import PFPartnerAnchorLinksComponent from '@/components/common/PFPartnerAnchorLinksComponent';

export default {
  name: 'PFPartnerDetailHeaderComponent',
  props: {
    toggleAlternativeNamesModal: { type: Function, default: () => {} },
    partnerProfileData: { type: Object, default: () => {} },
    isPreviewMode: {
      type: Boolean,
      default: false,
    },
    isSmallScreenMode: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    defaultHeader: true,
  }),
  components: {
    PFPartnerLogoComponent,
    PFPartnerTitleComponent,
    PFPartnerAnchorLinksComponent,
  },
  created() {
    if (!this.isSmallScreenMode) {
      window.addEventListener('scroll', this.handleScroll);
    }
  },
  unmounted() {
    if (!this.isSmallScreenMode) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  },
  methods: {
    handleScroll() {
      if (document.getElementById('partner-detail-header')) {
        this.defaultHeader = window.scrollY <= 10;
      }
    },
  },
};
</script>

<template>
  <div :class="['profile-header-container ds-flexRow ds-m-none ds-p-x-xl',
    defaultHeader ? 'profile-header-default' : 'profile-header-shrink',
    isSmallScreenMode ? 'profile-header-small-screen' : 'profile-header-large-screen']">
    <div class="profile-header-container-inner">
      <PFPartnerLogoComponent :logo="partnerProfileData.logoUrl"
        :title="partnerProfileData.name"
        :isSmallScreenMode="isSmallScreenMode"/>
      <div class="pf-header-title-container">
        <div class="pf-header-title-container-wrapper ds-m-x-none">
          <PFPartnerTitleComponent
            :title="partnerProfileData.name"
            :link="partnerProfileData.webSiteUrl"
            :brandNames="partnerProfileData.brandNames"
            :toggleAlternativeNamesModal="toggleAlternativeNamesModal"
          />
          <PFPartnerAnchorLinksComponent v-if="!isSmallScreenMode" :defaultHeader="defaultHeader"/>
          <span class="fade-overlay anchor-link-overlay"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
@import "../../assets/css/common.less";
.profile-header-small-screen {
  display: none !important;
  margin-top: 40px !important;
}

.profile-header-large-screen.profile-header-default {
  height: 134px;
  border-bottom: 1px solid @color-gray-4;
}
.profile-header-shrink {
  height: 110px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16), 0px 0px 2px rgba(0, 0, 0, 0.16);
}

.profile-header-container {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: @color-white;
  z-index: 10;
  transition: height 0.6s, box-shadow 1s;

  .profile-header-container-inner {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 1680px;
  }

  .pf-header-title-container {
    width: calc(100% - 80px);

    .pf-header-title-container-wrapper {
      width: 100%;
      display: flex;
      height: 100%;
    }
  }
  .anchor-link-overlay {
    display: none;
  }
}
</style>
