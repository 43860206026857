module.exports = {
  FAQ: 'https://gagps-viewer.cfapps.eu10.hana.ondemand.com/index.html#/tree/810/actions/8802?version=current',
  PUBLISH_FAQ: 'https://gagps-viewer.cfapps.eu10.hana.ondemand.com/index.html#/tree/782/actions/8500:9419',
  MANAGE_MY_PARTNER: 'https://pwp.sap.com',
  LEARN_MORE_LINKS: {
    PERMISSION: 'https://gagps-viewer.cfapps.eu10.hana.ondemand.com/index.html#/tree/782/actions/8500:8501:8941',
    NOT_OWN_PROFILE: 'https://gagps-viewer.cfapps.eu10.hana.ondemand.com/index.html#/tree/796/actions/8696?version=current',
    DUE_DILIGENCE: 'https://gagps-viewer.cfapps.eu10.hana.ondemand.com/index.html#/tree/793/actions/8666:8668/?version=current',
    PARTNER_TYPE: 'https://gagps-viewer.cfapps.eu10.hana.ondemand.com/index.html#/tree/793/actions/8666:8669/?version=current',
  },
  PRIVACY_LINK: '/privacy',
  FOOTER: {
    PARTNER_WITH_US: 'https://www.sap.com/partners/partner-program.html',
    ABOUT_PF: 'https://www.sap.com/partners/find.html',
    FAQ_PF: 'https://gagps-viewer-prospects.cfapps.eu10.hana.ondemand.com/#/tree/837/actions/9029',
    FAQ_PE: 'https://gagps-viewer.cfapps.eu10.hana.ondemand.com/index.html#/tree/782/actions/8500',
    PRIVACY: '/privacy',
    TERM_OF_USE: 'https://www.sap.com/corporate/en/legal/terms-of-use.html',
    PARTNER_TERM_OF_USE: '/partner-terms-of-use',
    LEGAL_DISCLOSURE: 'https://www.sap.com/about/legal/impressum.html',
    COPY_RIGHT: 'https://www.sap.com/about/legal/copyright.html',
    TRADE_MARK: 'https://www.sap.com/about/legal/trademark.html',
    BTP: 'https://www.sap.com/products/business-technology-platform.html',
    FAQ_PROFILE: 'https://gagps-viewer.cfapps.eu10.hana.ondemand.com/index.html#/tree/782/actions/8500:8501:8944/?version=current',
  },
  PARTNER_WITH_US: 'https://www.sap.com/partners/partner-program.html',
  HELP_TEXT_LEARN_MORE: {
    COMPETENCIES: 'https://dam.sap.com/mac/app/p/pdf/asset/preview/LPgUwkt?ltr=a&rc=10',
    CERTIFIED_CONSULTANT: 'https://gagps-viewer.cfapps.eu10.hana.ondemand.com/index.html#/tree/837/actions/9029:9032:9123/?version=current',
    SAP_SOLUTION: 'https://gagps-viewer-prospects.cfapps.eu10.hana.ondemand.com/index.html#/tree/837/actions/9029:9032:9124',
    INDUSTRY_FOCUS: 'https://gagps-viewer-prospects.cfapps.eu10.hana.ondemand.com/index.html#/tree/837/actions/9029:9032:9125',
    SERVICES: 'https://gagps-viewer-prospects.cfapps.eu10.hana.ondemand.com/index.html#/tree/837/actions/9029:9032:9126',
    SUPPORTED_LANGUAGES: 'https://gagps-viewer-prospects.cfapps.eu10.hana.ondemand.com/index.html#/tree/837/actions/9029:9032:9127',
    GROW_WITH_SAP: 'https://www.sap.com/products/erp/grow.html',
    RISE_WITH_SAP: 'https://www.sap.com/products/erp/rise.html#migration',
    RISE_WITH_SAP_VALIDATED_PARTNER: 'https://www.sap.com/products/erp/rise/sap-erp.html',
  },
  publicationGuideline: 'https://gagps-viewer.cfapps.eu10.hana.ondemand.com/index.html#/tree/782/actions/8500:9419',
  LEARN_MORE_COMPETENCY: 'https://dam.sap.com/mac/u/a/LPgUwkt.htm?rc=10',
  LEARN_MORE_SPECIALIZATION: 'https://dam.sap.com/mac/app/p/pdf/asset/preview/LPgUwkt?ltr=a&rc=10',
  LEARN_MORE_QPPS: 'https://www.sap.com/partners/find/qualified-packaged-solutions.html?sort=latest_desc',
  LEARN_MORE_VPUC: 'https://d.dam.sap.com/s/c/a/xxvvjPY/2025-01-22%20VPUC%20Customer%20Master%20Deck%20Jan%202025.pdf?inline=true&rc=10&doi=SAP1165810',
};
