<script>

import '@sapudex/web-components/dist/Modal.js';
import '@sapudex/web-components/dist/Button.js';
import '@ui5/webcomponents-fiori/dist/Bar.js';
import '@sapudex/web-components/dist/Divider.js';

export default {
  name: 'PFAlternativeNamesComponent',
  props: {
    brandNames: { type: Array, default: () => [] },
    title: { type: String, default: '' },
    toggleAlternativeNamesModal: { type: Function, default: () => {} },
  },
  data: () => ({
    editedBrandNames: [],
    favouriteName: '',
  }),
  computed: {
    query: {
      get() {
        return this.$store.state.autocomplete.query;
      },
    },
  },
  mounted() {
    this.editedBrandNames = this.sortedBrandNames();
    this.matchKeywordBrandName();
  },
  methods: {
    sortedBrandNames() {
      const tempBrandNames = this.brandNames.slice();
      tempBrandNames.sort((a, b) => a.brandName.localeCompare(b.brandName));
      for (let i = 0; i < tempBrandNames.length; i++) {
        if (tempBrandNames[i].isFavoriteName) {
          this.favouriteName = tempBrandNames[i].brandName;
          const buffer = tempBrandNames[i];
          tempBrandNames.splice(i, 1);
          tempBrandNames.unshift(buffer);
          break;
        }
      }
      return tempBrandNames;
    },
    matchKeywordBrandName() {
      const searchValueRegExp = new RegExp(
        this.query.replace(/[*%.\\[\](){}+?\-^$|]/g, ''),
        'ig',
      );
      if (String(searchValueRegExp) === '/(?:)/gi') {
        return;
      }
      const findName = this.editedBrandNames.find((brandNameObj) => brandNameObj.brandName.match(searchValueRegExp));
      if (findName) {
        this.favouriteName = findName.brandName;
      }
    },
  },
};
</script>

<template>
  <div class="alt-names">
    <div class="partner-name--brand-names">
      <a
        class="partner-name--brand-names"
        href=""
        @click.prevent="toggleAlternativeNamesModal()"
      >
        <div class="partner-name--brand-names-text">
          <span v-if="!favouriteName" class="alt-name-by-default">{{ brandNames.length }}
          {{ $tc('title.brand.names.more.names', brandNames.length) }}</span>
          <span v-else class="alt-name-by-favourite-brand">{{ favouriteName }}
            <span v-if="brandNames.length > 1" class="alt-name-by-favourite-and-more">
            {{ $t('alt.name.title.and')}}{{brandNames.length -1}}{{ $t('title.brand.names.more')}}</span></span>
        </div>
      </a>
    </div>
  </div>
</template>

<style lang="less" scoped>
.alt-names{
  // min-width: 150px;
  // width: 100%;

.partner-name--brand-names {
  position: static;
}
.partner-name--brand-names :hover {
  color: @color-gray-1;
}
.partner-name--brand-names-text {
  &:extend(.font-normal);
  font-size: 16px;
  color: @color-gray-2;
}
.alt-names-modal {
  display: none;
}
.ds-modal--active {
  z-index: 200;
}
.modal-container-inner-upload {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 250px);
}
.alt-names-modal-box {
  max-width: 669px;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  max-height: 572px;
  min-height: 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
}
.ds-modal__box__header__heading {
  padding-right: 40px !important;
  &:extend(.font-expanded);
  font-size: 27px;
  line-height: 35px;
}
  .content-wrapper {
    max-width: 544px;
    }

.alt-name-main-first {
  // &:extend(.font-normal);
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
}
.alt-name-main-content-name {
  color: @udexColorGrey9;
  font-size: 16px;
  line-height: 24px;
  margin-top: 12px;
}
.alt-name-main-content-description {
  // &:extend(.font-normal);
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 12px;
}
.modal-icon {
  line-height: 200%;
  color: @color-gray-2;
}
.modal-icon:hover {
  color: @color-gray-1;
  cursor: pointer;
}
.alt-name-by-favourite-and-more{
  font-size: 12px;
}
}
</style>
