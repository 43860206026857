<script>
import { mapState } from 'vuex';
import responseHandlerUtil from '@/plugins/search/responseHandler';

const LARGESCREEN = 700;
const MIDDLESCREEN = 525;
const SMALLSCREEN = 332;

export default {
  name: 'AutocompleteComponent',
  props: {
    searchSugestionItemHeight: {
      type: Number,
      default: 32,
    },
    recommendedEnabled: Boolean,
    host: {
      type: String,
      default: '',
    },
    isHomePage: { type: Boolean, default: false },
  },

  data: () => ({
    baseTerm: null,
    activeItem: null,
    textWidth: 820,
    maxChar: 90,
    titleItems: [],
    suggestionItems: [],
  }),

  computed: {
    ...mapState('autocomplete', {
      searchResults: (state) => state.searchResults,
      showSearchSuggestion: (state) => state.showSearchSuggestion,
    }),
    ...mapState('profileSearch', {
      pathChangedFromUiFlag: (state) => state.pathChangedFromUiFlag,
      queryTermTypeState: (state) => state.queryTermTypeState,
    }),
    query: {
      get() {
        return this.$store.state.autocomplete.query;
      },
      set(value) {
        this.$store.dispatch('autocomplete/setQueryValue', value);
      },
    },
    profileSearchQuery: {
      get() {
        return this.$store.state.profileSearch.query;
      },
    },
  },
  watch: {
    searchResults() {
      this.classifyItems();
    },
  },
  methods: {
    classifyItems() {
      // this.titleItems = this.searchResults.filter((result) => result.title === 'title');
      // this.suggestionItems = this.searchResults.filter((result) => result.title !== 'title');
      this.suggestionItems = this.sortMatch(this.searchResults);
    },
    sortMatch(searchResults) {
      const queryPattern = `^${this.query}$`;
      const searchValueRegExp = new RegExp(
        queryPattern,
        'i',
      );
      const filterOutMatch = searchResults.filter((item) => !searchValueRegExp.test(item.value));
      const filterMatch = searchResults.filter((item) => searchValueRegExp.test(item.value));
      if (filterOutMatch.length === searchResults.length) {
        return searchResults;
      }
      for (let i = filterMatch.length - 1; i >= 0; i--) {
        filterOutMatch.unshift({ value: filterMatch[i].value });
      }
      return filterOutMatch;
    },
    closeMenu() {
      this.$store.dispatch('autocomplete/clearResults');
    },
    selectedItemClick(item) {
      if (item && item.value) {
        this.clearFilter();
        const partnerNavigatorPage = 'partnerNavigatorPage';
        this.baseTerm = item.value;
        this.$store.commit('profileSearch/changeTriggeredQuery', this.baseTerm);
        if (item.title === 'title') {
          this.$store.commit('profileSearch/setSearchPartner', true);
        }
        this.clearFilter();
        this.$store.dispatch('autocomplete/setQueryValue', this.baseTerm);
        this.$store.dispatch('autocomplete/setOpenSearchDropdown', false);
        this.$store.dispatch('autocomplete/getAutocompleteResults');
        this.$store.commit('autocomplete/setSearchSuggestion', true);
        if (this.$router.currentRoute.value.name === partnerNavigatorPage) {
          this.$store.dispatch('profileSearch/setQuery', this.baseTerm);
        } else {
          this.$store.commit('profileSearch/setSearchFromHomePage', true);
          this.$store.dispatch('profileSearch/setQueryWithoutSearch', this.baseTerm);
        }
        this.$emit('submit-form-handler');
        if (!(this.$router.currentRoute.value.name === partnerNavigatorPage)) {
          const query = { q: this.baseTerm, queryType: this.queryTermTypeState };
          this.$router.push({
            name: partnerNavigatorPage,
            query,
          });
        }
      }
    },
    getCurrentAdobeUrl() {
      if (this.$router.currentRoute.value.name === 'home') {
        return window.location.search;
      }
      return null;
    },
    checkGuidedSearch() {
      if (this.$router.currentRoute.value.name === 'home') {
        return true;
      }
      return false;
    },
    selectedItemClickProductTitle(item) {
      if (item && item.term) {
        this.baseTerm = item.term;
        this.clearFilter();
        this.$store.commit('profileSearch/changeTriggeredQuery', '');
        this.$store.dispatch('autocomplete/setQueryValue', '');
        this.$store.dispatch('profileSearch/setQueryWithoutSearch', '');
        this.$store.dispatch('profileSearch/addSelectedOption', { optionType: item.title, value: { title: this.baseTerm }, searchType: 'autoComplete' });
        const partnerNavigatorPage = 'partnerNavigatorPage';
        if (item.title === 'title') {
          this.$store.commit('profileSearch/setSearchPartner', true);
        }
        this.$emit('submit-form-handler');
        this.$store.commit('autocomplete/setShowSearchSuggestion', false);
        this.$store.commit('profileSearch/setPathChangedFromUiFlag', true);
        if (!(this.$router.currentRoute.value.name === partnerNavigatorPage)) {
          const query = { q: this.baseTerm };
          this.$router.push({
            name: partnerNavigatorPage,
            query,
          });
        }
      }
    },
    clearFilter() {
      this.$store.commit('profileSearch/cleanFilters');
      this.$store.commit('profileSearch/cleanResults');
      this.$store.commit('profileSearch/setQuery', null);
      this.$store.commit('profileSearch/setPageNumber', 1);
    },
    highlightSearchTerm(item) {
      let suggestionvalue = item.value || '';
      if (item && item.value && this.query && this.query.trim().length > 0) {
        const query = this.query.replace(/[*%.\\[\](){}+?\-^$|]/g, '').replace(/, /g, ',').trim();
        let queryArray = query.split(' ');
        queryArray = queryArray.filter((q) => q.length > 0);
        queryArray.sort((a, b) => b.length - a.length);
        const extendedCharsAsciiPattern = /[\u0300-\u036f]/g;
        const normalizedSuggestion = item.value?.normalize('NFD').toLowerCase();
        const matchArr = [];
        if (extendedCharsAsciiPattern.test(normalizedSuggestion)) {
          queryArray.forEach((element) => {
            if (normalizedSuggestion.replace(extendedCharsAsciiPattern, '').includes(element.toLowerCase())) {
              const matched = normalizedSuggestion.replace(extendedCharsAsciiPattern, '').match(element.toLowerCase());
              const matchedQuery = item.value.substring(matched.index, matched.index + element.length);
              const searchValueRegExp = new RegExp(
                matchedQuery,
                'ig',
              );
              suggestionvalue.replaceAll(searchValueRegExp, (str, offset) => {
                matchArr.push(`S${offset}`);
                matchArr.push(`E${offset + str.length - 1}`);
              });
            }
          });
        } else {
          queryArray.forEach((element) => {
            const searchValueRegExp = new RegExp(
              element,
              'ig',
            );
            suggestionvalue.replaceAll(searchValueRegExp, (str, offset) => {
              matchArr.push(`S${offset}`);
              matchArr.push(`E${offset + str.length - 1}`);
            });
          });
        }
        matchArr.sort((a, b) => {
          const aIdx = parseInt(a.substring(1), 10);
          const bIdx = parseInt(b.substring(1), 10);
          const aPos = a.charAt(0);
          const bPos = b.charAt(0);
          if (aIdx > bIdx) {
            return 1;
          }
          if (aIdx === bIdx && bPos === 'S' && aPos === 'E') {
            return 1;
          }
          return -1;
        });
        const highlightPos = [];
        matchArr.forEach((pos) => {
          if (pos.charAt(0) === 'S' && (highlightPos.length === 0 || highlightPos[highlightPos.length - 1].charAt(0) === 'E')) {
            highlightPos.push(pos);
          }
          if (pos.charAt(0) === 'E') {
            while (highlightPos[highlightPos.length - 1].charAt(0) === 'E') {
              highlightPos.pop();
            }
            highlightPos.push(pos);
          }
        });
        for (let i = highlightPos.length - 1; i >= 0; i--) {
          const idxPos = parseInt(highlightPos[i].substring(1), 10);
          const posChar = highlightPos[i].charAt(0);
          if (posChar === 'E') {
            suggestionvalue = `${suggestionvalue.substring(0, idxPos + 1)}</b>${suggestionvalue.substring(idxPos + 1)}`;
          } else if (posChar === 'S') {
            suggestionvalue = `${suggestionvalue.substring(0, idxPos)}<b>${suggestionvalue.substring(idxPos)}`;
          }
        }
      }
      return this.addSpaceAfterComma(suggestionvalue);
    },
    onResize() {
      this.textWidth = this.$refs.textContent.clientWidth;
      if (this.textWidth > LARGESCREEN) {
        this.maxChar = Math.round(this.textWidth / 11);
      } else if (this.textWidth > MIDDLESCREEN && this.textWidth <= LARGESCREEN) {
        this.maxChar = Math.round(this.textWidth / 13);
      } else if (this.textWidth <= MIDDLESCREEN && this.textWidth > SMALLSCREEN) {
        this.maxChar = Math.round(this.textWidth / 15);
      } else { this.maxChar = Math.round(this.textWidth / 18); }
    },
    getTruncatedText(highlightBrandNames, termLength) {
      const maxChar = this.maxChar - termLength - 12;
      let truncatedText = '';
      while (highlightBrandNames.length) {
        const currHighlightName = highlightBrandNames.shift();
        truncatedText += currHighlightName;
        if (highlightBrandNames.length) { truncatedText += ', '; }
        if (truncatedText.length >= maxChar - 2) { break; }
      }
      if (truncatedText.length > maxChar) { return `${truncatedText.substring(0, maxChar - 3)}...`; }
      return truncatedText;
    },
    getBrandNames(item) {
      const brandNames = item.brandNames.slice();
      let restNamesCount = 0;
      if (brandNames.length > 0) {
        restNamesCount = item.brandAmount - brandNames.length;
      } else {
        restNamesCount = item.brandAmount - 1;
      }
      let finalBrandNames = '';
      if (!brandNames.length && !item.favoriteBrandName) { return ''; }
      const searchValueRegExp = new RegExp(
        this.query.replace(/[*%.\\[\](){}+?\-^$|]/g, ''),
        'ig',
      );
      if (!brandNames.length) {
        finalBrandNames += item.favoriteBrandName;
      } else { finalBrandNames += this.getTruncatedText(brandNames, item.value.length); }
      finalBrandNames = finalBrandNames.replace(searchValueRegExp, (str) => `<b>${str}</b>`);
      restNamesCount += brandNames.length;
      if (restNamesCount) {
        finalBrandNames = `${finalBrandNames}${this.$t('autocomplete.brand.names.and')}${
          restNamesCount}${this.$t('title.brand.names.more')}`;
      }
      finalBrandNames = ` (${finalBrandNames})`;
      return finalBrandNames;
    },
    addSpaceAfterComma(text) {
      return responseHandlerUtil.addSpaceAfterComma(text);
    },
  },
};
</script>

<template>
  <div v-if="showSearchSuggestion && suggestionItems.length > 0"
    :class="isHomePage ? 'pf-auto-complete-homepage' : ''"
    class="pf-autocomplete-menu-wrapper" ref="textContent">
    <resize-observer @notify="this.onResize"></resize-observer>
    <div class="menu-container">
    <ul
      class="pf-autocomplete-menu-wrapper_pf-search-auto-suggest
      pf-search-auto-suggest_suggested"
    >
      <!-- <div v-if="suggestionItems.length > 0" class="search-suggestion-subtitle">
        {{ $tc('autocomplete.subtitle.search.suggestion', suggestionItems.length) }}</div> -->
      <template v-for="item in suggestionItems" :key="item.id">
        <li
          @touchend.prevent="selectedItemClick(item)"
          @mousedown.prevent="selectedItemClick(item);$eventBus.$emit('PFAACustomEvent',
             {eventName: 'ClickSearchSuggestionItem', custom2: item.value})"
          class="pf-autocomplete-menu-item"
          :class="{ hover: item.active,
              'pf-term-autocomplete-active-background' : profileSearchQuery === item.value && $parent.selected !== -1  }"
        >
          <img class="search-suggestion-icon ds-m-r-s" src="../../../assets/search.svg"/>
          <div class="search-suggestion-text" :style="{ 'line-height': searchSugestionItemHeight + 'px' }" v-html="highlightSearchTerm(item)"></div>
        </li>
      </template>
      <!-- <div v-if="titleItems.length > 0" class="search-suggestion-subtitle">
        {{ $tc('autocomplete.subtitle.sap.partner', titleItems.length) }}</div> -->
      <!-- <template v-for="(item, idx) in titleItems" :key="item.id">
        <li
          @touchend.prevent="selectedItemClickProductTitle(item)"
          @mousedown.prevent="$eventBus.$emit('PFAACustomEvent',
             {eventName: 'ClickSearchSuggestionPartner',
             custom2: item.term,
             custom3: idx,
             custom4: profileSearchQuery,
             custom5: getCurrentAdobeUrl(),
             custom6: checkGuidedSearch()});
             selectedItemClickProductTitle(item);"
          class="pf-autocomplete-menu-item"
          :class="{ hover: item.active,
              'pf-term-autocomplete-active-background' : profileSearchQuery === item.term && $parent.selected !== -1  }"
        >
          <span v-html="highlightSearchTerm(item)"></span>
          <span v-if="item.brandNames.length || item.favoriteBrandName" class="pf-autocomplete-more-names">
            <span v-html="getBrandNames(item)"></span>
          </span>
        </li>
      </template> -->
    </ul>
    </div>
    </div>
</template>

<style lang="less" scoped>
.pf-search-auto-suggest_suggested::-webkit-scrollbar {
  width: 12px !important;
  background-color: white !important;
}
.pf-search-auto-suggest_suggested::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 8px;
}
.pf-search-auto-suggest_suggested::-webkit-scrollbar-thumb {
  background-color: #8396A8 !important;
  border-radius: 8px;
}
.menu-container {
  padding-right: 6px;
  padding-top: 4px;
}
.pf-search-auto-suggest_suggested {
  max-height: 336px;
  overflow-y: auto;
  overflow-x: hidden;
}
.search-suggestion-text {
  display: inline-block;
  line-height: 28px;
}
.search-suggestion-icon::before {
  color: #475E75;
  font-size: 18px;
  margin-right: 8px;
  line-height: 18px;
  font-weight:800;
}
.menu-container {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #FFFFFF;
  border-left:1px solid #5B738B;
  border-right:1px solid #5B738B;
  border-bottom:1px solid #5B738B;
  padding-bottom: 4px;
  box-shadow: 0px 2px 3px 0px rgba(34,54,73,.3);
}
.pf-search-auto-suggest_suggested::-webkit-scrollbar {
    background-color: #c6c6c6;
    width: 9px;
}
.pf-search-auto-suggest_suggested::-webkit-scrollbar-thumb {
    background-color: #727272;
}
.search-suggestion-icon::before {
  color: @color-gray-2;
}
.pf-autocomplete-menu-item {
  display: flex;
  &:extend(.body-s-size);
  color: #475E75;
  cursor: pointer;
}

.pf-autocomplete-menu-item_no_result {
  &:extend(.body-s-size);
  color: #475E75;
  height: 24px;
  font-size: 16px;
  line-height: 1.5;
  color: @color-gray-3;
  padding: 20px 0 20px 12px;
  box-sizing: content-box;
}

.pf-term-autocomplete-active-background {
  background-color: #F5F6F7;
  cursor: pointer;
}

.ds-partner-logo_auto_complete_image {
  width: 16px;
  height: 16px;
}
.pf-autocomplete-more-names{
  color: @color-gray-2;
}
.search-suggestion-subtitle{
  &:extend(.font-expanded);
  padding: 8px 8px 8px;
  font-size: 16px;
  color: @color-gray-3;
  border-color: @color-gray-5 !important;
  border: 1px;
}
.pf-auto-complete-homepage {
  margin: 0 !important;
  max-width: 490px !important;
}
.pf-auto-complete-homepage .menu-container {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
</style>
