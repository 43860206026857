import axios from 'axios';

const ROOT_ENDPOINT = '/sap/details/api';

const FullDataStore = {
  namespaced: true,
  state: {
    allIndustries: [],
    allSupportedLanguages: [],
    allCountries: {},
    allowedCountries: [],
    allBusinessModels: [],
    allSolutions: [],
  },
  actions: {
    async fetchAllData(context) {
      context.commit('solutionL2Store/setBuildInitSolutionFlag', true, { root: true });
      await axios.get(`${ROOT_ENDPOINT}/data/full`)
        .then((result) => context.dispatch('dispatchResponseHandler', result));
      context.commit('solutionL2Store/setBuildInitSolutionFlag', false, { root: true });
      context.commit('solutionL2Store/setItemsWithSpecializationUnavailable', {}, { root: true });
    },
    dispatchResponseHandler(context, results) {
      context.commit('setResults', results.data);
    },
    async fetchAllAllowedCountryData(context) {
      await axios.get(`${ROOT_ENDPOINT}/data/countries/allowed`)
        .then((result) => context.dispatch('dispatchAllowedCountryResponseHandler', result));
    },
    dispatchAllowedCountryResponseHandler(context, results) {
      context.commit('setAllowedCountryResults', results.data);
    },
  },
  mutations: {
    setResults(context, results) {
      this.commit('solutionL2Store/buildInitSolutionMap', results, { root: true });
      this.commit('solutionL2Store/setIeCompetency', results.ieCompetency, { root: true });

      if (results.industries) {
        const newAllIndustries = [];
        Object.entries(results.industries).forEach(([industryId, title]) => {
          newAllIndustries.push({ industryId, title });
        });
        context.allIndustries = newAllIndustries;
      }

      if (results.engagement) {
        const newAllBusinessModels = [];
        Object.entries(results.engagement).forEach(([businessModelId, title]) => {
          newAllBusinessModels.push({ businessModelId, title });
        });
        context.allBusinessModels = newAllBusinessModels;
      }

      if (results.languages) {
        const newAllSupportedLanguages = [];
        Object.entries(results.languages).forEach(([language, text]) => {
          newAllSupportedLanguages.push({ language, text });
        });
        context.allSupportedLanguages = newAllSupportedLanguages;
      }

      if (results.allowedCountries) {
        context.allCountries = results.allowedCountries;
        const newAllowedCountries = [];
        Object.entries(results.allowedCountries).forEach(([country, text]) => {
          newAllowedCountries.push({ country, text });
        });
        context.allowedCountries = newAllowedCountries;
      }
    },
    setAllowedCountryResults(context, results) {
      if (results) {
        const newAllowedCountries = [];
        Object.entries(results).forEach(([country, text]) => {
          newAllowedCountries.push({ country, text });
        });
        context.allowedCountries = newAllowedCountries;
      }
    },
  },
};

export default FullDataStore;
