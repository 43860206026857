<script>
import '@sapudex/web-components/dist/Modal.js';
import '@sapudex/web-components/dist/Button.js';
import '@ui5/webcomponents-fiori/dist/Bar.js';
import '@sapudex/web-components/dist/ControlButton.js';

const CountryAmount = {
  minumum: 5,
  maximum: 25,
};

export default {
  name: 'PEPlatinumPopupComponent',
  props: {
    closePopup: {
      type: Function,
      default: () => {},
    },
    goldCountries: {
      type: Array,
      default: () => [],
    },
    platinumCountries: {
      type: Array,
      default: () => [],
    },
    showGoldLogo: {
      type: Boolean,
      default: false,
    },
    groupedAccount: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    companyName() {
      return this.$store.state.partnerProfile.profileData.name;
    },
    isPlatinumCountriesAvailable() {
      return this.platinumCountries?.length > 0;
    },
    platinumCountriesColumnClassOnCount() {
      return this.getColumnClassOnCountriesCount(this.platinumCountries);
    },
    goldCountriesColumnClassOnCount() {
      return this.getColumnClassOnCountriesCount(this.goldCountries);
    },
  },
  methods: {
    getColumnClassOnCountriesCount(countriesList) {
      if (countriesList?.length > CountryAmount.minumum && countriesList?.length <= CountryAmount.maximum) {
        return 'countries-list--column-2';
      }
      if (countriesList?.length > CountryAmount.maximum) {
        return 'countries-list--column-3';
      }
      return 'countries-list--column-1';
    },
    getCompanyName() {
      return { company: this.companyName };
    },
    goldCountryText() {
      return this.goldCountries.length === 1
        ? this.$t('sap.gold.partner.text.b.single', this.getCompanyName())
        : this.$t('sap.gold.partner.text.b', this.getCompanyName());
    },
    platinumModalDescriptionText() {
      return this.platinumCountries.length > 0
        ? this.$t('sap.platinum.partner.country.description.text', this.getCompanyName())
        : this.$t('sap.platinum.partner.description.text', this.getCompanyName());
    },
    platinumCountryText() {
      return this.platinumCountries.length === 1
        ? this.$t('sap.platinum.partner.countries.text.single', this.getCompanyName())
        : this.$t('sap.platinum.partner.countries.text', this.getCompanyName());
    },
  },
};
</script>

<template>
  <udex-modal automation-id="profileRecognitionModal" type="default" :open="true" @keydown.esc="closePopup">
    <ui5-bar class="header" slot="header" design="Header" data-sap-ui-fastnavgroup="true">
      <h3 automation-id="profileRecognitionModal-header" class="HeadingMediumXS-size" slot="startContent">
        {{ $t("sap.partner.edge") }}
      </h3>
      <udex-control-button
        automation-id="profileRecognitionModal-closeDialog"
        @click="closePopup"
        class="closeDialog"
        slot="endContent"
        icon="decline"
        has-icon
        icon-only
        accessible-name="close"
      ></udex-control-button>
    </ui5-bar>

    <div class="platinum-logo-modal" :class="showGoldLogo ? 'gold-country-max-height' : ''">
      <div class="dialog-text body-s-size">
        <div v-if="showGoldLogo">
          <div class="platinum-popup--container" automation-id="profileRecognitionModal-container">
            <img
              automation-id="profileRecognitionModal-logo"
              class="platinum-popup--logo"
              src="@/assets/SAP_GoldPartner_grad_R.png"
            />
          </div>
          <div automation-id="profileRecognitionModal-logoText" class="HeadingMediumXXXS-size platinum-logo--text">
            {{ $t("sap.gold.partner") }}
          </div>
          <div  automation-id="profileRecognitionModal-description">
            {{ $t("sap.gold.partner.text") }}
          </div>
          <div
            class="country-list"
            v-if="groupedAccount"
            automation-id="profileRecognitionModal-descriptionCountryList"
          >
            {{ this.goldCountryText() }}
          </div>
          <ul
            :class="goldCountriesColumnClassOnCount"
            class="gold-country-list countries-list"
            v-if="groupedAccount"
            automation-id="profileRecognitionModal-countryList"
          >
            <li
              v-for="country in goldCountries"
              :key="country"
            >
              <span>{{ country }}</span>
            </li>
          </ul>
          <!--Logic for "show more countries" feature. Retained for potential future use again.  -->
          <!-- <div
            automation-id="profileRecognitionModal-countriesLink"
            class="more-gold-countries"
            @click="showAllCountires = true"
            v-if="!(this.goldCountries.length <= 5 || this.showAllCountires)"
          >
            {{ goldCountries.length - 4 }}
            {{ $t("sap.gold.partner.more.countries") }}
          </div> -->
        </div>
        <div v-else>
          <div class="platinum-popup--container" automation-id="profileRecognitionModal-container">
            <img
              automation-id="profileRecognitionModal-logo"
              class="platinum-popup--logo"
              src="@/assets/SAP_PlatinumPartner_R.png"
            />
          </div>
          <div automation-id="profileRecognitionModal-logoText" class="HeadingMediumXXXS-size platinum-logo--text">
            {{ $t("sap.platinum.partner") }}
          </div>
          <p automation-id="profileRecognitionModal-description">
            {{ this.platinumModalDescriptionText() }}
          </p>
          <p v-if="isPlatinumCountriesAvailable" automation-id="profileRecognitionModal-countryDescription">
            {{ this.platinumCountryText() }}
          </p>
          <ul
            :class="platinumCountriesColumnClassOnCount"
            class="countries-list"
            automation-id="profileRecognitionModal-countryList"
          >
            <li v-for="country in platinumCountries" :key="country">
              <span class="platinum-text">{{ country }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <ui5-bar class="footer" slot="footer" design="Footer" data-sap-ui-fastnavgroup="true">
      <udex-button
        @click="closePopup"
        design="Emphasized"
        size="Large"
        slot="endContent"
        automation-id="profileRecognitionModal-closeButton"
      >
        {{ $t("button.close") }}
      </udex-button>
    </ui5-bar>
  </udex-modal>
</template>

<style lang="less" scoped>
.countries-list {
  list-style-type: none;
  column-gap: 20px;
  &--column-1 {
    column-count: 1;
  }
  &--column-2 {
    column-count: 2;
  }
  &--column-3 {
    column-count: 3;
  }
}
.countries-list li::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M0 5 L6 5" stroke="%23007DB8" stroke-width="1" fill="transparent"/></svg>');
  background-repeat: no-repeat;
}
.countries-list li {
  padding-left: 15px;
  text-indent: -15px;
}
.platinum-logo-modal {
  .dialog-text {
    max-width: 544px;
  }

  .platinum-popup--container {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    margin-bottom: 20px;
  }
  .platinum-popup--logo {
    width: 85.1px;
    height: 50px;
  }

  .country-list {
    margin-top: 20px;
  }
  .platinum-logo--text {
    text-align: center;
    margin-bottom: 20px;
  }
  .platinum-text {
    font-size: 16px;
    line-height: 24px;
    color: @color-grey-0;
  }
  .gold-country-list {
    padding-inline-start: 28px;
  }
  .gold-country-max-height {
    max-height: 417px;
  }
  .border-none {
    border: none !important;
  }
  .more-gold-countries {
    padding-left: 28px;
    cursor: pointer;
    color: @color-link-show-more;
  }
  .more-gold-countries:hover {
    color: @color-link__hover;
  }
}
.closeDialog {
  --udex-button-icon-l-size: 24px;
  --udex-button-color-tertiary-standard-default-text-and-icon: var(--udexColorGrey7, #475e75);
}

.footer udex-button {
  margin: 0 0 0 16px;
}

ui5-bar::part(bar) {
  /* Alternativelly header and footer paddings can be reduced to utilize 'ui5-bar' paddings.
  In this case overwrite following variables:
    --udexModalAndOverlayModalFooterPaddingHorizontal
    --udexModalAndOverlayModalHeaderPaddingHorizontal
  */
  margin-left: -12px;
  margin-right: -12px;
  width: auto;
}

h3 {
  margin: 0px;
}
@media (max-width: 600px) {
  .countries-list {
    column-count: 1;
  }
}
</style>
