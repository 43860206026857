<script>
/* eslint-disable vue/no-multiple-template-root */
import { mapState } from 'vuex';
import PFRiseWithSAPModalComponent from '@/components/common/PFRiseWithSAPModalComponent';

export default {
  name: 'PFRiseWithSAPComponent',
  data: () => ({
    showRiseWithSAPModal: false,
    showRiseLogo: false,
  }),
  components: {
    PFRiseWithSAPModalComponent,
  },
  props: {
    logoHeight: { type: Number, default: () => 46 },
  },
  methods: {
    openModal() {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
      this.showRiseWithSAPModal = true;
      this.emitAdobeEvent();
    },
    closeModal() {
      document.body.style.overflow = 'scroll';
      document.body.style.paddingRight = '0px';
      this.showRiseWithSAPModal = false;
    },
    emitAdobeEvent() {
      if (!this.editMode) {
        this.$eventBus.$emit('PFAACustomEvent',
          { eventName: 'ClickModal', custom2: 'At_A_Glance_RISE_with_SAP' });
      }
    },
  },
  computed: {
    ...mapState('partnerProfile', {
      isRiseValidated: (state) => state.profileData.isRiseValidated,
    }),
  },
};
</script>

<template>
  <div automation-id="partnerProfilePage-riseValidatedContainer" class="rise-SAP-validated-container" @click="openModal()">
    <img class="rise-SAP-validated-logo" :style="{ height: `${logoHeight}px`}" src="@/assets/Rise_with_SAP_ValidatedPartner_logo.svg"/>
  </div>
  <PFRiseWithSAPModalComponent
    v-if="showRiseWithSAPModal"
    :isRiseValidated="isRiseValidated"
    :closePopup="() => { closeModal();}"
    />
</template>

<style lang="less" scoped>

.rise-SAP-validated-container {
  display: block !important;
  cursor: pointer;
  .rise-SAP-validated-logo {
    height: 46px;
    position: relative;
    transition: all 0.2s ease;
  }
  .rise-SAP-validated-logo:hover {
    transform: scale(1.065);
  }

}
.rise-SAP-container {
  margin-top: 4px;
  display: flex;
  align-items: center;
}
.rise-SAP-icon::before {
    font-size: 14px;
    color: #007DB8;
}

.competency-name {
  &:extend(.font-normal);
  font-weight: 400 !important;
  color: @color-grey-0 !important;
  font-family: "BentonSansMedium";
}
.rise-SAP-text {
  font-size: 16px;
  line-height: 24px;
  margin-right: 4px;
  &:extend(.font-bold);
}

.rise-SAP-text:hover {
  color: #007DB8 !important;
  cursor: pointer;
}

</style>
