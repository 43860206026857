<template>
  <span class="custom-bullet-point" automation-id="BlueBulletPoint"></span>
</template>

<style scoped>
.custom-bullet-point {
  display: inline-block;
  width: 10px;
  height: 10px;
  max-height: 10px;
  margin-right: 5px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M0 5 L6 5" stroke="%23007DB8" stroke-width="1" fill="transparent"/></svg>');
  background-repeat: no-repeat;
}
</style>