<script setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import urlLinks from '@/plugins/linkUrl';
import '@sapudex/web-components/dist/Switch.js';

/**
 * Component to toggle competency with additional tooltip information.
 * Allows enabling or disabling the competency option based on the active state.
 *
 * @component
 * @props {String} title - Title for the toggle button.
 * @props {Boolean} modelValue - Boolean value to indicate if the toggle should be checked.
 */
const { title, titleFontSize } = defineProps({
  title: String,
  titleFontSize: {
    type: String,
    validator: (value) => ['small', 'medium'].includes(value),
  },
});

const store = useStore();
const { t } = useI18n();

const isBadgeConsistentWithUnavailableItems = computed(
  () => store.getters['solutionL2Store/isBadgeConsistentWithUnavailableItems'],
);
const isChecked = computed(() => (store.state.profileSearch.selectedOptions.isCompetency.length > 0 ? true : null));
const isComptencyEnabled = computed(() => (store.state.profileSearch.filterOptions.isCompetency.active.length > 0 ? true : null));
const tooltipText = computed(() => (!isComptencyEnabled.value ? t('competency.cloud.not.available') : t('competency.cloud.available')));
const tooltipOpen = ref(false);
const faqLinkSolution = ref(urlLinks.HELP_TEXT_LEARN_MORE.SAP_SOLUTION);
const titleStyles = computed(() => (titleFontSize === 'small' ? 'font-size: 14px' : 'font-size: 16px'));

const toggleCompetencyButton = () => {
  if (!isComptencyEnabled.value) return;

  const currentIsChecked = !isChecked.value;

  const option = { optionType: 'isCompetency', title: 'true' };
  if (currentIsChecked) {
    store.dispatch('profileSearch/addSelectedOption', { optionType: 'isCompetency', value: option });
  } else {
    store.dispatch('profileSearch/uncheckOption', option);
  }
};
const toggleTooltip = () => {
  tooltipOpen.value = false;
};
const openLearnMore = () => {
  const win = window.open(faqLinkSolution.value, '_blank');
  win.focus();
};

watch(isBadgeConsistentWithUnavailableItems, (newValue) => {
  if (newValue === true && isComptencyEnabled.value) {
    setTimeout(() => {
      store.dispatch('profileSearch/uncheckOption', { optionType: 'isCompetency', title: 'true' });
    }, 0);
  }
});
</script>

<template>
  <div class="toggle-container">
    <!-- Switch label -->
    <div
      class="ds-toggle-button__text"
      @click="toggleCompetencyButton"
      :class="{ 'toggle-button-disabled__text': !isComptencyEnabled }"
      :style="titleStyles"
    >
      {{ title }}
    </div>
    <!-- Switch button -->
    <div class="switch-container">
      <udex-switch
        :accessibleName="$t('search.filter.cloud.competency.accessibleName')"
        desktop
      :checked.prop="isChecked"
        @change="toggleCompetencyButton"
        :disabled.prop="!isComptencyEnabled"
      ></udex-switch>
    </div>
    <!-- Tooltip -->
    <div class="ds-tooltip-icon tool-tip-container">
      <i
        class="ds-icon ds-icon--info"
        @click="tooltipOpen = !tooltipOpen"
        v-click-outside="toggleTooltip"
        aria-label="Toggle tooltip"
        :class="{ 'tool-tip-icon-clicked': tooltipOpen }"
      ></i>
      <div class="ds-tooltip-icon toolitp-icon-item-list">
        <div
          class="tooltiptext ds-tooltip-icon__tooltip--service"
          :class="tooltipOpen ? 'tooltiptext--open' : 'display-none'"
        >
          {{ tooltipText }}
          <span @click="openLearnMore" class="help-text-link">
            <b> {{ $t("learn.more") }}</b>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.ds-tooltip-icon__tooltip--service {
  cursor: default;
  font-weight: 390 !important;
  width: 170px !important;
  max-width: 170px !important;
  right: 18px;
  top: 43px !important;
}
&::after {
  rotate: 90deg;
  top: -10px !important;
  right: 10.5% !important;
}
.help-text-link,
.help-text-link b {
  color: @white-color;
}

.tooltiptext--open {
  display: flex;
  flex-direction: column;
}

.ds-tooltip-icon {
  display: inline-block;
  position: relative !important ;
  height: 18px !important;
  width: 18px !important;
  color: @color-gray-4;
  margin: 0 0 0 8px !important;
  cursor: pointer;
}
&:hover {
  color: @color-gray-2;
}

.tool-tip-icon-clicked {
  color: @color-gray-2 !important ;
}

.ds-icon--info {
  margin-top: 1px;
  height: 18px !important;
  width: 18px !important;
}

.ds-icon--info::before {
  width: 18px !important;
  height: 18px !important;
}

.ds-tooltip-icon .toolitp-icon-item-list {
  position: absolute !important;
  bottom: 11px;
}

.toggle-container {
  display: flex;
  align-self: center;
  align-items: center;
}
.ds-toggle-button__text {
  font-family: "72 Brand Variable";
  color: @color-blue-gray-dark !important;
  cursor: pointer;
}

.toggle-button-disabled__text {
  color: @color-gray-3 !important;
  cursor: default;
}
.toggle-wrapper {
  display: flex;
  align-self: center;
  align-items: center;
  padding: 14px 0;
  margin-left: 32px;
}
.switch-container {
  display: flex;
  align-content: center;
  margin: 0 4px 0 12px;
}
</style>
