<script>
/* This component serves as a custom header for the udex-modal component.
The primary reason for creating this custom header is to allow for longer
 header text that can span multiple lines, which is not supported by the
 default ui5-bar component.
 */
import "@sapudex/web-components/dist/ControlButton.js";

export default {
  name: "PFBaseModalHeader",
  props: {
    modalHeaderText: { type: String, default: "" },
    closeModal: { type: Function, default: () => {} },
  },
};
</script>

<template>
  <div class="header" slot="header" design="Header">
    <h3>{{ modalHeaderText }}</h3>
    <udex-control-button
      @click="closeModal(false)"
      automation-id="profileRecognitionModal-closeDialog"
      icon="decline"
      slot="endContent"
      icon-only
      has-icon
      accessible-name="close"
    ></udex-control-button>
  </div>
</template>

<style lang="less" scoped>
#modal .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  gap: 8px;
}
#modal h3 {
  overflow: hidden;
  font-family: var(--sapFontFamily);
  line-height: var(--udexTypographyHeadingLineHeight);
  font-size: var(--udexTypographyHeadingMediumXSFontSize);
  font-weight: var(--udexTypographyFontWeightMedium);
  margin: 0;
  color: var(--udexColorNeutralBlack);
}
</style>
