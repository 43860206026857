<script setup>
import PFBaseModalHeader from "@/components/common/modal/PFBaseModalHeader.vue";
import "@sapudex/web-components/dist/Modal.js";
import "@sapudex/web-components/dist/Button.js";
import "@ui5/webcomponents-fiori/dist/Bar.js";
import { computed, ref } from "vue";
import { useScreenSize } from "@/composables/useScreenSize";

const { isScreenSizeLessSM } = useScreenSize();

/*The messageBoxStyleOnMobile prop is added to avoid displaying a full-screen modal on mobile devices in situations where the contents of the modal are not complex.
  The modalFullHeight prop expands the modal to full viewport height, preventing modal shifts when toggling content like accordions.
  Refer to best practices for modals: https://www.sap.com/design-system/digital/patterns/modal-overlay/
*/
const props = defineProps({
  modalHeaderText: { type: String, default: "" },
  automationId: { type: String, default: "" },
  toggleModal: { type: Function, default: () => {} },
  messageBoxStyleOnMobile: { type: Boolean, default: false },
  modalFullHeight: { type: Boolean, default: false },
});
const messageBoxStyle = computed(() => {
  return isScreenSizeLessSM.value && props.messageBoxStyleOnMobile ? "message-box" : null;
});
// Double negation ensures the result is a boolean for type safety. 
const fullScreen = computed(() => {
  return isScreenSizeLessSM.value ? !props.messageBoxStyleOnMobile : !!props.modalFullHeight;
});
</script>

<template>
  <udex-modal
    id="modal"
    :automation-id="automationId"
    type="default"
    tabindex="0"
    :open="true"
    @beforeClose="toggleModal(false)"
    :stretch.prop="fullScreen"
    accessible-role="Dialog"
    :class="messageBoxStyle"
  >
    <PFBaseModalHeader :modalHeaderText :closeModal="props.toggleModal" />

    <slot class="modal-content-container"></slot>

    <ui5-bar class="footer" slot="footer" design="Footer" data-sap-ui-fastnavgroup="true">
      <udex-button
        automation-id="profileRecognitionModal-closeButton"
        @click="toggleModal(false)"
        design="Emphasized"
        size="Large"
        slot="endContent"
      >
        {{ $t("button.close") }}
      </udex-button>
    </ui5-bar>
  </udex-modal>
</template>

<style lang="less" scoped>
udex-modal {
  display: flex;
  justify-self: center;
  max-width: 608px;
  --udexModalAndOverlayModalContentPaddingHorizontal: 32px;
}
@media (max-width: 639px) {
  .message-box {
    max-width: 90vw;
  }
  udex-modal {
    --udexModalAndOverlayModalContentPaddingHorizontal: 24px;
    max-width: 639px;
  }
}
</style>
