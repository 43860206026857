<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import '@sapudex/web-components/dist/Search.js';
import '@sapudex/web-components/dist/List.js';
import { badgeCounter } from '@/utils/filterUtils';

export default {
  name: 'PFAccordionFilterMobileComponent',
  props: {
    title: {
      type: String,
    },
    filterType: {
      type: String,
    },
    searchBar: {
      type: Boolean,
    },
  },

  data: () => ({
    lastEvent: null,
    selectedItemObject: { type: Object, default: () => {} },
    searchValue: '',
  }),

  computed: {
    ...mapState('profileSearch', {
      selectedOptions: (state) => state.selectedOptions,
      filterOptions: (state) => state.filterOptions,
      loading: (state) => state.loading,
    }),
    ...mapState('filtersDrawer', {
      expandedItem: (state) => state.expandedAccordionItem,
    }),
    activeOptions() {
      return this.filterOptions[this.filterType].active;
    },
    disabledOptions() {
      return this.filterOptions[this.filterType].disabled;
    },
    filteredActiveOptions() {
      if (!this.searchValue) {
        return this.activeOptions;
      }
      return this.activeOptions.filter((option) => option.title.toLowerCase().includes(this.searchValue.toLowerCase()));
    },
    filteredDisabledOptions() {
      if (!this.searchValue) {
        return this.disabledOptions;
      }
      return this.disabledOptions.filter((option) => option.title.toLowerCase().includes(this.searchValue.toLowerCase()));
    },
    noSearchResults() {
      return this.filteredActiveOptions.length === 0 && this.filteredDisabledOptions.length === 0;
    },
    badgeCount() {
      return badgeCounter(this.selectedOptions[this.filterType]);
    },
  },
  methods: {
    ...mapActions('filtersDrawer', {
      setExpandedAccordionItem: 'setExpandedAccordionItem',
    }),
    ...mapMutations('solutionL2Store', {
      sortSolution: 'sortSolution',
    }),

    onSearchReset() {
      this.searchValue = '';
    },
    onSearch(text) {
      if (!this.searchValue) {
        return text;
      }
      const regex = new RegExp(`(${this.searchValue})`, 'gi');
      return text.replace(regex, '<strong>$1</strong>');
    },

    handleSelectionChange(event) {
      this.lastEvent = event;
      const selectedItem = [event.detail.item];
      const isSelected = selectedItem[0]._state.selected;
      const description = selectedItem[0]._state.supportingText;
      const designationDescr = selectedItem[0]._state.label;
      const title = selectedItem[0].accessKey;
      this.selectedItemObject = {
        isSelected,
        title,
        description,
        designationDescr,
      };
      this.selectOption(this.selectedItemObject);
      this.sortSolution();
    },

    selectOption(_option) {
      this.$store.dispatch('profileSearch/setPathChangedFromUiFlag', true);
      const option = { ..._option };

      if (!option.isSelected) {
        option.optionType = this.filterType;
        this.$store.dispatch('profileSearch/uncheckOption', option);
      } else {
        this.$store.dispatch('profileSearch/addSelectedOption', { optionType: this.filterType, value: option });
      }
      this.$store.commit('profileSearch/toggleOptionSelectedStatus', option);
    },
    inputSearchValue(event) {
      this.searchValue = event.detail.value;
    },
  },
};
</script>

<template>
  <udex-accordion-item
    automation-id="filterDrawer-accordionItem"
    :title="title"
    :expanded.prop="expandedItem === title"
    :numberBadge.prop="badgeCount"
    @click="setExpandedAccordionItem(expandedItem === title ? null : title)"
  >
    <udex-search
      v-if="searchBar"
      class="checkbox-search"
      label="Search"
      @handle-value-search="inputSearchValue"
      @handle-value-reset="onSearchReset"
      @click.stop
      :showSubmitButton.prop="true"
      :value.prop="searchValue"
      automation-id="filterDrawer-search"
    ></udex-search>
    <div class="udex-list-wrapper">
      <udex-list
        @selection-change="handleSelectionChange"
        id="udex-list"
        mode="MultiSelect"
        type="None"
        item-icon="accept"
        class="items-list"
        @click.stop
        automation-id="filterDrawer-list"
      >
        <template class="udex-list-items-container">
          <udex-list-item
            v-for="activeOption in filteredActiveOptions"
            :selected.prop="Boolean(activeOption.isSelected)"
            :key="activeOption.title"
            :accessKey="activeOption.title"
            :supporting-text="activeOption.description"
            @click.stop
            mode="MultiSelect"
            automation-id="filterDrawer-activeListItem"
          >
            <span
              v-html="onSearch(activeOption.designationDescr || activeOption.title)"
              automation-id="filterDrawer-activeListItemText"
            ></span>
          </udex-list-item>
          <udex-list-item
            disabled
            v-for="disabledOption in filteredDisabledOptions"
            :key="disabledOption.title"
            :accessKey="disabledOption.title"
            :supporting-text="disabledOption.description"
            mode="MultiSelect"
            automation-id="filterDrawer-disabledListItem"
          >
            <span
              v-html="onSearch(disabledOption.designationDescr || disabledOption.title)"
              automation-id="filterDrawer-disabledListItemText"
            ></span>
          </udex-list-item>
        </template>
      </udex-list>
    </div>
    <p class="no-searsh-results" v-if="noSearchResults" automation-id="filterDrawer-noResultsText">
      {{ this.$t("filter.drawer.search.no.results") }}
    </p>
  </udex-accordion-item>
</template>

<style lang="less" scoped>
.udex-list-wrapper {
  margin-top: 8px;
}
.udex-list-items-container {
  display: flex;
  flex-direction: column;
  max-height: 258px;
}
</style>
