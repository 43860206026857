<script>
import { mapState } from 'vuex';
import AAComponent from '@/app/AAComponent';

const SUPPORT_BROWSER_VERSION = [
  { name: 'chrome', version: 116 },
  { name: 'edge', version: 120 },
  { name: 'firefox', version: 49 },
  { name: 'opera', version: 106 },
  { name: 'safari', version: 16.4 },
];
const IE_BROWSER_PAGE = 'browserNotSupportedPage';

export default {
  name: 'PartnerFinderApp',
  data: () => ({
    queryData: '',
  }),
  components: {
    AAComponent,
  },
  computed: {
    ...mapState('partnerProfile', {
      pfProfileData: (state) => state.profileData,
    }),
  },
  mounted() {
    this.getScrollBarWidth();
  },
  beforeCreate() {
    const ua = navigator.userAgent;
    let tem;
    console.log(ua);
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    let browserObj = {};

    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      browserObj = { name: 'IE', version: (tem[1] || '') };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) browserObj = { name: tem[1].replace('OPR', 'Opera'), version: tem[2] };
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    browserObj = { name: M[0], version: M[1] };
    console.log(browserObj);
    const lowestBrowserVersion = SUPPORT_BROWSER_VERSION.filter((item) => item.name.includes(browserObj.name?.toLowerCase()));
    if (lowestBrowserVersion[0] && browserObj.version && browserObj.version < lowestBrowserVersion[0].version) {
      this.$router.replace({
        name: IE_BROWSER_PAGE,
      }).catch(() => {});
    } else {
      this.$store.dispatch('fullData/fetchAllData');
    }
  },
  created() {
    if (this.$store) {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'profileSearch/setResults') {
          this.$store.commit('profileSearch/setPrevQuery');
        }
      });
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        switch (to.name) {
          case 'home':
            document.title = `${this.$t('title.home')}`;
            break;
          case 'partnerNavigatorPage':
            document.title = `${this.$t('title.partnerNavigatorPage')}`;
            break;
          case 'profilePage':
            this.$store.dispatch('autocomplete/setQueryValue', '');
            break;
          default:
            document.title = `${this.$t('title.home')}`;
        }
      },
      deep: true,
    },
    pfProfileData(val) {
      if (val.name && this.$route.name === 'profilePage') {
        document.title = `${this.$t('title.profilePage', { partnerName: val.name })}`;
      } else if (val.name && this.$route.name === 'profileEditorPagePreview') {
        document.title = `${this.$t('title.profilePreviewPage', { partnerName: val.name })}`;
      } else if (val.name && this.$route.name === 'profileEditorPageEdit') {
        document.title = `${this.$t('title.profileEditorPage', { partnerName: val.name })}`;
      }
    },
  },
  methods: {
    updateSearchQuery(data) {
      this.queryData = data;
    },
    getScrollBarWidth() {
      const inner = document.createElement('p');
      inner.style.width = '100%';
      inner.style.height = '200px';

      const outer = document.createElement('div');
      outer.style.position = 'absolute';
      outer.style.top = '0px';
      outer.style.left = '0px';
      outer.style.visibility = 'hidden';
      outer.style.width = '200px';
      outer.style.height = '150px';
      outer.style.overflow = 'hidden';
      outer.appendChild(inner);

      document.body.appendChild(outer);
      const w1 = inner.offsetWidth;
      outer.style.overflow = 'scroll';
      let w2 = inner.offsetWidth;
      if (w1 === w2) w2 = outer.clientWidth;

      document.body.removeChild(outer);
      this.$store.commit('partnerProfile/setScrollBarWidth', w1 - w2);
    },
  },
};
</script>

<template>
  <div id="app" class="ds-flexGrid ds-p-none">
    <AAComponent />
    <header class="ds-flexRow ds-m-none">
      <router-view name="siteheader"></router-view>
    </header>
    <main class="ds-flexRow ds-m-none">
      <router-view name="contentarea"></router-view>
    </main>
    <footer class="ds-flexRow ds-m-none">
      <router-view name="sitefooter"></router-view>
    </footer>
  </div>
</template>
<style lang="less" scoped>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
main {
  flex: 1;
}
</style>
