<script>
import { mapState } from 'vuex';
import PFLocationValuePropComponent from '@/components/common/PFLocationValuePropComponent';
import PFItemListComponent from '@/components/common/PFItemListComponent';
import PEEditableListComponent from '@/components/common/PEEditableListComponent';
import PFServiceCountsComponent from '@/components/common/PFServiceCountsComponent';
import PFProfileResourcesComponent from '@/components/common/PFProfileResourcesComponent';
import PFOfficesComponent from '@/components/common/PFOfficesComponent';
import PFLocationQPPSComponent from '@/components/common/PFLocationQPPSComponent';
import PFGwSLogoComponent from '@/components/common/PFGwSLogoComponent';

const NODE_ENV = PROCESS_NODE_ENV;
const SHOW_CLOUD_IMPL_ENV = 'none';
const urlLinks = require('@/plugins/linkUrl');

export default {
  name: 'PFCountryAccordionComponent',
  components: {
    PFProfileResourcesComponent,
    PFItemListComponent,
    PFLocationValuePropComponent,
    PEEditableListComponent,
    PFServiceCountsComponent,
    PFOfficesComponent,
    PFLocationQPPSComponent,
    PFGwSLogoComponent,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    nodeEnv: NODE_ENV,
    showCloudImplEnv: SHOW_CLOUD_IMPL_ENV,
    helpTextLinks: urlLinks.HELP_TEXT_LEARN_MORE,
    showConsultantEnv: '',
  }),
  methods: {
    getErrorsCount(countryId) {
      return this.errorFields.filter((err) => err.country === countryId).length;
    },
    getErrorMessage(count) {
      return `${count}
      ${this.$t(`editor.footer.error.message${count > 1 ? '.plural' : ''}`)}`;
    },
    showSolution(solutions) {
      return this.editMode || (solutions && solutions.length > 0);
    },

  },
  computed: {
    ...mapState('fullData', {
      allIndustries: (state) => state.allIndustries,
      allCountries: (state) => state.allCountries,
    }),
    ...mapState('partnerProfile', {
      errorFields: (state) => state.errorFields,
      growCountries: (state) => state.profileData.growWithSap,
      localProfiles: (state) => state.profileData.localProfiles,
    }),
    availableCountries() {
      return this.localProfiles.map((profile) => ({
        countryName: profile.countryName,
        countryCode: profile.country,
      }));
    },
    selectedLocationOnResultPage() {
      return JSON.parse(localStorage.getItem('selectedLocation')) || [];
    },
    mappedCountryCodes() {
      const filteredCountries = this.availableCountries.filter((profile) => this.selectedLocationOnResultPage.includes(profile.countryName));
      const filteredCountriesCodes = filteredCountries.map((code) => code.countryCode);
      return filteredCountriesCodes;
    },
    partnerProfileData() {
      return this.$store.state.partnerProfile.profileData;
    },
    consultantNumber() {
      const result = new Map();
      if (this.$store.state.partnerProfile.profileData.localProfiles) {
        this.$store.state.partnerProfile.profileData.localProfiles.forEach((localProfile) => {
          let totalConsultantNumber = 0;
          if (localProfile.consultants) {
            localProfile.consultants.forEach((consultant) => {
              totalConsultantNumber += consultant.total;
            });
          }
          result.set(localProfile.country, totalConsultantNumber);
        });
      }
      return result;
    },
    checkedBoxes: {
      get() {
        return this.$store.state.partnerProfile.expandedCountry;
      },
      set(expandedCountry) {
        this.$store.dispatch('partnerProfile/toggleCountryAccordion', expandedCountry);
      },
    },
  },

  mounted() {
    this.showConsultantEnv = this.$showConsultantEnv;
    this.checkedBoxes = this.mappedCountryCodes;
  },
};
</script>

<template>
  <div class="ds-flexCol-12 ds-accordion ds-m-t-xl country-accordion-section">
    <hr class="country-line ds-m-none" />
    <div
      v-for="countryProfile in partnerProfileData.localProfiles"
      :key="countryProfile.id + countryProfile.country"
      class="ds-accordion__item"
    >
      <input
        :id="countryProfile.id + countryProfile.country"
        :value="countryProfile.country"
        type="checkbox"
        name="items"
        v-model="checkedBoxes"
      />
      <div class="ds-accordion__item-header--icon">
        <label :for="countryProfile.id + countryProfile.country" class="link-cursor">
          <i
            :class="
              checkedBoxes.includes(countryProfile.country)
                ? 'ds-icon--big ds-icon--minus'
                : 'ds-icon--big ds-icon--plus'
            "
          ></i>
        </label>
      </div>
      <div class="ds-accordion__item-content country-accordion-content ds-p-l-s">
        <div
          class="ds-accordion__item-content--header ds-p-t-s ds-p-b-s link-cursor"
          :class="checkedBoxes.includes(countryProfile.country) ? 'font-expanded ' : 'font-normal'"
        >
          <label :for="countryProfile.id + countryProfile.country" class="link-cursor">
            {{ allCountries[countryProfile.country] || countryProfile.countryName }}
          </label>
          <div class="errors-container" v-show="editMode && getErrorsCount(countryProfile.country) > 0">
            <div class="ds-message__content editor-warning-message accordion-msg ds-p-none">
              <i class="ds-icon--big ds-icon--close-outline ds-message__content__icon" />
              {{ getErrorMessage(getErrorsCount(countryProfile.country)) }}
            </div>
          </div>
        </div>
        <div
          class="ds-accordion__item-content--body ds-p-l-s country-accordion-body"
          v-if="checkedBoxes.includes(countryProfile.country)"
        >
          <div class="ds-flexRow ds-m-t-xs">
            <div class="ds-flexCol-12">
              <PFLocationValuePropComponent :editMode="editMode" :countryProfile="countryProfile" />
            </div>
          </div>
          <div v-if="growCountries.includes(countryProfile.country)" class="ds-flexRow ds-m-t-xs">
            <div class="ds-flexCol-12">
              <div class="GwS-container GwS-margin">
                <PFGwSLogoComponent
                  :logoWidth="136"
                  :logoHeight="52"
                  :showCountryList="false"
                  :growCountries="growCountries"
                  :localProfiles="localProfiles"
                  :analyticsOrigin="'Country_Section'"
                />
              </div>
            </div>
          </div>
          <div
            v-if="nodeEnv === showConsultantEnv || nodeEnv === showCloudImplEnv"
            class="ds-flexRow ds-m-t-l location-counts-max-width row-content-center"
          >
            <div class="ds-flexCol ds-flexCol-sm-6">
              <PFServiceCountsComponent
                v-if="nodeEnv === showConsultantEnv"
                :close-text="$t('service.consultant.modal.closeText')"
                :header-text="$t('service.consultant.modal.headerText')"
                :viewMode="!editMode"
                :consultantMode="true"
                :locationSection="true"
                :title="$t('service.title.consultant.placeholder')"
                :helpText="
                  editMode
                    ? $t('helptext.service.consultant.edit.placeholder')
                    : $t('helptext.service.consultant.view.placeholder')
                "
                :helpTextLink="helpTextLinks.CERTIFIED_CONSULTANT"
                :showNumber="countryProfile.totalConsultants ? countryProfile.totalConsultants : 0"
                :country="countryProfile.country"
                :is-consultants="true"
              />
            </div>
            <div v-if="nodeEnv === showCloudImplEnv" class="ds-flexCol ds-flexCol-sm-6">
              <PFServiceCountsComponent
                :close-text="$t('service.cloud.implementations.modal.closeText')"
                :header-text="$t('service.cloud.implementations.modal.headerText')"
                :viewMode="!editMode"
                :consultantMode="true"
                :locationSection="true"
                :title="$t('service.title.cloud.placeholder')"
                :helpText="
                  editMode
                    ? $t('helptext.service.cloud.edit.placeholder')
                    : $t('helptext.service.cloud.view.placeholder')
                "
                :showNumber="countryProfile.totalCloudImpls ? countryProfile.totalCloudImpls : 0"
                :country="countryProfile.country"
                :is-consultants="false"
              />
            </div>
          </div>
          <div
            class="ds-flexRow ds-m-b-l country-editable-list-row row-content-center"
            :class="showSolution(countryProfile.solutions) ? '' : 'country-editable-list-row-width'"
          >
            <div class="ds-flexCol ds-flexCol-xs-6 ds-flexCol-md-4 col-content-center">
              <PFItemListComponent
                :class="showSolution(countryProfile.solutions) ? '' : 'pf-country-item'"
                :listTitle="$t('service.section.business.models.title')"
                :moreTitle="$t('service.section.business.models.more')"
                :items="countryProfile.businessModels"
                itemKey="id"
                :helpText="
                  editMode
                    ? $t('service.section.helptext.business.models.edit')
                    : $t('service.section.helptext.business.models.preview')
                "
                :helpTextLink="helpTextLinks.SERVICES"
                itemTextKey="title"
                :editMode="editMode"
                :locationSection="true"
                descriptionTextKey="description"
              />
            </div>
            <div class="ds-flexCol ds-flexCol-xs-6 ds-flexCol-md-4">
              <PEEditableListComponent
                v-if="editMode"
                idKey="industryId"
                textKey="title"
                :listHeading="$t('service.section.title.focus.indutries')"
                :items="countryProfile.industries"
                :allItems="allIndustries"
                :localProfileId="countryProfile.id"
                selectAction="partnerProfile/updateLocalIndustries"
                removeAction="partnerProfile/removeLocalIndustry"
                reorderAction="partnerProfile/reorderLocalIndustries"
                :helpText="$t('service.section.helptext.focus.industries.edit')"
                :locationSection="true"
                :countryName="countryProfile.country"
                :mandatoryCheck="false"
              />
              <PFItemListComponent
                :class="showSolution(countryProfile.solutions) ? '' : 'pf-country-item'"
                v-if="!editMode"
                :listTitle="$t('service.section.title.focus.indutries')"
                :moreTitle="$t('service.section.title.indutries')"
                :items="countryProfile.industries"
                itemKey="industryId"
                :initialDisplayCount="5"
                :helpText="$t('service.section.helptext.focus.industries.preview')"
                :helpTextLink="helpTextLinks.INDUSTRY_FOCUS"
                itemTextKey="title"
                :locationSection="true"
              />
            </div>
          </div>
          <PFOfficesComponent
            :editMode="editMode"
            :countryProfile="countryProfile"
            :partnerProfileId="partnerProfileData.profileId"
            :singleLocalProfileView="false"
          />
          <PFLocationQPPSComponent class="ds-m-t-l" :getQPPS="countryProfile.packagedSolutions" />
          <div
            v-if="editMode || (countryProfile.resources && countryProfile.resources.length > 0)"
            class="ds-flexRow ds-m-b-xl"
          >
            <h5 class="ds-heading--s offices__title ds-m-t-s ds-m-b-m">
              {{ $t("section.locations.title.resources") }}
            </h5>
            <div v-if="editMode" class="local-resource-guiding-text">
              {{
                $t("local.resource.guiding.text", {
                  country: allCountries[countryProfile.country] || countryProfile.countryName,
                })
              }}
            </div>
            <PFProfileResourcesComponent
              :edit-mode="editMode"
              :country-profile-id="countryProfile.id"
              :countryName="countryProfile.country"
              :countryText="allCountries[countryProfile.country] || countryProfile.countryName"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.ds-accordion__item .ds-accordion__item-header--icon {
  margin-top: 3px;
}

input:checked ~ .ds-accordion__item-content .ds-accordion__item-content--body {
  max-height: 100% !important; //override the ds.css style for max-height: 100vh;
  overflow: visible;
  text-align: left;
}

.display {
  display: block !important;
}

input:checked ~ .ds-accordion__item-content .ds-accordion__item-content--body {
  max-height: 100% !important;
  overflow: visible;
  text-align: left;
}

.display {
  display: block !important;
}

.hidden {
  display: none !important;
}

.ds-accordion__item {
  overflow: visible;
}

.ds-accordion__item-content--header {
  font-size: 21px;
  line-height: 1.52;
  padding-left: 0;
  .errors-container {
    float: right;
  }
}

.GwS-container {
  display: flex;
  justify-content: center;
}

.GwS-margin {
  margin-top: 52px;
  margin-bottom: 40px;
}

.offices__title {
  width: 100%;
  display: block;
  text-align: center;
  &:extend(.font-normal);
}

.country-accordion-section {
  max-width: 1280px !important;
  margin-left: auto;
  margin-right: auto;
}

.country-accordion-body {
  padding-right: 68px;
}

.country-accordion-content {
  width: 100%;
  .country-editable-list-row {
    .ds-flexCol {
      min-width: 230px;
    }
  }
  .country-editable-list-row-width {
    justify-content: center;
  }
  .pf-country-item {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  .location-counts-max-width {
    .ds-flexCol {
      min-width: 200px;
    }
  }
  .row-content-center {
    justify-content: center;
  }
  .local-resource-guiding-text {
    &:extend(.font-normal);
    font-size: 16px;
    line-height: 24px;
    color: @color-gray-1;
    max-width: 778px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
