<script>
import '@sapudex/web-components/dist/Dropdown.js';
import '@sapudex/web-components/dist/List.js';
import '@sapudex/web-components/dist/ListItem.js';

const urlLinks = require('@/plugins/linkUrl');

export default {
  name: 'PFHelpComponent',
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
    if (this.$router.currentRoute.value.name === 'profileEditorPageEdit' || this.$router.currentRoute.value.name === 'profileEditorPagePreview') {
      this.faqLink = urlLinks.FOOTER.FAQ_PE;
    }
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  props: {
    editorHeader: { type: Boolean, default: false },
  },
  components: {},
  data: () => ({
    dropdownEnabled: false,
    faqLink: urlLinks.FOOTER.FAQ_PF,
    partnerWithUs: urlLinks.PARTNER_WITH_US,
  }),
  methods: {
    openInNewTab(url) {
      const win = window.open(url, '_blank');
      win.focus();
      this.$refs.dropdown.onClose();
    },
    showIndicator() {
      this.dropdownEnabled = !this.dropdownEnabled;
    },
    hideIndicator() {
      this.dropdownEnabled = false;
    },
    toggleDropdown() {
      this.dropdownEnabled ? this.$refs.dropdown.onOpen() : this.$refs.dropdown.onClose();
    },
    changeIconColor() {
      this.$refs.helpIcon.classList.remove(this.editorHeader ? 'pe-header-icon-color' : 'pf-header-icon-color');
      this.$refs.helpIcon.classList
        .add(this.editorHeader ? 'pe-header-icon-hover-color' : 'pf-header-icon-hover-color');
    },
    changeIconColorBack() {
      if (!this.dropdownEnabled) {
        this.$refs.helpIcon.classList
          .remove(this.editorHeader ? 'pe-header-icon-hover-color' : 'pf-header-icon-hover-color');
        this.$refs.helpIcon.classList.add(this.editorHeader ? 'pe-header-icon-color' : 'pf-header-icon-color');
      }
    },
    sendEmail() {
      const subject = encodeURIComponent(this.$tc('help.mail.subject'));
      const emailBody = encodeURIComponent(this.$tc('help.mail.body'));
      const email = encodeURIComponent(this.$tc('help.mail.contact.email'));
      this.openInNewTab(`mailto:${email}?subject=${subject}&display-name=SAP&body=${emailBody}`);
    },
    handleScroll() {
      if (document.getElementsByClassName('edit-header')[0]) {
        const headerBottom = document.getElementsByClassName('edit-header')[0].offsetHeight;
        if (!this.editorHeader && window.scrollY > headerBottom) {
          this.hideIndicator();
        } else if (this.editorHeader && window.scrollY < headerBottom) {
          this.hideIndicator();
        }
      }
    },
  },
  watch: {
    dropdownEnabled(val) {
      if (val) {
        this.$refs.rectangleIndicator.style.visibility = 'visible';
        this.changeIconColor();
      } else {
        this.$refs.rectangleIndicator.style.visibility = 'hidden';
        this.changeIconColorBack();
      }
    },
    $route: {
      handler(to) {
        if (to.name === 'profileEditorPageEdit' || to.name === 'profileEditorPagePreview') {
          this.faqLink = urlLinks.FOOTER.FAQ_PE;
        } else {
          this.faqLink = urlLinks.FOOTER.FAQ_PF;
        }
      },
      deep: true,
    },
  },

};
</script>

<template>
  <udex-dropdown @click-trigger="toggleDropdown" id="dropdown" ref="dropdown" horizontal-align="Center">
    <div slot="trigger" class="button-container">
      <button class="help-dropdown-btn" @mouseover="changeIconColor"
        @mouseleave="changeIconColorBack" @click="showIndicator" v-click-outside="hideIndicator" tabindex="0"
        @keyup.esc="hideIndicator">
        <svg ref="helpIcon"
          :class="editorHeader ? 'pe-header-icon-color' : 'pf-header-icon-color'">
          <use xlink:href="@/assets/question-mark.svg#help-icon"></use>
        </svg>
      </button>
      <div class="rectangle helpIndicator" ref="rectangleIndicator" />
    </div>
    <div v-if="dropdownEnabled" class="dropdown-header">
      {{ $t('title.get.help') }}
    </div>
    <udex-list v-if="dropdownEnabled">
      <udex-list-item @click="openInNewTab(faqLink)" @keyup.enter="openInNewTab(faqLink)">{{ $t('title.faq')
        }}</udex-list-item>
      <udex-list-item @click="openInNewTab(partnerWithUs)" @keyup.enter="openInNewTab(partnerWithUs)">{{
        $t('title.partner.with.us') }}</udex-list-item>
      <udex-list-item @click="sendEmail()" @keyup.enter="sendEmail()">{{ $t('title.contact.us') }}</udex-list-item>
    </udex-list>
  </udex-dropdown>
</template>

<style scoped lang="less">
@import "../../assets/css/common.less";
#dropdown{
display: flex;
align-items: center;
height: 100%;
position: relative;
}
.button-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60px;
  position: relative;
  justify-content: center;
  margin-right: 4px;
}
.dropdown-header{
font-size: 20px;
padding: 10px 12px;
}
.ds-select-button {
  display: block;
}

.rectangle {
  width: 28px;
  height: 4px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: @color-blue-light-1;
  visibility: hidden;
}
.helpIndicator {
  position: absolute;
  bottom: 0px;
}

.help-dropdown-btn {
  text-align: center;
  padding: 0;
  border: none;
  margin: 0 7px;
  svg {
    vertical-align: middle;
    width: 18px;
    height: 18px;
    fill: currentColor;
  }
}
.help-dropdown-btn:hover {
  color: @color-blue-light-1;
  cursor: pointer;
  .rectangle {
    visibility: visible !important;
  }
}
.dropdown-option-get-help {
  &:extend(.font-expanded);
  font-size: 21px;
  line-height: 27px;
}
.help-dropdown {
  width: 319px;
  height: fit-content;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.21);
  right: -780%;
  margin-top: -1px;
}

.pf-header-icon-hover-color,.pe-header-icon-hover-color {
  color: @color-blue-light-1 !important ;
}
.pf-header-icon-color,.pe-header-icon-color {
  color: @color-blue-gray-light;
}

</style>
