<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import '@sapudex/web-components/dist/Search.js';
import '@sapudex/web-components/dist/List.js';
import '@sapudex/web-components/dist/Accordion.js';
import '@sapudex/web-components/dist/AccordionItem.js';
import { badgeCounter } from '@/utils/filterUtils';

export default {
  name: 'PFAccordionSolutionFilterMobileComponent',
  props: {
    title: {
      type: String,
    },
    filterType: {
      type: String,
    },
  },
  data: () => ({
    lastEvent: null,
    searchInput: '',
  }),

  computed: {
    ...mapState('solutionL2Store', {
      sortedSolution: (state) => state.sortedSolution,
      solutionDataMap: (state) => state.solutionDataMap,
      reverseL2L1Mapping: (state) => state.reverseL2L1Mapping,
      titleL2: (state) => state.titleL2Mapping,
    }),
    ...mapState('profileSearch', {
      countResults: (state) => state.countResults,
      selectedOptions: (state) => state.selectedOptions.products,
    }),
    ...mapState('filtersDrawer', {
      expandedItem: (state) => state.expandedAccordionItem,
    }),

    getSolutionTitleL2AsArray() {
      return Array.from(this.titleL2);
    },
    filteredL2() {
      return this.sortedSolution.map((solution) => solution.L2.filter((L2) => this.getSolutionTitleL2AsArray
        .find(([key, value]) => value === L2)?.[0]
        ?.toLowerCase()
        .includes(this.searchInput.toLowerCase())));
    },
    filteredOptions() {
      return this.sortedSolution
        .map((solution, index) => ({ ...solution, L2: this.filteredL2[index] }))
        .filter((solution) => solution.L2.length > 0);
    },
    noSearchResults() {
      return this.filteredOptions.length === 0;
    },
    badgeCount() {
      return badgeCounter(this.selectedOptions);
    },
  },
  mounted() {
    this.sortSolution();
  },
  methods: {
    ...mapMutations('solutionL2Store', {
      sortSolution: 'sortSolution',
    }),
    ...mapActions('solutionL2Store', {
      removeSelectedL2Item: 'removeSelectedL2Item',
      addSelectedL2Item: 'addSelectedL2Item',
    }),
    ...mapActions('profileSearch', {
      cleanFilters: 'cleanFilters',
    }),
    ...mapActions('filtersDrawer', {
      setExpandedAccordionItem: 'setExpandedAccordionItem',
    }),
    accordionToggle(title) {
      this.setExpandedAccordionItem(this.expandedItem === title ? null : title);
      this.sortSolution();
    },
    onSearchReset() {
      this.searchInput = '';
    },
    onSearch(text) {
      if (!this.searchInput) {
        return text;
      }
      const regex = new RegExp(`(${this.searchInput})`, 'gi');
      return text.replace(regex, '<strong>$1</strong>');
    },

    handleSelectionChange(event) {
      const selectedItem = [event.detail.item];
      const L1Item = selectedItem[0].parentNode.accessKey;
      const L2Item = selectedItem[0].accessKey;
      const solutionObject = { L1: L1Item, L2: L2Item };
      console.log('solutionObject', solutionObject);

      this.addOrRemoveL2Item(solutionObject);
    },
    addOrRemoveL2Item(solution) {
      const solutionL2Status = this.solutionDataMap.get(solution.L1).L2Data.get(solution.L2).status;

      if (solutionL2Status === 1) {
        this.removeSelectedL2Item(solution);
      } else {
        this.addSelectedL2Item(solution);
      }
    },

    selectOption(_option) {
      this.$store.dispatch('profileSearch/setPathChangedFromUiFlag', true);
      const option = { ..._option };

      if (!option.isSelected) {
        option.optionType = this.filterType;
        this.$store.dispatch('profileSearch/uncheckOption', option);
      } else {
        this.$store.dispatch('profileSearch/addSelectedOption', { optionType: this.filterType, value: option });
      }
      this.$store.commit('profileSearch/toggleOptionSelectedStatus', option);
    },
    inputSearchValue(event) {
      this.searchInput = event.detail.value;
    },
    getL1Title(L1Option) {
      let selectedItems = '';
      const solutionL1ItemStatus = this.solutionDataMap?.get(L1Option.title)?.status;

      if (solutionL1ItemStatus !== 0) {
        const count = L1Option.L2.filter((L2Item) => {
          const solutionL2ItemStatus = this.solutionDataMap?.get(L1Option.title)?.L2Data?.get(L2Item)?.status;
          return solutionL2ItemStatus === 1;
        }).length;
        selectedItems = ` (${count})`;
      }
      const L1Title = this.solutionDataMap.get(L1Option.title).title;
      return L1Title + selectedItems;
    },
    getL2Title(L2Option) {
      const L1Title = this.reverseL2L1Mapping.get(L2Option);
      return this.solutionDataMap.get(L1Title).L2Data.get(L2Option).title;
    },

  },
};
</script>

<template>
  <udex-accordion-item
    :title="title"
    :expanded.prop="expandedItem === title"
    :numberBadge.prop="badgeCount"
    @click="accordionToggle(title)"
    automation-id="filterDrawer-accordionItem"
  >
    <udex-search
      class="udex-search"
      label="Search"
      mode="Standard"
      @handle-value-search="inputSearchValue"
      @handle-value-reset="onSearchReset"
      @click.stop
      :showSubmitButton.prop="true"
      automation-id="filterDrawer-search"
    ></udex-search>
    <div class="udex-list-wrapper">
      <udex-list
        @selection-change="handleSelectionChange"
        id="udex-list"
        :mode.prop="'MultiSelect'"
        type="None"
        class="items-list"
        automation-id="filterDrawer-list"
      >
        <template class="udex-list-items-container">
          <udex-list-group-header
            id="drawer-udex-list-group-header"
            v-for="L1Option in this.filteredOptions"
            :key="L1Option.title"
            :accessKey="L1Option.title"
            :label="getL1Title(L1Option)"
            nonInteractive
            automation-id="filterDrawer-listGroupHeader"
          >
            <udex-list-item
              id="drawer-udex-list-item"
              :mode.prop="'MultiSelect'"
              v-for="L2Option in L1Option.L2"
              :key="L2Option"
              :accessKey="L2Option"
              @mouseenter="onMouseEnter"
              :selected.prop="Boolean(solutionDataMap?.get(L1Option.title)?.L2Data?.get(L2Option)?.status)"
              :disabled.prop="!solutionDataMap?.get(L1Option.title)?.L2Data?.get(L2Option)?.available"
              @click.stop
              automation-id="filterDrawer-listItem"
            >
              <span v-html="onSearch(getL2Title(L2Option))" automation-id="filterDrawer-listItemText"></span>
            </udex-list-item>
          </udex-list-group-header>
        </template>
        <p class="no-searsh-results" v-if="noSearchResults" automation-id="filterDrawer-noResultsText">
          {{ this.$t("filter.drawer.search.no.results") }}
        </p>
      </udex-list>
    </div>
  </udex-accordion-item>
</template>

<style lang="less" scoped>
.udex-list-wrapper {
  margin-top: 8px;
}
.udex-list-items-container {
  display: flex;
  flex-direction: column;
  max-height: 258px;
}
</style>
