<script>
import { mapState } from 'vuex';
import AutocompleteComponent from './AutocompleteComponent';
import responseHandlerUtil from '@/plugins/search/responseHandler';

const DEFAULT_PARTNER_FINDER_SEARCH_PAGE = 'index.html';
const PARTNER_NAVIGATOR_PAGE = 'partnerNavigatorPage';
const SMALLSCREEN = 547;
const MIDSCREEN = 509;
const LARGECREEN = 707;
const PARTNER_NAME = 'Partner Name';
const KEYWORD = 'Keyword';
const DEFAULT_QUERY_TERM_TYPE = 'partner';
const KEYWORD_QUERY_TYPE = 'keyword';

export default {
  name: 'PFSearchBarComponent',
  components: {
    AutocompleteComponent,
  },
  props: {
    target: {
      type: String,
      validator: (val) => ['_self', '_blank'].includes(val),
      default: '_self',
    },
    searchPage: { type: String },
    placeHolderText: { type: String, default: 'Search by Solution, Industry Focus, Location, Partner Name' },
    placement: { type: String },
    isHomePage: { type: Boolean, default: false },
  },
  computed: {
    ...mapState('autocomplete', {
      searchResults: (state) => state.searchResults,
      showSearchSuggestion: (state) => state.showSearchSuggestion,
      fromHomePage: (state) => state.fromHomePage,
    }),
    lastUsedQuery: {
      get() {
        return this.$store.state.autocomplete.lastUsedQuery;
      },
    },
    query() {
      return this.$store.state.profileSearch.query;
    },
    groupedSearchResults() {
      const titleItems = this.searchResults.filter((result) => result.title === 'title');
      const suggestionItems = this.searchResults.filter((result) => result.title !== 'title');
      return suggestionItems.concat(titleItems);
    },
    truncatePlaceHolderText() {
      if (this.maxChar >= 58) {
        return this.placeHolderText;
      }
      return `${this.placeHolderText.substring(0, this.maxChar - 3)}...`;
    },
    currentOption() {
      return this.$store.state.profileSearch.queryTermTypeState === KEYWORD_QUERY_TYPE ? KEYWORD : PARTNER_NAME;
    },
    availableOption() {
      return this.$store.state.profileSearch.queryTermTypeState === DEFAULT_QUERY_TERM_TYPE
      || this.$store.state.profileSearch.queryTermTypeState === '' ? KEYWORD : PARTNER_NAME;
    },
    hasText() {
      return this.query?.length > 0;
    },
  },
  watch: {
    query(val) {
      this.changeUpdatedData(val);
    },
  },
  mounted() {
    this.updatedData = this.query;
    this.onResize();
  },
  data: () => ({
    showDropdown: false,
    openInNewTab: false,
    partnerFinderSearchPage: null,
    updatedData: '',
    selectedItemsIdx: -1,
    keyCodes: {
      END: 35,
      HOME: 36,
      SHIFT: 16,
      COMMAND: 91,
      OPTION: 18,
      CONTROL: 17,
      DOWN: 40,
      ENTER: 13,
      ESCAPE: 27,
      LEFT: 37,
      RIGHT: 39,
      TAB: 9,
      UP: 38,
      CONTINUOUS_KEY_PRESSING: 229,
    },
    maxChar: 58,
  }),
  created() {
    this.partnerFinderSearchPage = this.searchPage
      ? this.searchPage
      : DEFAULT_PARTNER_FINDER_SEARCH_PAGE;
    this.openInNewTab = this.target === '_blank';
  },
  methods: {
    closeDropDownList() {
      this.showDropdown = false;
      this.$emit('dropDownStateChange', this.showDropdown);
    },
    changeCurrentOption() {
      this.$store.commit('profileSearch/updateQueryTypeState', this.availableOption === KEYWORD ? KEYWORD_QUERY_TYPE : DEFAULT_QUERY_TERM_TYPE);
      this.closeDropDownList();
      this.$store.dispatch('autocomplete/keyPressed', this.query);
      if (!this.isNotCurrentPath()) {
        this.$store.commit('profileSearch/changeTriggeredQuery', this.query);
        this.$eventBus.$emit('PFAACustomEvent',
          { eventName: 'SearchInitiated', custom2: this.query });
        this.$store.dispatch('profileSearch/setPathChangedFromUiFlag', true);
        this.$store.dispatch('profileSearch/triggerSelectedQueryType');
      }
    },
    dropDownClicked() {
      this.showDropdown = !this.showDropdown;
      this.$emit('dropDownStateChange', this.showDropdown);
    },
    onResize() {
      const textWidth = this.$refs.searchBarContainer.clientWidth;
      if (this.placement === 'homepage') {
        if (textWidth <= LARGECREEN && textWidth > MIDSCREEN) {
          this.maxChar = Math.round(textWidth / 12);
        } else if (textWidth <= MIDSCREEN) {
          this.maxChar = Math.round(textWidth / 13);
        } else {
          this.maxChar = 58;
        }
      } else if (textWidth <= SMALLSCREEN) {
        this.maxChar = Math.round(textWidth / 10);
      } else {
        this.maxChar = 58;
      }
    },
    updateQuery(event) {
      this.$store.dispatch('profileSearch/setQueryWithoutSearch', event.target.value);
      this.updatedData = event.target.value;
    },
    changeUpdatedData(newValue) {
      this.updatedData = newValue;
    },
    submitForm() {
      if (this.isNotCurrentPath()) {
        this.$store.dispatch('profileSearch/routerSetQuery', this.query);
      }
    },
    clearFilter() {
      this.$store.commit('profileSearch/cleanFilters');
      this.$store.commit('profileSearch/cleanResults');
      this.$store.commit('profileSearch/setQuery', null);
      this.$store.commit('profileSearch/setPageNumber', 1);
    },
    isNotCurrentPath() {
      return !(this.$router.currentRoute.value.name === PARTNER_NAVIGATOR_PAGE);
    },
    onMagnifierClick() {
      this.$store.commit('profileSearch/changeTriggeredQuery', this.query);
      if (this.groupedSearchResults[this.selectedItemsIdx]
        && this.groupedSearchResults[this.selectedItemsIdx].title === 'title') {
        this.$store.commit('profileSearch/setSearchPartner', true);
      }
      this.clearFilter();
      this.$store.dispatch('solutionL2Store/clearSelectedSolution');
      this.discardSelected();
      this.$store.commit('autocomplete/setResults', []);
      const dataPayload = this.updatedData ? this.updatedData : '';
      this.$store.commit('autocomplete/setSearchSuggestion', false);
      if (this.isNotCurrentPath()) {
        this.$store.dispatch(
          'profileSearch/setQueryWithoutSearch',
          dataPayload,
        );
        this.$store.commit('profileSearch/setSearchFromHomePage', true);
      } else {
        this.$store.dispatch('profileSearch/setQuery', dataPayload);
      }
      this.submitForm();
      this.$store.dispatch('autocomplete/abortController');
      this.$store.commit('autocomplete/setFromHomePage', true);
      this.$eventBus.$emit('PFAACustomEvent',
        { eventName: 'SearchInitiated', custom2: this.query });
    },
    clearSearchField() {
      this.$store.commit('profileSearch/changeTriggeredQuery', '');
      this.$store.dispatch('autocomplete/clearResults');
      // as computed properties are cached we have to "update" them.
      if (this.query === '') {
        this.$store.commit('profileSearch/cleanFilters');
        this.$store.dispatch('solutionL2Store/unselectAllSolution', false);
        this.$store.commit('profileSearch/setQuery', null);
      }
      if (this.isNotCurrentPath()) {
        this.$store.dispatch('profileSearch/setQueryWithoutSearch', '');
      } else {
        this.$store.commit('profileSearch/cleanFilters');
        this.$store.dispatch('solutionL2Store/unselectAllSolution', false);
        this.$store.dispatch('profileSearch/setQuery', '');
      }
      this.discardSelected();
    },
    inputKeyup(event) {
      const isSpecialKey = Object.values(this.keyCodes)
        .findIndex((value) => value === event.keyCode) !== -1;
      if (isSpecialKey) {
        return;
      }
      this.discardSelected();
      this.$store.dispatch('autocomplete/keyPressed', event.target.value);
    },
    up() {
      if (!this.showSearchSuggestion) {
        return;
      }
      if (this.selectedItemsIdx === 0) {
        this.selectedItemsIdx--;
        this.$store.dispatch('profileSearch/setQueryWithoutSearch', this.lastUsedQuery);
        return;
      }
      if (this.selectedItemsIdx === -1) {
        this.selectedItemsIdx = this.searchResults.length - 1;
      } else {
        this.selectedItemsIdx--;
      }
      this.$store.dispatch('profileSearch/setQueryWithoutSearch',
        this.groupedSearchResults[this.selectedItemsIdx].value);
    },
    down() {
      if (!this.showSearchSuggestion) {
        return;
      }
      if (this.selectedItemsIdx === this.searchResults.length - 1) {
        this.discardSelected();
        this.$store.dispatch('profileSearch/setQueryWithoutSearch', this.lastUsedQuery);
        return;
      }
      this.selectedItemsIdx++;
      this.$store.dispatch('profileSearch/setQueryWithoutSearch',
        this.groupedSearchResults[this.selectedItemsIdx].value);
    },
    discardSelected() {
      this.selectedItemsIdx = -1;
    },
    closeSearchSuggestion() {
      this.$store.dispatch('autocomplete/setShowSearchSuggestion', false);
    },
    openSearchSuggestion() {
      this.$store.dispatch('autocomplete/openSearchSuggestion');
    },
    triggerSearchSuggestion() {
      if (this.$store.state.autocomplete.searchResults.length === 0) {
        this.$store.dispatch('autocomplete/getAutocompleteResults');
      } else {
        this.openSearchSuggestion();
      }
    },
    addSpaceAfterComma(text) {
      return responseHandlerUtil.addSpaceAfterComma(text);
    },
    searchBarPlaceholderText() {
      return this.isHomePage ? '(Optional)' : this.$t('searchbar.search.input.placeholder');
    },
  },
};
</script>

<template>
  <div class="pf-search-bar-outer-container" :class="{'pf-search-bar-homepage-outer-container' : isHomePage}">
  <div class="pf-search-bar-container" ref="searchBarContainer"
    v-bind:class="{ 'ds-p-y-xxl': this.placement === 'homepage', 'pf-search-bar-homepage-container' : isHomePage }"
  >
  <div class="ds-select-box guide-dropdown" :class="showDropdown || showSearchSuggestion ? 'add-after-border' : ''"
  v-click-outside="closeDropDownList">
    <label class="ds-textField ds-select-box__wrapper guide-dropdown__wrapper" @click="dropDownClicked">
        <div id="selectbox-search-bar" class="ds-textField__input ds-select-box__wrapper__input" type="text"
        :class="{ 'guide-dropdown-focused-border': showDropdown }" />
        <div class="ds-combo-box__placeholder">
          <span class="editlist__placeholder__text home-filter-placeholder"
            ref="placeHolderText"
            id="cancel-open-dropdown">
            <span>{{currentOption}}</span>
          </span>
        </div>
        <img v-if="!showDropdown" class="down-arrow ds-select-box__wrapper__icon--down" src="../../../assets/slim-arrow-down.svg"/>
        <img v-else class="down-arrow ds-select-box__wrapper__icon--down" src="../../../assets/slim-arrow-up.svg"/>
        <div class="ds-select-box__wrapper__dropdown dropdown-item"
          :class="{ 'display-block': showDropdown, 'display-none': !showDropdown }"
        >
        <div class="ds-dropdown__inner" @click.stop="changeCurrentOption">
            <div class="new-ds-dropdown__inner__option option-text">{{availableOption}}</div>
        </div>
        </div>
    </label>
</div>
  <resize-observer @notify="this.onResize"></resize-observer>
    <section
      v-bind:class="[openInNewTab ? 'pf-search-bar_light' : '']"
      class="pf-search-bar"
      v-click-outside="closeSearchSuggestion"
    >
      <form
        class="pf-search-bar_form-wrapper"
        ref="pf-search-input-form"
        @submit.prevent="submitForm"
      >
        <div class="form-group"
        :class="showSearchSuggestion ? 'add-after-right-border' : ''">
          <input
            type="search"
            class="form-control srch-autocomplete-input top-search-bar search-input"
            :value="query"
            :placeholder="searchBarPlaceholderText()"
            @input="updateQuery"
            @keyup="inputKeyup"
            @keyup.esc="clearSearchField"
            @keydown.enter="onMagnifierClick"
            @keydown.up.prevent="up"
            @keydown.down.prevent="down"
            @focus="triggerSearchSuggestion"
          />
          <div class="input-button-search">
            <button
              class="search__submit-btn-new"
              type="button"
              @click="onMagnifierClick"
            ><img class="search-box-search-icon" src="../../../assets/search.svg"/></button>
          </div>
          <div class="input-button-group" key="clearn-button">
            <button
              class="search__clear-btn-new"
              :class="hasText ? 'display-block' : 'display-none'"
              type="button"
              @click="clearSearchField"
            ><img class="search-box-decline-icon" src="../../../assets/decline.svg"/>
          </button>
          </div>
        </div>
      </form>
    </section>
  </div>
    <AutocompleteComponent
      search-sugestion-item-height="28"
      @submit-form-handler="submitForm"
      :isHomePage="isHomePage"
    />
  </div>
</template>

<style lang="less">
@import './styles/css/search-form';
@import './styles/css/autocomplete';
@import '../../../assets/css/common.less';

.pf-search-bar .input-button-search {
  z-index: 2;
}
.pf-search-bar .input-button-group {
  z-index: 2;
}
input::placeholder{
  color: #475E75!important;
}
.search__clear-btn-new {
  .ds-icon--close::before{
    font-size: 11px;
  }
}

.pf-search-bar {
  width: 100%;
}
.add-after-right-border::after {
  width: 10px;
  content: "";
  position: absolute;
  z-index: 0;
  height: 21px;
  right: 0;
  bottom: 0;
  background-color: #FFFFFF;
  border-right: 1px solid #5B738B;
  box-shadow: none;
  overflow-y: hidden;
}
.add-after-border::after {
  width: 10px;
  content: "";
  position: absolute;
  z-index: 0;
  height: 21px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFFFFF;
  border-left: 1px solid #5B738B;
  box-shadow: none;
  overflow-y: hidden;
}
.form-control {
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  border-color: #5B738B;
  cursor: pointer;
}
.form-control:hover {
  border-color: #1B90FF;
}
.form-control:focus {
  border-color: #0040B0;
}
.pf-search-bar-container {
  display: flex;

  input {
      &:extend(.body-s-size);
      width         : 506px;
      height        : 32px;
      font-size     : 16px;
      color         : #223548;
  }

  .rotate-180 {
    display: block !important;
    transform: matrix(1,0,0,-1,0,0);
  }
  .new-ds-dropdown__inner__option {
    padding: 0.5rem 0.75rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
.ds-textField__input{
    min-height: 40px;
    height: 40px;
  }
  input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  .search__clear-btn:hover::after {
    position: absolute;
    right: 10px;
    content: "";
    display: block;
    font-family: "SAP.com Icons, sans-serif";
    font-size: 18px;
    transform: translate(0, -50%);
    color: #475E75;
  }
  .guide-dropdown {
    cursor: pointer;
    max-width: 170px !important;
    min-width: 150px !important;
  }

  .ds-combo-box__placeholder {
    width: fit-content;
  }
  .guide-dropdown__wrapper {
    cursor: pointer;
    z-index: 30;
  }
  .search-box-decline-icon {
  margin-top: 3px;
}
  #selectbox-search-bar {
    border-left:1px solid #5B738B;
    border-right:1px solid #5B738B;
    border-top:1px solid #5B738B;
    border-bottom:1px solid #5B738B;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    cursor: pointer;
    width: 100% !important;
    background-color: #FFFFFF;
    padding-left: 12px !important;
  }

  .guide-dropdown:hover #selectbox-search-bar {
    border-color: #1B90FF;
  }
  #selectbox-search-bar:hover {
    border-color: #1B90FF;
  }
  .guide-dropdown-focused-border {
    border-color: #0040B0 !important;
  }

  .home-filter-placeholder {
    &:extend(.body-s-size);
    font-size: 16px;
    line-height: 21px;
    background-color: transparent;
    color: #475E75;
    padding-left: 8px;
    position: absolute;
    top: 50%;
    left: 2%;
    transform: translateY(-50%);
  }
  .down-arrow {
    height: 18px;
    width: 18px;
    display:block !important;
    top: 12px;
  }
  .dropdown-item {
    min-width: 130px !important;
    max-width: 150px !important;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: #FFFFFF;
    border-left:1px solid #5B738B;
    border-right:1px solid #5B738B;
    border-bottom:1px solid #5B738B;
    margin-top: 0.1px;
    box-shadow: 0px 2px 3px 0px rgba(34,54,73,.3);
  }
  .dropdown-item:hover {
    background-color: #F5F6F7;
  }
  .option-text {
    width: 150px;
    height: 40px;
    font-weight: 390;
    font-size: 16px;
    line-height: 21px;
    &:extend(.body-s-size);
    // background-color: #3c3c3c;
    color: #475e75;
  }

  .top-search-bar{
    z-index: 1;
    position: relative;
    padding: 12px 70px 12px 12px;
  }
  .search-input:focus::placeholder {
  color: transparent !important;
}
  input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  input {
    border-width: 0;
    width: 100%;
    font-size: 16px;
    letter-spacing: normal;
    background-color: transparent;
    &:extend(.body-s-size);
    color: #223548;
  }

  input::placeholder {
    color: @color-gray-3;
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  button {
    cursor: pointer;
  }

  input:focus {
    outline: none;
  }

  p {
    margin: 0;
  }
}

.pf-search-bar-homepage-container {
  margin: 0 ;
  max-width: 490px ;

  .ds-textField__input {
    height: 46px ;
    width: 150px ;
  }
  .dropdown-item {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .home-filter-placeholder {
    font-size: 16px ;
    line-height: 24px ;
    padding-left: 12px ;
  }
  #selectbox-search-bar {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .search-box-decline-icon {
  margin-top: 0px;
}
.ds-select-box__wrapper__icon--down{
  margin-top:2px;
}
  .option-text {
    padding: 8px 14px ;
    line-height: 24px ;
  }
  .form-control {
    font-size: 16px ;
    line-height: 24px ;
    padding-left: 12px ;
    height: 46px ;
    border-radius: 0px 0px 0px 0px;
    border-left: none ;
  }
  .form-control::placeholder {
    color: #475E75 ;
  }
  .form-control:hover {
    cursor: text ;
  }
  .input-button-search button{
    height: 38px;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
@media (max-width: 639px) {
  .pf-search-bar-container {
     .home-filter-placeholder {
        &:extend(.body-xs-size);
        font-size: 14px;
     }
     input {
       &:extend(.body-xs-size);
        font-size: 14px;
     }
     .dropdown-item {
       max-width: 130px !important;
     }
     .option-text {
       width: 130px;
     }
     .guide-dropdown {
       min-width: 130px !important;
     }
  }
  .pf-search-bar-homepage-container {

    .guide-dropdown {
      min-width: 136px !important;
    }
    .dropdown-item {
      max-width: 136px !important;
    }
    .ds-select-box__wrapper__icon--down, .ds-select-box__wrapper__icon--up {
      right: 4px !important;
    }
  }
}

.pf-search-bar-homepage-outer-container{
  max-width: 1680px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
</style>
