import axios from 'axios';

const ROOT_ENDPOINT = '/sap/details/api/downtimes/active';

const SystemMaintenanceStore = {
  namespaced: true,
  state: {
    message: '',
    activeMaintenance: [],
    enableMaintenanceBanner: false,
  },
  actions: {
    async fetchActiveMaintenanceData(context) {
      await axios.get(ROOT_ENDPOINT)
        .then((result) => {
          context.commit('setActiveMaintenance', result.data);
          if (result.data.length > 0) {
            context.commit('setEnableMaintenanceBanner', true);
            const message = result.data[0].message;
            context.commit('setMessage', message);
          }
        });
    },
  },
  mutations: {
    setActiveMaintenance(context, val) {
      context.activeMaintenance = val;
    },
    setEnableMaintenanceBanner(context, val) {
      context.enableMaintenanceBanner = val;
    },
    setMessage(context, val) {
      context.message = val;
    },
  },
};

export default SystemMaintenanceStore;
