<script>
import { mapState } from 'vuex';
import PFForbiddenPage from '@/pages/PFForbiddenPage';
import '@sapudex/web-components/dist/Button.js';

const urlLinks = require('@/plugins/linkUrl');

export default {
  components: {
    PFForbiddenPage,
  },
  name: 'PFNotFoundPage',
  props: {
    mode: {
      type: String,
      default: '404',
    },
    isFromPfEditor: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    faqLink: urlLinks.FOOTER.FAQ_PF,
  }),
  methods: {
    sendEmail() {
      const subject = encodeURIComponent(this.$tc('help.mail.subject'));
      const emailBody = encodeURIComponent(this.$tc('help.mail.body'));
      const email = encodeURIComponent(this.$tc('help.mail.contact.email'));
      this.openInNewTab(`mailto:${email}?subject=${subject}&display-name=SAP&body=${emailBody}`);
    },
    sendEmailBackendError() {
      const subject = encodeURIComponent(this.$tc('help.mail.body.backend.error.subject'));
      const profileId = this.partnerProfile.profileId ? this.partnerProfile.profileId : 'N/A';
      const userId = this.profileAuthorizations?.userName ? this.profileAuthorizations.userName : 'N/A';
      const pageTitle = this.$route.name;
      const url = window.location.href;
      this.$sendEmail.constructor.sendEmailBackendError(subject, profileId, userId, pageTitle, url);
    },
    openInNewTab(url) {
      const win = window.open(url, '_blank');
      win.focus();
    },
    goToHomePage() {
      this.$router.push('/');
    },
  },
  computed: {
    ...mapState('partnerProfile', {
      partnerProfile: (state) => state.profileData,
    }),
    ...mapState('userProfile', {
      profileAuthorizations: (state) => state.user.profileAuthorizations,
    }),
  },
};
</script>

<template>
  <div class="not-found-page-container">

    <div  class="image-container">
      <img v-if="mode === '404' || mode === '400' || mode === '500' || mode === '503'
        || mode === 'networkError'" alt="" class="img" src="../assets/not-found.webp"/>
      <img v-if="mode === '401' || mode === '403'" alt="" class="img" src="../assets/not-allowed.webp"/>
      <img v-if="mode === 'systemMaintenance'" alt="" class="img" src="../assets/maintenance.webp"/>
      <img v-if="mode === 'browserNotSupported'" alt="" class="img" src="../assets/browser-not-supported.png"/>
    </div>

    <div v-if="mode === '404' || mode === '400'" class="header"> {{ $t('not.found.header') }}</div>
    <div v-if="mode === '401'" class="header"> {{ $t('not.authorized.header') }}</div>
    <div v-if="mode === '403'"><PFForbiddenPage :isFromPfEditor="isFromPfEditor"/></div>
    <div v-if="mode === '500'|| mode === '503'" class="header"> {{ $t('system.error.header') }}</div>
    <div v-if="mode === 'browserNotSupported'" class="header"> {{ $t('browser.not.supported.header') }}</div>
    <div v-if="mode === 'networkError'" class="header"> {{ $t('system.network.error.header') }}</div>
    <div v-if="mode === 'systemMaintenance'" class="header">{{ $t('system.maintenance.error.header') }}</div>

    <div v-if="mode === '404' || mode === '400'" class="text">{{ $t('not.found.text') }}</div>
    <div v-if="mode === '401'" class="text" v-html="$t('forbidden.default.text')"></div>
    <div v-if="mode === '500' || mode==='503'" class="text">{{$t('system.error.text')}}</div>
    <div v-if="mode === 'networkError'" class="text" v-html="$t('editor.footer.message.network.error')"></div>
    <div v-if="mode === 'systemMaintenance'" class="text" v-html="$t('system.maintenance.error.text')"></div>
    <div v-if="mode === 'browserNotSupported'" class="text" v-html="$t('browser.not.supported.text')"></div>

    <div class="button-container" slot="footer"
      v-if="mode !== 'browserNotSupported' && mode !== 'networkError' || mode === '500'
      || mode === '503' || mode === 'networkError'">
      <udex-button
        @click.prevent="goToHomePage()"
        design="Emphasized"
        size="Large"
        slot="endContent"
      >
        {{ $t('title.homepage') }}
      </udex-button>
      <udex-button
        @click.prevent="if (mode === '500' || mode==='503') sendEmailBackendError(); else sendEmail()"
        design="Default"
        size="Large"
        slot="endContent"
      >
        {{ $t('title.contact.us') }}
      </udex-button>
      <udex-button
        @click.prevent="openInNewTab(faqLink)"
        design="Transparent"
        size="Large"
        slot="endContent"
      >
        {{ $t('title.help') }}
      </udex-button>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../assets/css/common.less";

.not-found-page-container {
  width: 100%;
  padding-bottom: 60px;
  display: -moz-box;
  display: -webkit-box;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  -moz-box-align: center;
  -webkit-box-align: center;
  -moz-box-pack: start;
  -webkit-box-pack: start;
  text-align: center;
}

.image-container {
  margin-top: 60px;
  margin-bottom: 40px;
}

.img {
  width: 240px;
  height: 240px;
}

.header {
  font-family: @sapFontFamily;
  line-height: @udexTypographyHeadingLineHeight;
  font-size: @udexTypographyHeadingMediumXLFontSize;
  font-weight: @udexTypographyFontWeightMedium;
  color: @udexCoreHeadingDark;
  margin-bottom: 16px;
}

.text {
  font-size: 20px;
  font-family: "72 Brand Variable";
  font-weight: 400;
  line-height: 30px;
  color: @udexCoreTextDark;
  text-align: center;
  margin-bottom: 40px;
  max-width: 754px;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  text-align: center;
  line-height: 24px;
}

@media (max-width: 360px) {
  .not-found-page-container {
    padding-left: 24px;
    padding-right: 24px;
  }
  .image-container {
    margin-bottom: 24px;
  }
  .header {
    font-family: @sapFontFamily;
    line-height: @udexTypographyHeadingLineHeight;
    font-size: @udexTypographyHeadingMediumMFontSize;
    font-weight: @udexTypographyFontWeightMedium;
  }
  .text {
    font-size: @udexTypographyBodySFontSize;
    font-family: "72 Brand Variable";
    font-weight: @udexTypographyFontWeightRegular;
    line-height: 24px;
    margin-bottom: 32px;
  }
}

</style>
