<script>
import { mapState } from 'vuex';
import PFSiteLogoComponent from '@/components/common/PFSiteLogoComponent';
import PFHelpComponent from '@/components/common/PFHelpComponent';
import PFUserProfileComponent from '@/components/common/PFUserProfileComponent';
import PEChangelogComponent from '@/components/editor/popup/PEChangelogComponent';
import PEHeaderSmallComponent from '@/components/editor/PEHeaderSmallComponent';
import PEProfileProgressComponent from '@/components/editor/PEProfileProgressComponent';

export default {
  name: 'PEHeaderComponent',
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  components: {
    PFSiteLogoComponent,
    PFHelpComponent,
    PFUserProfileComponent,
    PEChangelogComponent,
    PEHeaderSmallComponent,
    PEProfileProgressComponent,
  },
  data: () => ({
    toggleChecked: true,
    changelogOpen: false,
    defaultHeader: true,
  }),
  methods: {
    handleScroll() {
      const headerBottom = document.getElementsByClassName('edit-header')[0]
        .offsetHeight;
      const logo = document.getElementsByClassName('edit-header--logo')[0];
      const progressBar = document.getElementsByClassName('progress-bar-container')[0];
      const helpIcon = document.getElementsByClassName(
        'pe-page-header__help-block',
      )[0];
      const userIcon = document.getElementsByClassName(
        'pe-page-header__profile-block',
      )[0];
      this.defaultHeader = window.scrollY <= 10;
      if (window.scrollY > headerBottom) {
        logo.style.display = 'block';
        helpIcon.style.visibility = 'visible';
        userIcon.style.visibility = 'visible';
        progressBar.style.display = 'none';
        logo.style.opacity = 0;
        logo.style.opacity = 1;
        userIcon.style.opacity = 1;
        helpIcon.style.opacity = 1;
      } else {
        logo.style.display = 'none';
        progressBar.style.display = 'block';
        helpIcon.style.visibility = 'hidden';
        userIcon.style.visibility = 'hidden';
        logo.style.opacity = 0;
        userIcon.style.opacity = 0;
        helpIcon.style.opacity = 0;
      }
    },
    switchToEditMode() {
      this.goToPath(`/editor/${this.$route.params.profileId}/edit`);
    },
    switchToPreviewMode() {
      this.goToPath(`/editor/${this.$route.params.profileId}/preview`);
    },
    goToPath(path) {
      if (this.isNotCurrentPath(path)) {
        this.$router.push({
          path,
        });
      }
    },
    isNotCurrentPath(path) {
      return !(this.$router.currentRoute.value.fullPath === path);
    },
    onToggleChange(event) {
      if (event.target.checked) {
        this.switchToEditMode();
        setTimeout(() => {
          this.$eventBus.$emit('editorToggleButtonClicked');
        });
      } else {
        this.$eventBus.$emit('editorToggleButtonClicked');
        setTimeout(() => {
          this.switchToPreviewMode();
        });
      }
    },
    openProfile() {
      const route = this.$router.resolve({
        path: `/profile/${this.$route.params.profileId}`,
      });
      window.open(route.href, '_blank');
    },
    toggleChangelog() {
      const footer = document.getElementsByClassName('sticky-edit-edit-footer')[0];
      this.changelogOpen = !this.changelogOpen;
      if (this.changelogOpen) {
        footer.style.zIndex = '50';
      } else {
        footer.style.zIndex = '100';
      }
    },
  },
  mounted() {
    const url = this.$route.path;
    if (url.endsWith('edit')) {
      this.toggleChecked = true;
    } else if (url.endsWith('preview')) {
      this.toggleChecked = false;
    }
  },
  computed: {
    ...mapState('partnerProfile', {
      publishDate: (state) => state.profileData.publishDate,
      status: (state) => state.profileData.status,
    }),
    profileStatus() {
      if (this.publishDate) {
        return this.status === 'PUBLISHED'
          ? this.$t('editor.header.profile.status.published')
          : this.$t('editor.header.profile.status.draft');
      }
      return this.$t('editor.header.profile.status.not.published');
    },
    lastPublishDate() {
      if (this.publishDate) {
        const myDate = new Date(this.publishDate);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };

        const seconds = Math.floor((new Date() - myDate) / 1000);

        // larger than 7 days, show date, between 1 day and 7 days, show days ago
        let interval = seconds / 86400;
        if (interval >= 8) {
          return myDate.toLocaleString('en-US', options);
        } if (interval >= 2) {
          return (
            `${Math.floor(interval)
            } ${
              this.$t('editor.header.profile.published.days')}`
          );
        } if (interval >= 1) {
          return (
            `${Math.floor(interval)
            } ${
              this.$t('editor.header.profile.published.day')}`
          );
        }
        // between 24 hours and 1 hour, show hours ago
        interval = seconds / 3600;
        if (interval >= 2) {
          return (
            `${Math.floor(interval)
            } ${
              this.$t('editor.header.profile.published.hours')}`
          );
        } if (interval >= 1) {
          return (
            `${Math.floor(interval)
            } ${
              this.$t('editor.header.profile.published.hour')}`
          );
        }
        // between 1 hour and 1 minute, show minutes ago
        interval = seconds / 60;
        if (interval >= 1) {
          return (
            `${Math.floor(interval)
            } ${
              this.$t('editor.header.profile.published.minutes')}`
          );
        }
        // less than 1 minutes, show just now
        return this.$t('editor.header.profile.published.just.now');
      }
      return '';
    },
    formatLastPublishDate() {
      if (this.publishDate) {
        return this.status === 'PUBLISHED'
          ? `${this.$t('editor.header.profile.published.updated')
          } ${
            this.lastPublishDate}`
          : `${this.$t('editor.header.profile.last.published.date')
          } ${
            this.lastPublishDate}`;
      }
      return '';
    },
  },
  watch: {
    $route: {
      handler(to) {
        if (to.path.endsWith('edit')) {
          this.toggleChecked = true;
        } else if (to.path.endsWith('preview')) {
          this.toggleChecked = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<template>
  <div :class="['edit-header sticky-edit', defaultHeader ? '' : 'editer-header-grey-shadow']" id="editor-footer-bar">
    <div class="edit-header-grey-container">
      <div class="edit-header--logo">
        <PFSiteLogoComponent :editorHeader="true" class="ds-m-l-xl editor-site-logo-conatainer" />
      </div>
      <div class="edit-header--progress-bar">
        <PEProfileProgressComponent />
      </div>
      <div class="edit-header-tool-bar">
        <div class="edit-header-tool-bar-content">
          <div class="ds-toggle-button pe-toggle-button ds-m-t-m ds-m-b-m">
            <label
              class="ds-toggle-button-label pe-toggle-button-label ds-m-t-xs ds-m-b-xs"
            >
              <input
                class="ds-toggle-button__input"
                type="checkbox"
                :checked="toggleChecked"
                id="PE-header-toggle"
                @change="onToggleChange($event)"
              />
              <div
                class="ds-toggle-button__toggle pe-toggle-button__toggle ds-m-r-xs"
              ></div>
              <div class="ds-toggle-button__on pe-toggle-text font-normal">
                {{ $t('editor.header.mode.edit') }}
              </div>
              <div class="ds-toggle-button__off pe-toggle-text font-normal">
                {{ $t('editor.header.mode.preview') }}
              </div>
            </label>
          </div>
          <div class="pe-publish-status ds-m-t-m ds-m-b-m">
            <div class="pe-publish-status-label">
              <div class="publish-status">{{ profileStatus }}</div>
              <div class="modified-date">{{ formatLastPublishDate }}</div>
            </div>
          </div>
          <div class="profile-link ds-m-t-m ds-m-b-m">
            <div class="open-profile-link-block ds-m-r-l">
              <div
                v-show="publishDate"
                class="open-profile"
                @click="openProfile"
              >
                <i class="ds-icon ds-icon--external-link ds-m-r-xs"></i>
                <div class="open-profile-link">
                  {{ $t('editor.header.open.profile') }}
                </div>
              </div>
              <div
                v-show="publishDate"
                class="show-change-log"
                @click="toggleChangelog();
                $eventBus.$emit('PFCustomEvent',
                {eventName: 'ChangeHistory'})"
              >
                {{ $t('editor.header.show.changelog') }}
              </div>
              <PEChangelogComponent
                :toggleChangelog="toggleChangelog"
                :changelogOpen="changelogOpen"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="edit-header-icon-bar">
        <div class="pe-page-header__help-block">
          <PFHelpComponent :editorHeader="true" />
        </div>
        <div class="pe-page-header__profile-block">
          <PFUserProfileComponent :editorHeader="true" />
        </div>
      </div>
    </div>
    <PEHeaderSmallComponent />
  </div>
</template>

<style lang="less">
@import '../../assets/css/common.less';

 @media (min-width: 768px) {
   #editor-footer-bar {
     padding: 0 48px;
   }
 }
 @media (min-width: 980px) {
   #editor-footer-bar {
     padding: 0 72px;
   }
 }
 @media (min-width: 1600px) {
   #editor-footer-bar {
     padding: auto
   }
  }
.editer-header-grey-shadow {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16), 0px 0px 2px rgba(0, 0, 0, 0.16);
}

.edit-header {
  min-height: 64px;
  max-height: 64px;
  height: 64px;
  width: 100%;
  background-color: @color-editor-footer-background;
  justify-content: center;
  transition: height 1s, box-shadow 1s;

  .edit-header-grey-container {
    display: flex;
    width: 100%;
    height: 64px;
    min-height: 64px;
    max-width: 1432px;
    margin-left: auto;
    margin-right: auto;
  }
  .edit-header-tool-bar {
    width: 100%;
    .edit-header-tool-bar-content {
      height: 100%;
      max-width: 647px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
    }
  }
  .edit-header-icon-bar {
    display: flex;
    width: 332px;
  }
  .pe-publish-status {
    &:extend(.font-bold);
    color: @color-gray-1;
    flex: 1;
    min-width: 175px;
    font-size: 16px;
    line-height: 24px;
    cursor: default;
    .pe-publish-status-label {
      width: fit-content;
      margin: auto;
    }
    .modified-date {
      &:extend(.font-expanded);
      color: @color-gray-2;
      font-size: 12px;
      line-height: 18px;
    }
  }
  .profile-link {
    &:extend(.font-normal);
    color: @color-link;
    flex: 1;
    font-size: 16px;
    line-height: 24px;
    vertical-align: middle;
    .open-profile-link-block {
      width: fit-content;
      margin-left: auto;
      .open-profile {
        cursor: pointer;
      }
      .open-profile:hover {
        color: @color-link__hover;
      }
      .show-change-log {
        &:extend(.font-expanded);
        font-size: 12px;
        height: 18px;
        line-height: 18px;
        width: fit-content;
        cursor: pointer;
      }
      .show-change-log:hover {
        color: @color-link__hover;
      }
    }
    .open-profile-link {
      display: inline-block;
    }
  }
  .ds-icon--external-link::before {
    font-size: 18px;
    line-height: 29px;
  }
}
button {
  max-width: 144px;
}
.edit-button {
  display: inline-block;
  margin-right: 0;
  margin-left: auto;
  width: 144px;
}
.preview-button {
  display: inline-block;
  margin-left: 0;
  margin-right: auto;
  width: 144px;
}
.sticky-edit {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
}

.edit-header--logo {
  display: none;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s display 0.3s linear;
}
.edit-header--progress-bar {
  display: block;
  opacity: 1;
  transition: visibility 0.3s, opacity 0.3s display 0.3s linear;
}

.pe-toggle-button {
  &:extend(.ds-toggle-button);
  display: inline-block;
  vertical-align: middle;
  flex: 1;
  .pe-toggle-button-label {
    width: fit-content;
    min-width: 122px;
  }
}

.pe-toggle-button__toggle {
  &:extend(.ds-toggle-button__toggle);
  margin-left: 0;
}

.pe-toggle-text {
  color: @color-black;
}

.pe-page-header__help-block {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s linear;
  display: flex;
  margin-left: auto;
}
.pe-page-header__profile-block {
  visibility: hidden;
  transition: visibility 0.5s, opacity 0.5s linear;
  display: flex;
}
</style>
