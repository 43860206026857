<script>
import { mapState } from 'vuex';

const MAX_TEXTAREA_INPUT_LENGTH = 1500;
const MAX_LINES_SUMMARY_TEXT = 5;
const LINE_HEIGHT_SUMMARY_TEXT = 24;

export default {
  name: 'PFServiceValuePropComponent',
  props: {
    editMode: { type: Boolean },
  },
  data: () => ({
    isValidProp: true,
    isFocusedProp: false,
    maxChar: MAX_TEXTAREA_INPUT_LENGTH,
    errorId: 'serviceSummaryErr',
    isSummaryOverflow: true,
    showMoreToggle: true,
    numTruncatedLines: MAX_LINES_SUMMARY_TEXT,
  }),
  methods: {
    handleServicePropError(val) {
      if (val) {
        this.$store.dispatch('partnerProfile/removeErrorField', this.errorId);
      } else {
        this.$store.dispatch('partnerProfile/addErrorField',
          {
            id: this.errorId,
            field: this.$t('field.name.services.description'),
            message: this.$t('mandatory.field.error.message'),
          });
      }
    },
    truncateSummaryText() {
      const summaryArr = this.servicePropSummary.split('\n');
      const offsetWidth = this.$refs.summaryRef.offsetWidth;
      let currentLine = 0;
      let summaryIdx = 0;
      const paragraphNumLinesArr = [];

      // Calculate the number of lines for each paragraph and add to paragraphNumLinesArr
      while (currentLine < MAX_LINES_SUMMARY_TEXT && summaryIdx < summaryArr.length) {
        const tempDiv = document.createElement('div');
        tempDiv.style.width = `${offsetWidth}px`;
        tempDiv.classList.add('service-prop-summary', 'body-s-size');
        tempDiv.textContent = summaryArr[summaryIdx];
        document.body.appendChild(tempDiv);

        const numLines = tempDiv.offsetHeight / LINE_HEIGHT_SUMMARY_TEXT;
        paragraphNumLinesArr.push(numLines);

        currentLine += numLines === 0 ? 1 : numLines;
        summaryIdx += 1;
        tempDiv.remove();
      }

      this.numTruncatedLines = MAX_LINES_SUMMARY_TEXT;

      // Find the last non-empty line and set it as numTruncatedLines
      for (let i = paragraphNumLinesArr.length - 1; i >= 0; i--) {
        if (paragraphNumLinesArr[i] === 0) {
          this.numTruncatedLines -= 1;
        } else {
          break;
        }
      }

      // Create a temporary div to calculate the number of lines for the summary
      const tempDiv = document.createElement('div');
      const htmlContent = this.servicePropSummary.replace(/\n/g, '<br>');
      tempDiv.style.width = `${offsetWidth}px`;
      tempDiv.classList.add('service-prop-summary', 'body-s-size');
      tempDiv.innerHTML = htmlContent;
      document.body.appendChild(tempDiv);

      const numLines = tempDiv.offsetHeight / LINE_HEIGHT_SUMMARY_TEXT;
      tempDiv.remove();

      this.isSummaryOverflow = numLines > this.numTruncatedLines;
    },
  },
  mounted() {
    if (this.mountedComponent.includes(this.$options.name)) {
      this.isValidProp = this.servicePropSummary && this.servicePropSummary !== '';
    }
    this.$eventBus.$on('*', (type) => {
      if (['footerSaveClicked', 'footerPublishClicked', 'editorToggleButtonClicked'].includes(type)) {
        this.isValidProp = this.servicePropSummary && this.servicePropSummary !== '';
      }
    });
    this.$eventBus.$on('serviceSummaryErr', () => {
      this.isValidProp = false;
    });
    this.$nextTick(() => {
      if (this.servicePropSummary && !this.editMode) {
        this.truncateSummaryText();
      }
    });
  },
  computed: {
    ...mapState('partnerProfile', { mountedComponent: (state) => state.mountedComponent }),
    servicePropSummary: {
      get() {
        return this.$store.state.partnerProfile.profileData.serviceSummary;
      },
      set(newProposition) {
        this.$store.dispatch('partnerProfile/updateServiceValuePropositionSummary', newProposition);
      },
    },
    charLeft() {
      if (this.servicePropSummary) {
        return this.maxChar - this.servicePropSummary.length;
      }
      return this.maxChar;
    },
    getSummaryText() {
      if (this.servicePropSummary && this.servicePropSummary.length > 0) {
        return this.$sanitize(this.servicePropSummary);
      }
      return '';
    },
  },
  watch: {
    servicePropSummary(val, oldVal) {
      if (this.mountedComponent.includes(this.$options.name)) {
        this.isValidProp = val && val !== '';
      } else {
        this.$store.dispatch('partnerProfile/addMountedComponent', this.$options.name);
      }
      if (val !== oldVal && val !== '' && !this.editMode) {
        this.truncateSummaryText();
      }
    },
    isValidProp(val) {
      this.handleServicePropError(val);
    },
  },
};
</script>

<template>
  <div class="service-prop-container default-cursor">
    <div v-show="editMode">
      <div class="ds-textarea service-prop-textarea ds-m-t-l">
        <label
          class="ds-textField ds-required-textfield ds-m-none"
          :class="isValidProp || isFocusedProp ? '' : 'ds-textField--error'">
          <textarea
            class="ds-textField__input ds-textField__input--textarea service-textarea"
            type="text"
            :spellcheck="true"
            :placeholder="$t('helptext.service.valueProp.edit.placeholder')"
            :maxlength="maxChar"
            @focus="isFocusedProp = true"
            @blur="isFocusedProp = false"
            v-model="servicePropSummary"/>
          <div class="ds-textField__message" v-if="!isValidProp && !isFocusedProp">
            <i class="ds-icon--close-outline ds-p-r-xs"></i>
            {{ $t("mandatory.field.error.message") }}
          </div>
        </label>
        <div class="char-left ds-m-t-xxs">
          {{ charLeft }} {{ $tc("editor.global.characters.left", charLeft) }}
        </div>
      </div>
    </div>
    <div v-show="!editMode">
      <div class="service-prop-summary body-s-size" ref="summaryRef">
        <resize-observer @notify="this.truncateSummaryText"></resize-observer>
        <div
          id="service-summary-content"
          :class="{ 'service-summary-truncated': showMoreToggle }"
          :style="{ '-webkit-line-clamp': numTruncatedLines }"
          v-html="getSummaryText" />
        <span v-show="isSummaryOverflow">
          <a
            class="show-more-link"
            href=""
            @click.prevent="showMoreToggle = !showMoreToggle">
            {{ showMoreToggle ? $t('title.brand.names.more') : $t('show.less.text') }}
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "../../assets/css/common.less";

.service-prop-summary {
  white-space: pre-wrap;
  width: 100%;
}

.service-prop-textarea {
  & > label textarea.ds-textField__input--textarea {
    min-height: 168px;
  }
  .ds-textField__message {
    &:extend(.font-normal);
    font-size: 14px;
    .ds-icon--close-outline {
      line-height: 17px;
    }
  }
}

.char-left {
  &:extend(.font-normal);
  height: 18px;
  font-size: 12px;
  line-height: 1.5;
  text-align: right;
  color: @color-glance-consultants;
}

.service-textarea {
  resize: vertical;
}

.service-summary-truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.show-more-link {
  color: @sapLinkColor;
}
.show-more-link:hover {
  color: @sapLink_Hover_Color;
}
</style>
