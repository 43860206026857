import axios from 'axios';
import EncodeSearchTerm from '@/plugins/search/encodeSearchTerm';
import router from '../../router/routes';
import responseHandlerUtil from '../../plugins/search/responseHandler';

const sortOptions = require('@/plugins/search/sortOptions');

const partnerNavigatorPage = 'partnerNavigatorPage';
const PRODUCTS = 'products';
const ALWAYS_REDIRECT_TO_PARTNER = false;
const DEFAULT_REQUEST_TYPE = 'results';
const GUIDED_REQUEST_TYPE = 'results/count';
const DEFAULT_QUERY_TERM_TYPE = 'partner';
const KEYWORD_QUERY_TYPE = 'keyword';
const USE_BM_SEARCH = true;

function sortFilterOptions(a, b) {
  if (a.isSelected === true && b.isSelected !== true) {
    return -1;
  }
  if (a.isSelected !== true && b.isSelected === true) {
    return 1;
  }
  if (a.title < b.title) {
    return -1;
  }
  if (a.title > b.title) {
    return 1;
  }
  return 0;
}

function changeVisibilityOfFilterOption(option, text) {
  if (text.length === 0 || (option.optionType === 'location' && option.isSelected)) {
    option.isVisible = true;
    return;
  }
  const loweCaseTitle = option.title.toLowerCase();
  if (option.description) {
    const loweCaseDescription = option.description.toLowerCase();
    option.isVisible = loweCaseTitle.includes(text.toLowerCase()) || loweCaseDescription.includes(text.toLowerCase());
    return;
  }
  option.isVisible = loweCaseTitle.includes(text.toLowerCase());
}
function isSelectedOptionsNotEmpty(selectedOptions) {
  const currentSelectedOptions = Object.keys(selectedOptions)
    .filter((key) => selectedOptions[key].length > 0);
  return currentSelectedOptions.length > 0;
}
function isSelectedOptionsNotEmptyBesidesLocation(selectedOptions) {
  const currentSelectedOptions = Object.keys(selectedOptions)
    .filter((key) => key !== 'location' && selectedOptions[key].length > 0);
  return currentSelectedOptions.length > 0;
}
const buildFilterQueryParameter = (key, selectedOptions) => `${key}:${selectedOptions[key].map((filter) => {
  const title = filter.title;
  return title.includes('&') ? encodeURIComponent(title) : title;
}).join(`;${key}:`)}`;

const triggerSearch = (query, context, requestType, sorting, autocomplete) => {
  let url = '';
  if (USE_BM_SEARCH) {
    url = `/sap/search/api/search/bm/${requestType}?q=${query}&pageSize=${context.state.pageSize}&pageNumber=${
      context.state.pageNumber - 1
    }`;
  } else {
    url = `/sap/search/api/search/${requestType}?q=${query}&pageSize=${context.state.pageSize}&pageNumber=${
      context.state.pageNumber - 1
    }`;
  }
  if (sorting) {
    url = `${url}&order=${sorting}`;
  }
  if (autocomplete) {
    url = `${url}&suggestion=true`;
  }
  return axios.get(url);
};

const buildSearchQuery = (selectedOptions) => Object.keys(selectedOptions)
  .filter((key) => selectedOptions[key].length > 0)
  .map((key) => buildFilterQueryParameter(key, selectedOptions))
  .join(';');

const buildSearchQueryWithoutLocation = (selectedOptions) => Object.keys(selectedOptions)
  .filter((kye) => kye !== 'location')
  .filter((key) => selectedOptions[key].length > 0)
  .map((key) => buildFilterQueryParameter(key, selectedOptions))
  .join(';');

const toQuery = (query) => query || '';

function createQuery(encodedQuery, selectedOptions, queryTermType) {
  let queryToReturn = toQuery(encodedQuery);
  queryToReturn = queryToReturn.concat(`&qField=${queryTermType}`);
  if (isSelectedOptionsNotEmpty(selectedOptions)) {
    queryToReturn = queryToReturn.concat('&filter=') + buildSearchQuery(selectedOptions);
  }
  return queryToReturn;
}

function createUpdateLocationsQuery(encodedQuery, selectedOptions, value) {
  if (isSelectedOptionsNotEmpty(selectedOptions)) {
    return encodedQuery.concat('&filter=') + buildSearchQuery(selectedOptions).concat(';');
  }
  return value ? encodedQuery.concat('&filter=') : '';
}

const PartnerProfilesSearch = {
  namespaced: true,
  state: {
    pathChangedFromUiFlag: false,
    query: null,
    queryTermTypeState: DEFAULT_QUERY_TERM_TYPE,
    searchResults: [],
    countResults: 0,
    pageNumber: 1,
    countPages: 1,
    pageSize: 12,
    selectedSortingOptionText: Object.keys(sortOptions.SORT_OPTIONS)[0],
    sorting: sortOptions.SORT_OPTIONS[Object.keys(sortOptions.SORT_OPTIONS)[0]],
    lastUsedQuery: '',
    distributions: [],
    filterOptions: {
      products: {
        active: [],
        disabled: [],
      },
      industry: {
        active: [],
        disabled: [],
      },
      engagement: {
        active: [],
        disabled: [],
      },
      location: {
        active: [],
        disabled: [],
      },
      designation: {
        active: [],
        disabled: [],
      },
      isCompetency: {
        active: [],
        disabled: [],
      },
    },
    loading: false,
    selectedOptions: {
      products: [],
      industry: [],
      engagement: [],
      location: [],
      title: [],
      designation: [],
      isCompetency: [],
    },
    optionDisplayTitles: { gws: 'GROW with SAP Partner', rise: 'RISE with SAP Validated Partner', true: 'Cloud Competency' },
    filterSearchText: null,
    prevQuery: '',
    alwaysRedirectToPartner: ALWAYS_REDIRECT_TO_PARTNER,
    searchPartner: false,
    triggeredQuery: '',
    setSolutionFromHomepage: false,
    searchFromHomePage: false,
    emitNoResults: 0,
    emitTrackResults: 0,
    resultAPILocationDistribution: null,
    locationFilterQuery: null,
  },
  actions: {
    async findResults(context, searchType = DEFAULT_REQUEST_TYPE) {
      const queryTermType = context.state.queryTermTypeState ? context.state.queryTermTypeState : DEFAULT_QUERY_TERM_TYPE;
      context.commit('setLoading', true);
      if (router.currentRoute.value.name !== 'home') {
        context.dispatch('autocomplete/setShowSearchSuggestion', false, { root: true });
      }
      const encodedQuery = EncodeSearchTerm.searchTermEncode(context.state.triggeredQuery);
      const query = createQuery(encodedQuery, context.state.selectedOptions, queryTermType);
      const sorting = sortOptions.SORT_MAPPING[context.state.sorting];
      const autocomplete = context.rootState.autocomplete.searchSuggestion;
      context.commit('setLastUsedQuery', context.state.triggeredQuery);
      await triggerSearch(query, context, searchType, sorting, autocomplete)
        .then((result) => {
          context.dispatch('handleResponse', { data: result.data });
          if (result.data.count === 0 && searchType == DEFAULT_REQUEST_TYPE) {
            context.commit('setEmitNoResults');
          }
          context.commit('setEmitTrackResults');
        })
        .catch((err) => {
          console.log(err);
          throw new Error(`Response error: ${err}`);
        });
    },
    async cleanFilterByType(context, type) {
      context.dispatch('routerUncheckAllOptionsByType', type);
      await context.commit('cleanFilterByType', type);

      context.dispatch('findResults', DEFAULT_REQUEST_TYPE);
    },
    async setResults(context, payload) {
      await context.commit('updateSearchResults', payload.results.results);
      await context.commit('updateCountResults', payload.results.count);
      await context.commit('updateResultAPILocationDistribution', payload.results.distributions.LOCATION);
      await context.commit('updatePageCount', payload.results.count);
      await context.commit('updateDistributions', payload.results.distributions);
    },
    setLastUsedQuery(context, query) {
      context.commit('setLastUsedQuery', query);
    },

    setPathChangedFromUiFlag(context, value) {
      context.commit('setPathChangedFromUiFlag', value);
    },
    async restoreSelection(context) {
      const urlParameters = new URLSearchParams(window.location.search);
      context.commit('cleanFilters');
      // clean solutions
      context.dispatch('solutionL2Store/clearSelectedSolution', {}, { root: true });

      // restore page
      const pageNumber = urlParameters.get('page') || 1;
      const page = Number.isNaN(Number.parseInt(pageNumber, 10)) ? 1 : Number.parseInt(pageNumber, 10);
      context.commit('setPageNumber', page);

      // restore query
      context.commit('setQuery', urlParameters.get('q') || '');
      context.commit('changeTriggeredQuery', urlParameters.get('q'));
      context.commit('autocomplete/newValue', urlParameters.get('q') || '', { root: true });
      context.commit('autocomplete/setResults', [], { root: true });

      // restore sorting
      const sortOption = Object.entries(sortOptions.SORT_OPTIONS)
        // eslint-disable-next-line no-unused-vars
        .find(([key, value]) => value === urlParameters.get('sorting'));
      if (sortOption) {
        context.commit('setSelectedSortingOptionText', sortOption[0]);
        context.commit('setSorting', sortOption[1]);
      } else {
        context.commit('setSelectedSortingOptionText', Object.keys(sortOptions.SORT_OPTIONS)[0]);
        context.commit('setSorting', sortOptions.SORT_OPTIONS[Object.keys(sortOptions.SORT_OPTIONS)[0]]);
      }

      // restore industries
      (urlParameters.getAll('industry') || []).forEach((industry) => {
        const value = {
          title: industry,
          isSelected: true,
        };
        context.commit('addSelectedOption', {
          optionType: 'industry',
          value,
        });
      });

      // restore solutions
      (urlParameters.getAll('products') || []).forEach((product) => {
        const value = {
          title: product,
          isSelected: true,
        };
        context.commit('addSelectedOption', {
          optionType: 'products',
          value,
        });
      });
      if (
        !context.rootState.solutionL2Store.buildInitSolutionFlag
        && context.rootState.solutionL2Store.titleL2Mapping.size
        && context.rootState.solutionL2Store.reverseL2L1Mapping.size
        && context.rootState.solutionL2Store.solutionDataMap.size) {
        context.dispatch('solutionL2Store/restoreSelectedSolution', {}, { root: true });
      }
      // restore RwS GwS
      (urlParameters.getAll('designation') || []).forEach((item) => {
        const value = {
          title: item,
          isSelected: true,
          designationDescr: item === 'rise' ? 'RISE with SAP' : 'GROW with SAP',
        };
        context.commit('addSelectedOption', {
          optionType: 'designation',
          value,
        });
      });

      // restore business models
      (urlParameters.getAll('engagement') || []).forEach((businessModel) => {
        const value = {
          title: businessModel,
          isSelected: true,
        };
        context.commit('addSelectedOption', {
          optionType: 'engagement',
          value,
        });
      });

      // restore location
      (urlParameters.getAll('location') || []).forEach((location) => {
        const value = {
          title: location,
          isSelected: true,
        };
        context.commit('addSelectedOption', {
          optionType: 'location',
          value,
        });
      });
      // restore query type
      context.commit('updateQueryTypeState', urlParameters.get('queryType') || '');

      // restore isCompetency
      if (urlParameters.get('isCompetency') === 'true') {
        const value = {
          title: 'true',
          isSelected: true,
        };
        context.commit('addSelectedOption', {
          optionType: 'isCompetency',
          value,
        });
      }

      if (router.currentRoute.value.name === 'home') {
        await context.dispatch('findResults', GUIDED_REQUEST_TYPE);
      } else {
        await context.dispatch('findResults');
      }
      if (context.state.searchFromHomePage) {
        context.commit('setSearchFromHomePage', false);
      }
    },
    async handleResponse(context, data) {
      if (!responseHandlerUtil.handleResponse(context, data, router)) {
        await context.dispatch('setResults', { results: data.data });
        await context.commit('updateIsCompetencyGreyOut', data.data);
        setTimeout(async () => {
          await context.dispatch('solutionL2Store/updateSolutionAfterSearch', data.data.distributions.PRODUCTS, { root: true });
          await context.dispatch('updateLocations', '');
          await context.commit('updateFilterDistributionsByType', 'industry');
          await context.commit('updateFilterDistributionsByType', 'engagement');
          await context.commit('updateDesignationGreyOut', data.data);
          context.commit('setLoading', false);
        }, '0');
      }
    },
    async updateLocations(context, value) {
      context.commit('setLoading', true);
      // context.commit('showAllCachedLocaiton');
      context.commit('setLocation', { results: context.state.resultAPILocationDistribution });
      context.commit('updateDisabledLocations');
      context.commit('updateLocationsToActiveOptions', context.state.locationFilterQuery ?? '');
      // context.commit('setLocationVisibility', context.state.locationFilterQuery ?? '');
    },
    addSelectedOptions(context, options) {
      options.options.forEach((option) => {
        context.commit('addSelectedOption', {
          optionType: option.optionType,
          value: option.value,
        });
      });
      context.dispatch('routerAddSelectedOptions', { options: options.options });
      context.commit('setPageNumber', 1);
      if (router.currentRoute.value.name === 'home') {
        context.dispatch('findResults', GUIDED_REQUEST_TYPE);
      } else {
        context.dispatch('findResults');
      }
    },
    addSelectedOption(context, {
      optionType,
      value,
      searchType = '',
    }) {
      context.commit('addSelectedOption', {
        optionType,
        value,
      });
      context.dispatch('routerAddSelectedOption', {
        value,
        optionType,
      });
      context.commit('setPageNumber', 1);
      if (router.currentRoute.value.name === 'home' && searchType !== 'autoComplete') {
        context.dispatch('findResults', GUIDED_REQUEST_TYPE);
      } else {
        context.dispatch('findResults');
      }
    },
    triggerSelectedQueryType(context) {
      context.dispatch('routerChangeSelectedQueryType');
      context.commit('setPageNumber', 1);
      context.dispatch('findResults');
    },
    cleanFilters(context) {
      const query = { ...router.currentRoute.value.query };
      if (context.state.triggeredQuery || query.sorting) {
        const queryObject = { q: context.state.triggeredQuery };
        if (query.sorting) {
          queryObject.sorting = query.sorting;
        }
        context.dispatch('routerPushNavigatorPage', queryObject);
      } else {
        context.dispatch('routerPushNavigatorPage', {});
      }
      context.commit('cleanFilters');
      // add clean solution filter
      context.dispatch('solutionL2Store/clearSelectedSolution', {}, { root: true });
      context.commit('removeAllSelectedOptionByType', 'products');

      context.commit('setPageNumber', 1);
      context.dispatch('findResults');
    },
    async uncheckOption(context, value) {
      const optionType = value.optionType;
      context.dispatch('routerUncheckOption', value);
      context.commit('removeSelectedOption', {
        optionType,
        value,
      });
      context.commit('setPageNumber', 1);
      if (router.currentRoute.value.name === 'home') {
        await context.dispatch('findResults', GUIDED_REQUEST_TYPE);
        // context.commit('updateFilterDistributionsByType', optionType);
      } else {
        await context.dispatch('findResults');
      }
    },
    async uncheckOptions(context, value) {
      // call this when uncheck solution
      // value is {options: Array<Object<optionType, value:{title, isSelected}>>}
      const optionType = value.options[0].optionType;

      context.dispatch('routerUncheckOptions', { optionType, value: value.options });
      value.options.forEach((item) => {
        context.commit('removeSelectedOption', {
          optionType,
          value: item.value,
        });
      });

      context.commit('setPageNumber', 1);
      if (router.currentRoute.value.name === 'home') {
        await context.dispatch('findResults', GUIDED_REQUEST_TYPE);
      } else {
        await context.dispatch('findResults');
      }
    },
    async uncheckAllOptionsByType(context, { type, updateSolution }) {
      context.dispatch('routerUncheckAllOptionsByType', type);
      context.commit('removeAllSelectedOptionByType', type);

      if (updateSolution) {
        context.commit('setPageNumber', 1);
        if (router.currentRoute.value.name === 'home') {
          await context.dispatch('findResults', GUIDED_REQUEST_TYPE);
        } else {
          await context.dispatch('findResults');
        }
      }
    },
    setQuery(context, query) {
      context.commit('setQuery', query);
      context.commit('setPageNumber', 1);

      context.commit('setSelectedSortingOptionText', Object.keys(sortOptions.SORT_OPTIONS)[0]);
      context.commit('setSorting', sortOptions.SORT_OPTIONS[Object.keys(sortOptions.SORT_OPTIONS)[0]]);
      context.dispatch('routerSetSortingWithQuery', { sorting: sortOptions.SORT_OPTIONS[Object.keys(sortOptions.SORT_OPTIONS)[0]], currentQuery: query });

      context.dispatch('findResults');
    },
    routerAddSelectedOptions(context, options) {
      const searchParams = new URLSearchParams(window.location.search);
      const query = JSON.parse(JSON.stringify(router.currentRoute.value.query));
      options.options.forEach((option) => {
        const addedSelection = searchParams.getAll(option.optionType);
        addedSelection.push(option.value.title);
        if (query[option.optionType]) {
          Array.isArray(query[option.optionType])
            ? query[option.optionType].push(option.value.title)
            : query[option.optionType] = Array.of(query[option.optionType], option.value.title);
        } else {
          query[option.optionType] = Array.of(option.value.title);
        }
      });
      delete query.page;
      context.dispatch('routerPushNavigatorPage', query);
    },
    routerChangeSelectedQueryType(context) {
      const query = JSON.parse(JSON.stringify(router.currentRoute.value.query));
      query.queryType = context.state.queryTermTypeState ? context.state.queryTermTypeState : DEFAULT_QUERY_TERM_TYPE;
      delete query.page;
      context.dispatch('routerPushNavigatorPage', query);
    },
    homeSearchAddRouterQuery(context, { query, guidedSearch }) {
      // if is guidesearch, then won't change page
      const currentQuery = JSON.parse(JSON.stringify(router.currentRoute.value.query));
      currentQuery.q = query;
      currentQuery.queryType = context.state.queryTermTypeState ? context.state.queryTermTypeState : DEFAULT_QUERY_TERM_TYPE;
      delete currentQuery.page;
      if (guidedSearch) {
        router.push({
          name: 'home',
          query: currentQuery,
        }).catch(() => {});
        context.dispatch('findResults', GUIDED_REQUEST_TYPE);
      } else {
        context.commit('setPathChangedFromUiFlag', false);
        router.push({
          name: partnerNavigatorPage,
          query: currentQuery,
        }).catch(() => {});
      }
    },
    routerAddSelectedOption(context, {
      value,
      optionType,
    }) {
      context.dispatch('routerAddSelectedOptions', {
        options: Array.of({
          value,
          optionType,
        }),
      });
    },
    routerUncheckOption(context, value) {
      const query = { ...router.currentRoute.value.query };
      // if only one option from filter type is selected, then it's stored as object, otherwise it's stored as array
      if (Array.isArray(query[value.optionType])) {
        query[value.optionType] = query[value.optionType].filter((val) => val !== value.title);
      } else {
        delete query[value.optionType];
      }
      delete query.page;
      context.dispatch('routerPushNavigatorPage', query);
    },
    routerUncheckOptions(context, value) {
      // value is {optionType, value: Array<Object<optionType, value:{title, isSelected}>>}
      const query = { ...router.currentRoute.value.query };
      const currentType = value.optionType;
      // if only one option from filter type is selected, then it's stored as object, otherwise it's stored as array
      if (Array.isArray(query[currentType])) {
        value.value.forEach((item) => {
          query[currentType] = query[currentType].filter((val) => val !== item.value.title);
        });
      } else {
        delete query[currentType];
      }
      delete query.page;
      context.dispatch('routerPushNavigatorPage', query);
    },
    routerUncheckAllOptionsByType(context, type) {
      const query = { ...router.currentRoute.value.query };
      delete query[type];
      delete query.page;
      context.dispatch('routerPushNavigatorPage', query);
    },
    routerPushNavigatorPage(context, query) {
      context.commit('setPathChangedFromUiFlag', true);
      if (router.currentRoute.value.name !== 'home') {
        router.push({
          name: partnerNavigatorPage,
          query,
        }).catch(() => {});
      } else {
        router.push({
          name: 'home',
          query,
        }).catch(() => {});
      }
    },
    routerSetQuery(context, q) {
      const currentQuery = JSON.parse(JSON.stringify(router.currentRoute.value.query));
      currentQuery.q = q;
      currentQuery.queryType = context.state.queryTermTypeState ? context.state.queryTermTypeState : DEFAULT_QUERY_TERM_TYPE;
      delete currentQuery.page;
      context.commit('setPathChangedFromUiFlag', false);
      router.push({
        name: partnerNavigatorPage,
        query: currentQuery,
      }).catch(() => {});
    },
    routerSetPage(context, pageNumber) {
      const query = { ...router.currentRoute.value.query };
      query.page = Number.isNaN(Number.parseInt(pageNumber, 10)) ? 1 : Number.parseInt(pageNumber, 10);
      context.dispatch('routerPushNavigatorPage', query);
    },
    routerSetSortingWithQuery(context, { sorting, currentQuery }) {
      const query = {};
      query.q = currentQuery;
      query.queryType = context.state.queryTermTypeState ? context.state.queryTermTypeState : DEFAULT_QUERY_TERM_TYPE;
      delete query.page;
      if (sorting === sortOptions.SORT_OPTIONS[Object.keys(sortOptions.SORT_OPTIONS)[0]]) {
        delete query.sorting;
      } else {
        query.sorting = sorting;
      }
      context.dispatch('routerPushNavigatorPage', query);
    },
    routerSetSorting(context, sorting) {
      const query = { ...router.currentRoute.value.query };
      delete query.page;
      if (sorting === sortOptions.SORT_OPTIONS[Object.keys(sortOptions.SORT_OPTIONS)[0]]) {
        delete query.sorting;
      } else {
        query.sorting = sorting;
      }
      context.dispatch('routerPushNavigatorPage', query);
    },
    setSelectedSortingOptionText(context, text) {
      context.commit('setSelectedSortingOptionText', text);
    },
    setQueryWithoutSearch(context, query) {
      context.commit('setQuery', query);
    },
    nextPage(context) {
      if (context.state.pageNumber < context.state.countPages) {
        context.commit('setPageNumber', context.state.pageNumber + 1);
        const query = { ...router.currentRoute.value.query };
        query.page = context.state.pageNumber;
        context.dispatch('routerPushNavigatorPage', query);
        context.dispatch('findResults');
      }
    },
    prevPage(context) {
      if (context.state.pageNumber > 1) {
        context.commit('setPageNumber', context.state.pageNumber - 1);
        const query = { ...router.currentRoute.value.query };
        query.page = context.state.pageNumber;
        context.dispatch('routerPushNavigatorPage', query);
        context.dispatch('findResults');
      }
    },
    setSolution(context, solutionId) {
      context.commit('cleanResults');
      context.commit('cleanFilters');

      const distributions = [
        {
          id: 'PFESC',
          mapping: [{ title: 'Digital Supply Chain' }],
        },
        {
          id: 'PFERPDC',
          mapping: [{ title: 'ERP and SAP S/4HANA' }, { title: 'Small and Midsize Enterprise' }],
        },
        {
          id: 'PFCEC',
          mapping: [{ title: 'Customer Experience' }],
        },
        {
          id: 'PFTRAVEL',
          mapping: [{ title: 'Procurement' }],
        },
        {
          id: 'PFHCM',
          mapping: [{ title: 'Human Resources' }, { title: 'Education' }],
        },
        {
          id: 'PFANA',
          mapping: [{ title: 'Application Development and Integration' }, { title: 'Analytics' }, { title: 'Intelligent Technologies' }, { title: 'Database and Data Management' }],
        },
      ];
      const solution = distributions.find((value) => value.id === solutionId);
      if (solution) {
        const options = solution.mapping.map((item) => ({
          optionType: PRODUCTS,
          value: item,
        }));
        context.dispatch('addSelectedOptions', { options });
      }
    },
    goToPage(context, pageNumber) {
      const query = { ...router.currentRoute.value.query };
      query.page = pageNumber;

      context.dispatch('routerPushNavigatorPage', query);
      context.commit('setPageNumber', pageNumber);
      context.dispatch('findResults');
    },
    setSorting(context, sorting) {
      context.dispatch('routerSetSorting', sorting);
      context.commit('setSorting', sorting);
      context.commit('setPageNumber', 1);
      context.dispatch('findResults');
    },
    sortAndUpdateActiveOptionsLocations(context, query) {
      context.commit('sortAndUpdateActiveOptionsLocations', query);
    },
    setLocationVisibility(context, query) {
      context.commit('setLocationVisibility', query);
    },
  },
  mutations: {
    updateQueryTypeState(context, val) {
      context.queryTermTypeState = val;
    },
    showAllCachedLocaiton(context) {
      context.resultAPILocationDistribution.forEach((item) => {
        item.isVisible = true;
      });
    },
    updateResultAPILocationDistribution(context, val) {
      context.resultAPILocationDistribution = val;
    },
    setLocationFilterQuery(context, val) {
      context.locationFilterQuery = val;
    },
    sortAndUpdateActiveOptionsLocations(context, query) {
      const filterType = 'location';
      if (!query) {
        const currFilterOptionsTitles = context.filterOptions[filterType].active.map((option) => option.title);
        context.selectedOptions[filterType].forEach((option) => {
          if (!currFilterOptionsTitles.includes(option.title)) {
            context.filterOptions[filterType].active.push(option);
          }
        });
      }
      context.filterOptions[filterType].active.sort(sortFilterOptions);
    },
    deleteActiveLocation(context, title) {
      context.filterOptions.location.active = context.filterOptions.location.active.filter((option) => option.title !== title);
    },
    updateLocationsToActiveOptions(context, query) {
      const filterType = 'location';
      const upperFilterType = filterType.toUpperCase();
      const filterDistributions = context.distributions[upperFilterType];
      if (!query) {
        const distributionLocationTitiles = context.distributions[upperFilterType].map((option) => option.title);
        context.selectedOptions[filterType].forEach((option) => {
          if (!distributionLocationTitiles.includes(option.title)) {
            filterDistributions.push(option);
          }
        });
      }
      let activeSelected = [];
      let activeNotSelected = [];
      if (context.selectedOptions[filterType]) {
        activeSelected = filterDistributions.filter(
          (option) => option
            && option.count !== 0
            && context.selectedOptions[filterType].filter((selOption) => selOption.title === option.title)
              .length === 1,
        );
        activeSelected.forEach((option) => {
          option.isSelected = true;
        });
        activeNotSelected = filterDistributions.filter(
          (option) => option
            && option.count !== 0
            && context.selectedOptions[filterType].filter((selOption) => selOption.title === option.title)
              .length === 0,
        );
      } else {
        activeNotSelected = filterDistributions.filter(
          (option) => option && option.count !== 0,
        );
      }
      activeSelected.sort(sortFilterOptions);
      activeNotSelected.sort(sortFilterOptions);
      context.filterOptions[filterType].active = activeSelected.concat(activeNotSelected);
    },
    setPathChangedFromUiFlag(context, value) {
      context.pathChangedFromUiFlag = value;
    },
    updateSearchResults(state, results) {
      state.searchResults = results;
    },
    updateCountResults(state, count) {
      state.countResults = count;
    },
    updatePageCount(state, countRes) {
      const pageCount = Math.ceil(countRes / state.pageSize);
      state.countPages = pageCount === 0 ? 1 : pageCount;
    },
    updateDistributions(state, distributions) {
      state.distributions = distributions;
    },
    setLocation(context, { results }) {
      results.forEach((location) => {
        location.id = location.title;
      });
      context.distributions.LOCATION = results;
      context.loading = false;
    },
    updateDisabledLocations(context) {
      context.filterOptions.location.disabled = context.distributions.LOCATION.filter((l) => l.count === 0);
      context.filterOptions.location.disabled.sort(sortFilterOptions);
    },
    addSelectedOptions(context, options) {
      context.selectedOptions = options;
    },
    addSelectedOption(context, {
      optionType,
      value,
    }) {
      const options = context.selectedOptions[optionType];
      if (options.filter((v) => v.title === value.title).length === 0) {
        value.optionType = optionType;
        context.selectedOptions[optionType].push(value);
      }
    },
    removeSelectedOption(context, {
      optionType,
      value,
    }) {
      context.selectedOptions[optionType] = context.selectedOptions[optionType]
        .filter((v) => v.title !== value.title);
    },
    removeAllSelectedOptionByType(context, type) {
      context.selectedOptions[type] = [];
    },
    cleanFilters(context) {
      context.selectedOptions.products = [];
      context.selectedOptions.industry = [];
      context.selectedOptions.engagement = [];
      context.selectedOptions.designation = [];
      context.selectedOptions.isCompetency = [];
      context.selectedOptions.location.forEach((option) => {
        const selectedOption = context.filterOptions.location.active.filter((_option) => _option.title === option.title)[0];
        if (selectedOption) {
          selectedOption.isSelected = !selectedOption.isSelected;
        }
        if (option.title.includes(',')) {
          context.filterOptions.location.active = context.filterOptions.location.active.filter((_option) => _option.title !== option.title);
        }
      });
      context.selectedOptions.location = [];
      context.selectedOptions.title = [];
    },
    cleanFilterByType(context, val) {
      if (val !== 'location') {
        context.selectedOptions[val].forEach((option) => {
          const selectedOption = context.filterOptions[val].active.filter((_option) => _option.title === option.title)[0];
          if (selectedOption) {
            selectedOption.isSelected = !selectedOption.isSelected;
          }
        });
        context.selectedOptions[val] = [];
      } else {
        context.selectedOptions.location.forEach((option) => {
          const selectedOption = context.filterOptions.location.active.filter((_option) => _option.title === option.title)[0];
          if (selectedOption) {
            selectedOption.isSelected = !selectedOption.isSelected;
          }
          if (option.title.includes(',')) {
            context.filterOptions.location.active = context.filterOptions.location.active.filter((_option) => _option.title !== option.title);
          }
        });
        context.selectedOptions.location = [];
      }
    },
    uncheckAllLocation(context) {
      context.selectedOptions.location = [];
    },
    setLoading(context, value) {
      context.loading = value;
    },
    setQuery(context, query) {
      context.query = toQuery(query);
    },
    setSorting(context, sorting) {
      context.sorting = sorting;
    },
    setSelectedSortingOptionText(context, optionText) {
      context.selectedSortingOptionText = optionText;
    },
    setLastUsedQuery(context, query) {
      context.lastUsedQuery = query;
    },
    setLocationVisibility(context, searchQuery) {
      const optionVisible = (option) => {
        option.isVisible = searchQuery.length === 0 || option.title.toLowerCase().startsWith(searchQuery.toLowerCase());
      };
      context.filterOptions.location.active.forEach(optionVisible);
      context.filterOptions.location.disabled.forEach(optionVisible);
    },
    setFilterSearchText(context, {
      text,
      filterType,
    }) {
      context.filterOptions[filterType.toLowerCase()]
        .active.forEach((option) => changeVisibilityOfFilterOption(option, text));
      context.filterOptions[filterType.toLowerCase()]
        .disabled.forEach((option) => changeVisibilityOfFilterOption(option, text));
    },
    setPageNumber(context, pageNumber) {
      context.pageNumber = pageNumber;
    },
    updateDesignationGreyOut(context, resultData) {
      // refresh the greyed out status after get search result
      const filterType = 'designation';
      const RwSExist = resultData.riseExists === 1;
      const GwSExist = resultData.gwsExists === 1;
      const RwSOption = {
        title: 'rise',
        designationDescr: 'RISE with SAP Validated Partner',
        description: 'Proven credentials in executing large or complex projects, an implementation methodology and collaboration model optimized for RISE with SAP',
      };
      const GwSOption = {
        title: 'gws',
        designationDescr: 'GROW with SAP Partner',
        description: 'A unique recognition awarded to select partners that demonstrate continuous commitment and expertise in GROW with SAP and SAP Business Technology Platform',
      };
      let activeArr = [];
      const disableArr = [];
      context.selectedOptions[filterType].find((item) => item.title === 'rise')
        ? RwSOption.isSelected = true : RwSOption.isSelected = false;
      context.selectedOptions[filterType].find((item) => item.title === 'gws')
        ? GwSOption.isSelected = true : GwSOption.isSelected = false;
      RwSExist ? activeArr.push(RwSOption) : disableArr.push(RwSOption);
      GwSExist ? activeArr.push(GwSOption) : disableArr.push(GwSOption);
      if (RwSExist && GwSExist && !RwSOption.isSelected && GwSOption.isSelected) {
        activeArr = [GwSOption, RwSOption];
      }
      context.filterOptions[filterType].active = activeArr;
      context.filterOptions[filterType].disabled = disableArr;
    },
    updateIsCompetencyGreyOut(context, resultData) {
      const filterType = 'isCompetency';
      const competencyExist = resultData.competencyExists === 1;
      context.filterOptions[filterType].active = competencyExist ? [{ title: 'true', isSelected: true }] : [];
      context.filterOptions[filterType].disabled = competencyExist ? [] : [{ title: 'true', isSelected: false }];
    },
    updateFilterDistributionsByType(context, filterType) {
      const upperFilterType = filterType.toUpperCase();
      let filterDistributions = [];
      filterDistributions = filterDistributions.concat(context.distributions[upperFilterType]);
      let activeSelected = [];
      let activeNotSelected = [];
      let disabled = [];
      if (context.selectedOptions[filterType]) {
        activeSelected = filterDistributions.filter(
          (option) => option
            && option.count !== 0
            && option.isVisible !== false
            && context.selectedOptions[filterType].filter((selOption) => selOption.title === option.title)
              .length === 1,
        );
        activeSelected.forEach((option) => {
          option.isSelected = true;
        });
        activeNotSelected = filterDistributions.filter(
          (option) => option
            && option.count !== 0
            && option.isVisible !== false
            && context.selectedOptions[filterType].filter((selOption) => selOption.title === option.title)
              .length === 0,
        );
      } else {
        activeNotSelected = filterDistributions.filter(
          (option) => option && option.count !== 0 && option.isVisible !== false,
        );
      }
      disabled = filterDistributions.filter(
        (option) => option && option.count === 0 && option.isVisible !== false && option.isSelected !== true,
      );

      if (upperFilterType !== 'ENGAGEMENT') {
        activeSelected.sort(sortFilterOptions);
        activeNotSelected.sort(sortFilterOptions);
        disabled.sort(sortFilterOptions);
      }
      context.filterOptions[filterType].active = activeSelected.concat(activeNotSelected);
      context.filterOptions[filterType].disabled = disabled;
    },
    cleanResults(context) {
      context.searchResults = {};
      context.countResults = 0;
      context.countPages = 1;
      context.loading = false;
    },
    toggleOptionSelectedStatus(context, option) {
      const filterType = option.optionType;
      const selectedOption = context.filterOptions[filterType].active.filter((_option) => _option.title === option.title)[0];
      if (selectedOption) {
        selectedOption.isSelected = !selectedOption.isSelected;
      }
    },
    setPrevQuery(context) {
      context.prevQuery = context.query;
    },
    setSearchPartner(context, val) {
      context.searchPartner = val;
    },
    changeTriggeredQuery(context, query) {
      if (query === null) {
        context.triggeredQuery = '';
      } else {
        context.triggeredQuery = query;
      }
    },
    setSolutionFromHomepage(context, val) {
      context.setSolutionFromHomepage = val;
    },
    setSearchFromHomePage(context, val) {
      context.searchFromHomePage = val;
    },
    setEmitNoResults(context) {
      context.emitNoResults++;
    },
    setEmitTrackResults(context) {
      context.emitTrackResults++;
    },
  },
};
export default PartnerProfilesSearch;
