<script>

const MAX_ITEM_DEFAULT_SHOW = 7;

export default {
  name: 'PFItemListComponent',
  data: () => ({
    showAllToggle: false,
    tooltipOpen: false,
  }),
  props: {
    listTitle: {
      type: String,
      required: true,
    },
    moreTitle: {
      type: String,
      required: false,
    },
    items: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    itemKey: {
      type: String,
      required: true,
    },
    itemTextKey: {
      type: String,
      required: true,
    },
    descriptionTextKey: {
      type: String,
      required: false,
      default: '',
    },
    initialDisplayCount: {
      type: Number,
      default: () => MAX_ITEM_DEFAULT_SHOW,
    },
    helpText: {
      type: String,
      default: '',
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    locationSection: {
      type: Boolean,
      default: false,
    },
    helpTextLink: {
      type: String,
      default: '',
    },
  },
  methods: {
    openLearnMore() {
      const win = window.open(this.helpTextLink, '_blank');
      win.focus();
    },
    toggleTooltip() {
      this.tooltipOpen = false;
    },
    toggleShowMore() {
      this.showAllToggle = !this.showAllToggle;
    },
  },
  computed: {
    getItems() {
      if (!this.showAllToggle && this.items && this.items.length > 0 && !this.editMode) {
        return this.items.slice(0, this.initialDisplayCount);
      } if (!this.items) {
        return [];
      }
      return this.items;
    },
    showMoreItems() {
      return this.items
        && this.items.length > this.initialDisplayCount
        && this.items.length - this.initialDisplayCount > 0;
    },
    moreItems() {
      if (this.items && this.items.length > this.initialDisplayCount) {
        return Math.abs(this.items.length - this.initialDisplayCount);
      }
      return '';
    },
  },
};
</script>

<template>
<div class="item-list-container"
     :class="locationSection ? 'col-content-min-width item-list-line-height-local' : 'item-list-line-height-global'">
  <div class="list-heading-container">
    <h3
      :class="[
        (locationSection ? 'ds-m-b-xs location-header-size' : 'ds-m-b-s service-header-size')
      ]"
      class="ds-m-t-s default-cursor view-text-color">
      {{ listTitle }}
      <div  v-if="helpText.length > 0" class="tool-tip-container" v-click-outside="toggleTooltip">
              <i class="ds-icon ds-icon--info tool-tip-icon ds-m-r-xs"
            :class="tooltipOpen ? 'tool-tip-icon-clicked' : ''" @click="tooltipOpen=!tooltipOpen"></i>
            <div class='ds-tooltip-icon toolitp-icon-item-list'>
                <div
                  :class="[tooltipOpen ? 'display-block' :
                  'display-none','tooltiptext','ds-tooltip-icon__tooltip--service']">
                   {{ helpText }}
                    <span v-if="!editMode" @click="openLearnMore" class="help-text-link">
                      <b> {{$t('learn.more')}}</b>
                    </span>
                </div>
              </div>
      </div>
    </h3>
  </div>
  <ul v-if="getItems" class="ds-list item-list ds-m-none"
      :class="locationSection ? 'location-item-size' : 'service-item-size'">
    <li
      class="ds-list__item default-cursor"
      :class="descriptionTextKey.length >0 ? 'description-item-margin' : 'ds-m-b-xs' "
      :id="item[itemKey]"
      :key="item[itemKey]"
      v-for="item in getItems">
      <div class="item-list--text"
      :class="descriptionTextKey.length >0 ? 'description-item-title--text ds-m-b-xs' :  'service-item--text'">
        {{ item[itemTextKey] }}
      </div>
      <div v-if="descriptionTextKey" class="item-list--description-service">
        {{ item[descriptionTextKey] }}
      </div>
    </li>
    <li class="ds-m-b-xs flex-list"
      v-if="showMoreItems && !editMode"
      v-show="!showAllToggle">
      <a
        href=""
        class="show-more-link"
        @click.prevent="showAllToggle = !showAllToggle">
        {{ moreItems }} more {{ moreTitle || listTitle }}
      </a>
      <ui5-icon name="slim-arrow-right"></ui5-icon>
    </li>
  </ul>

  <ul v-if="getItems.length === 0" class="ds-list item-list empty-item-list ds-m-none">
    <li class="ds-m-b-xs" :class="editMode ? 'edit-text-color' : ''">
      &mdash;
    </li>
  </ul>
</div>
</template>

<style lang="less">
@import "../../assets/css/common.less";
.custom-ui5-icon{
    vertical-align: middle;
    width: var(--sapFontSize);
    height: 24px;
    color: var(--sapLinkColor);
    fill: currentColor;
    outline: none;
  }
.item-list-container .tool-tip-container .tool-tip-icon {
    color: #223548 !important;
}
.description-item-margin {
  margin-bottom: 1rem;
}

.item-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  .item-link-font {
    margin-left: -10px;
    &:extend(.body-s-size);
  }
  .flex-list {
    display: flex;
    align-items: center;
    justify-items: center;
  }

  & li {
    // padding-left: 10px;
    // text-indent: -10px;
    text-align: left;

    div {
      &.item-list--text {
        text-align: left;
      }

      &.description-item-title--text {
        color: #223548;
        &:extend(.HeadingMediumXXXXS-size);
      }
      &.service-item--text {
        color: #223548;
        &:extend(.body-s-size);
      }

      &.item-list--description-service {
        color: #223548;
        display: block;
        text-indent: 0 !important;
        // margin-left: -10px;
        &:extend(.body-xs-size);
      }
    }
  }
}
.item-list-container {
  position: relative;
  .tool-tip-container {
    display: inline-block;
    height: 27px;
    position: relative;
    .tool-tip-icon::before {
      font-size: 16px !important;
      line-height: 26px !important;
      cursor: pointer;
    }
    .tool-tip-icon {
      color: @color-gray-4;
    }
    .tool-tip-icon:hover {
      color: @color-gray-3;
    }
  }
  .show-more-link {
     &:extend(.body-s-size);
    color: #0070F2;

    &:hover {
      color: @color-link__hover;
    }
  }
}
.ds-tooltip-icon {
  position: absolute !important; //ordering issues with ds.css loading after and overriding our override
  z-index: 3;
}
.edit-text-color {
  color: @color-icon-before;
}

// h3.edit-text-color:hover {
//   color: @color-gray-3;
// }

.toolitp-icon-item-list {
  left:100%;
  bottom: calc(100% - 20px);
}

.list-heading-container {
  width: fit-content;
  position: relative;
}
.service-header-size {
  color: #223548;
  &:extend(.HeadingMediumXS-size);
}
.location-header-size {
  color: #223548;
  &:extend(.HeadingMediumXXS-size);
}
.location-item-size {
  font-size: 14px;
}

.service-item-size {
  font-size: 16px;
}
.view-text-color {
  color: #223548;
}
</style>
