<script>
import PFSearchBarComponent from '@/components/common/PFSearchBarComponent/PFSearchBarComponent';
import PFSiteLogoComponent from '@/components/common/PFSiteLogoComponent';
import PFHelpComponent from '@/components/common/PFHelpComponent';
import PFUserProfileComponent from '@/components/common/PFUserProfileComponent';

const IE_BROWSER_PAGE = 'browserNotSupportedPage';

export default {
  name: 'PFHeaderComponent',
  props: {
    updateSearchQuery: { type: Function, default: (value) => value },
    hasTheSearchField: { type: Boolean, default: true },
    title: { type: String },
  },
  components: {
    PFSearchBarComponent,
    PFSiteLogoComponent,
    PFHelpComponent,
    PFUserProfileComponent,
  },
  data: () => ({
    windowWidth: 0,
    removeSearchBar: true,
  }),
  mounted() {
    if (this.$router.currentRoute.value.name !== IE_BROWSER_PAGE) {
      this.removeSearchBar = false;
    }
    this.$store.dispatch('systemMaintenance/fetchActiveMaintenanceData');
  },
  computed: {
    isMobile() {
      return this.windowWidth <= 979;
    },
  },
  methods: {
    showSearchBarInHeader() {
      return this.$route.path === '/' || this.$route.path.includes('editor');
    },
    isEditorPageHeader() {
      return this.$route.path.includes('editor') || this.$route.path.includes('partner-terms-of-use');
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 979) {
        this.$store.commit('solutionL2Store/setMobileMode', true);
      } else {
        this.$store.commit('solutionL2Store/setMobileMode', false);
      }
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<template>
  <div class="pf-page-header" id="pf-page-header">
    <div class="pf-header-container">
      <div class="pf-page-header__logo">
        <PFSiteLogoComponent :title="title" :editorHeader="isEditorPageHeader()" :blackHeaderLogo="true" />
      </div>
      <div v-if="!removeSearchBar && !showSearchBarInHeader()" class="pf-page-header__search">
        <Teleport to=".pf-page-small-header__search" :disabled="!isMobile" defer>
          <PFSearchBarComponent :updateSearchQuery="updateSearchQuery" />
        </Teleport>
      </div>
      <div class="pf-page-header__menu-block">
        <div class="pf-page-header__help-block" v-if="!removeSearchBar">
          <PFHelpComponent />
        </div>
        <div class="pf-page-header__profile-block" v-if="!removeSearchBar">
          <PFUserProfileComponent />
        </div>
      </div>
    </div>
    <div v-if="!removeSearchBar && !showSearchBarInHeader()" :class="{ hide: !isMobile }"
      class="pf-page-small-header__search">
    </div>
    <PFDisclaimerComponent v-show="enableMaintenanceBanner" :closeDisclaimer="closeDisclaimer" />
  </div>
</template>

<style scoped lang="less">
@import "../../assets/css/common.less";
.hide {
  display: none;
}
.pf-page-header {
  background-color: #F5F6F7;
  justify-content: center;
  width: 100%;
  padding: 0 24px;
  height: fit-content;
  .pf-page-header__help-block,
  .pf-page-header__profile-block {
    height: 100%;
    align-content: center;
  }
  .pf-header-container {
    display: flex;
    max-width: 1432px;
    height: 100%;
    max-height: 60px;
    margin: auto;
    justify-content: space-between;
  }

    .pf-page-header__search {
      margin-top: 10px;
    }

    @media(min-width:1600px) {
      .pf-page-header__search {
        min-width: 567px;
      }
    }

  .pf-page-small-header__search {
    position: relative;
    width: 100%;
  }

  .pf-page-header__logo {
    margin: 0.8px 0 0.8px 0px;
  }

  .pf-page-header__menu-block {
    display: flex;
  }
}
     @media (min-width: 640px) {
       .pf-page-header {
         padding: 0 48px;
         padding-bottom: 10px;
       }
     }

     @media (min-width: 980px) {
       .pf-page-header {
         padding: 0 72px;
       }
     }

     @media (min-width: 1600px) {
       .pf-page-header {
         padding: auto
       }
     }
</style>
