<script>
export default {
  name: "PFCompetencyLevelImage",
  props: {
    competencyLevel: { type: String, default: "" },
  },
  computed: {
    levelImageAltText() {
      return `competency-level-${this.competencyLevel}`;
    },
    competencyLevelImageSrc() {
      const levelImages = {
        ESSENTIAL: require("@/assets/Competency-lvl1-large-rest.png"),
        ADVANCED: require("@/assets/Competency-lvl2-large-rest.png"),
        EXPERT: require("@/assets/Competency-lvl3-large-rest.png"),
      };
      return levelImages[this.competencyLevel?.toUpperCase()] || levelImages.EXPERT;
    },
  },
};
</script>

<template>
  <div class="profile-competency-text" automation-id="PFCompetencyLevel">
    <div class="profile-competency-level-text HeadingMediumXXS-size">{{ competencyLevel }}</div>
    <img v-if="this.competencyLevel" :alt="levelImageAltText" :src="competencyLevelImageSrc" />
  </div>
</template>

<style lang="less" scoped>
.profile-competency-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
</style>
