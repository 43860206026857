<script>
export default {
  name: 'PFResultConsultantComponent',
  data: () => ({

  }),
  props: {
    competencyMode: { type: Boolean, default: false },
    cloudImplementationMode: { type: Boolean, default: false },
    consultantMode: { type: Boolean, default: false },
    number: { type: Number, default: 0 },
  },
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },

};
</script>

<template>
  <div class="consultant-container">
    <div class="consultant-number-tile">{{number > 0 ? numberWithCommas(number) : '&mdash;'}}</div>
    <div class="consultant-text" v-if="competencyMode" v-html="$tc('search.result.competency', number)"/>
    <div class="consultant-text" v-if="cloudImplementationMode" v-html="$tc('search.result.cloud', number)"/>
    <div class="consultant-text" v-if="consultantMode" v-html="$tc('search.result.consultant', number)"/>
  </div>
</template>

<style lang="less" scoped>
@import "../../assets/css/common.less";
.consultant-container {
  text-align: center;
  -moz-box-flex: 1;
  -webkit-box-flex: 1;
}
.consultant-number-tile {
  &:extend(.font-expanded);
  color: #223548;
  font-size: 28px;
  line-height: 31px;
  margin-bottom: 2px;
  font-family: "72 Brand Variable";
}
.consultant-text {
  &:extend(.font-normal);
  &:extend(.body-xs-size);
  color: #223548;
  font-size: 14px;
  line-height: 21px;
}
@media (max-width: 1239px) {
  .consultant-number-tile {
    font-size: 27px !important;
    line-height: 35px !important;
  }
}
@media (max-width: 950px) {
  .consultant-number-tile {
      font-size: 24px !important;
      line-height: 26px !important;
  }
}
@media (max-width: 640px) {
  .consultant-number-tile {
    font-size: 24px !important;
    line-height: 26px !important;
  }
}
@media (max-width: 579px) {
  .consultant-number-tile {
    display: block;
    float: unset;
    text-align: unset;
    width: unset;
    color: #223548;
    font-family: "72 Brand Variable";
  }
  .consultant-text {
    display: block;
    float: unset;
    margin-left: unset;
  }
  .consultant-text {
    font-size: 14px !important;
    line-height: 21px !important;
  }
}
</style>
