<script>
import { mapState } from 'vuex';
import '@sapudex/web-components/dist/Tile.js';
import PFIECompetencyModalComponent from './PFIECompetencyModalComponent';

export default {
  name: 'PFIECompetencyItemComponent',
  data: () => ({
    showProfileCompetencyModal: { status: false, current: 0 },
    // mockBusinessProcesses: [1, 2],
  }),
  props: {
    ieCompetency: { type: Array, default: () => [] },
    leftCol: { type: Boolean, default: false },
  },
  computed: {
    ...mapState('solutionL2Store', {
      solutionDataMap: (state) => state.solutionDataMap,
    }),
  },
  methods: {
    toggleModal(event, val, obj = -1) {
      if (obj !== -1) {
        this.showProfileCompetencyModal.current = obj;
      }
      this.showProfileCompetencyModal.status = val;
      if (val) {
        this.emitAdobeEvent(this.ieCompetency[obj].name);
      }
      if (event) {
        event.detail.originalEvent.preventDefault();
      }
    },
    emitAdobeEvent(name) {
      if (this.$route.name === 'profilePage') {
        this.$eventBus.$emit('PFAACustomEvent', { eventName: 'ClickModal', custom2: `IECompetency_${name}` });
      }
    },
  },
  components: {
    PFIECompetencyModalComponent,
  },
};
</script>

<template>
  <div class="flex-ie-container">
    <div
      class="udex-ie-tile"
      v-for="(comp, idx) in ieCompetency"
      :class="leftCol ? 'auto-margin-left' : ''"
      :key="comp.id + '-competency-' + idx"
      automation-id="profileIEcompetencyComponent-competencyTile"
    >
      <udex-tile level="H4" :interactive="true" @click="toggleModal($event, true, idx)">
        <ui5-title style="cursor: inherit" class="HeadingMediumXXXS-size-extended ie-tile-title">{{
          comp.name
        }}</ui5-title>
        <p class="body-xs-size ie-tile-text">{{ comp.description }}</p>
      </udex-tile>
    </div>
    <PFIECompetencyModalComponent
      v-if="this.showProfileCompetencyModal.status"
      :ieCompetency="ieCompetency[this.showProfileCompetencyModal.current]"
      :toggleModal="toggleModal"
    />
  </div>
</template>

<style lang="less" scoped>

.auto-margin-left {
  margin-left: auto;
}
.flex-ie-container {
  flex: 50%;
}
@media (max-width: 830px) {
  .flex-ie-container {
    flex: 100%;
  }
  .udex-ie-tile {
    margin-top: 16px !important;
    margin-right: auto !important;
    margin-left: auto;
  }
}
.udex-ie-tile {
  cursor: pointer;
  margin-top: 24px;
  margin-right: 24px;
  max-width: 464px;
}
.ie-tile-title {
  color: #000000;
}
.ie-tile-text {
  color: #3c3c3c;
}

  .ie-tile-text {
    height: 70px;
  }

.HeadingMediumXXXS-size-extended {
  &:extend(.HeadingMediumXXXS-size);
  line-height: 20px;
}

.process-item {
  width: 321px;
}

.process-group {
  display: flex;
  justify-content: left;
  margin-bottom: 14px;
}

.competency-header-font {
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  &:extend(.font-expanded);
}

.description-text {
  &:extend(.font-normal);
  font-size: 14px;
  line-height: 21px;
  color: @color-gray-1;
  cursor: pointer;
  margin-bottom: 3px;
}

.profile-competency-more-text:hover,
.competency-content:hover {
  color: @color-link__hover;
  .competency-header,
  .description-text {
    color: @color-link !important;
  }
  .competency-level-img {
    padding-left: 90px;
  }
  .competency-level1-img {
    background: url("../../assets/Competency-lvl1-hover.png") no-repeat;
    background-size: 90px 16px;
  }
  .competency-level2-img {
    background: url("../../assets/Competency-lvl2-hover.png") no-repeat;
    background-size: 90px 16px;
  }
  .competency-level3-img {
    background: url("../../assets/Competency-lvl3-hover.png") no-repeat;
    background-size: 90px 16px;
  }
}
</style>
