<script>
import { mapActions, mapState } from 'vuex';
import '@sapudex/web-components/dist/FilterMenu.js';
import '@sapudex/web-components/dist/FilterMenuItem.js';

const sortOptions = require('@/plugins/search/sortOptions');

export default {
  name: 'PFSortDropdownComponent',
  data: () => ({
    typesSort: sortOptions.SORT_OPTIONS,
  }),

  computed: {
    ...mapState('profileSearch', {
      countResults: (state) => state.countResults,
      selectedOptions: (state) => state.selectedOptions,
      selectedSortOption: (state) => state.selectedSortingOptionText,
      selectedSort: (state) => state.sorting,
      query: (state) => state.query,
    }),
    ...mapState('solutionL2Store', {
      solutionBadge: (state) => state.solutionBadge,
    }),
    ...mapState('filtersDrawer', {
      expandedItem: (state) => state.expandedAccordionItem,
    }),
  },
  methods: {
    ...mapActions('profileSearch', {
      cleanFilters: 'cleanFilters',
    }),
    ...mapActions('filtersDrawer', {
      setExpandedAccordionItem: 'setExpandedAccordionItem',
    }),

    handleSelectionChange(event) {
      const selectedItem = [event.detail.item];
      const key = selectedItem[0]._state.label;

      const value = () => {
        if (key === 'Alphabetical (A-Z)') {
          return 'AZ';
        }
        if (key === 'Alphabetical (Z-A)') {
          return 'ZA';
        }
        return 'RELEVANCE';
      };

      this.changeSortType(key, value());
    },
    changeSortType(key, value) {
      this.$store.dispatch('profileSearch/setSelectedSortingOptionText', key);
      this.$store.dispatch('profileSearch/setSorting', value);
      this.$eventBus.$emit('PFAACustomEvent', { eventName: 'SearchInitiated', custom2: this.query });
    },
  },
};
</script>

<template>
  <udex-filter-menu
    height="280px"
    :filter-name="selectedSortOption"
    :filter-name-prefix="$t('filter.sort.prefix')"
    desktop
    @selection-change="handleSelectionChange"
    disable-target-overlap="true"
  >
    <udex-filter-menu-item
      v-for="(value, key) in typesSort"
      :key="key"
      :label="key"
    ></udex-filter-menu-item>
  </udex-filter-menu>
</template>

<style lang="less" scoped>

</style>
