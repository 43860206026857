<script>
import PFSwaComponent from '@/components/common/PFSwaComponent';
import '@sapudex/web-components/dist/Footer.js';

const urlLinks = require('@/plugins/linkUrl');

export default {
  name: 'PFFooterComponent',
  components: {
    PFSwaComponent,
  },

  mounted() {
    this.$nextTick(() => {
      const footer = document.querySelector('udex-footer');
      if (footer) {
        footer.addEventListener('click', this.handleFooterCookiePreferencesClick);
      }
    });

    this.isIE = /Trident\/|MSIE /i.test(navigator.userAgent);
    const currentRouteName = this.$router.currentRoute.value.name;

    if (currentRouteName === 'profileEditorPageEdit' || currentRouteName === 'profileEditorPagePreview' || currentRouteName === 'partnerTermsOfUsePage') {
      this.advance[0].url = urlLinks.FOOTER.FAQ_PE;
      this.advance[2].title = 'Partner Terms of Use';
      this.advance[2].url = urlLinks.FOOTER.PARTNER_TERM_OF_USE;
    }
  },

  watch: {
    $route: {
      handler(to) {
        if (to.name === 'profileEditorPageEdit' || to.name === 'profileEditorPagePreview') {
          this.advance[0].link = urlLinks.FOOTER.FAQ_PE;
        } else {
          this.advance[0].link = urlLinks.FOOTER.FAQ_PF;
        }
      },
      deep: true,
    },
  },
  methods: {
    handleFooterCookiePreferencesClick(event) {
      const clickedElement = event.composedPath()[0];
      if (clickedElement.innerText === 'Cookie Preferences') {
        const teconsentLink = document.querySelector('#teconsent a');
        if (teconsentLink) {
          teconsentLink.click();
        }
        event.preventDefault();
      }
    },
  },
  beforeDestroy() {
    const footer = document.querySelector('udex-footer');
    if (footer) {
      footer.removeEventListener('click', this.handleFooterClick);
    }
  },
  data: () => ({
    sapLogo: 'https://cdn.udex.services.sap.com/dds/design-tokens/assets/logos/sap-logo.svg',
    homePage: '/',
    main: [
      { title: 'Partner with Us', url: urlLinks.FOOTER.PARTNER_WITH_US },
      { title: 'About SAP Partner Finder', url: urlLinks.FOOTER.ABOUT_PF },
      { title: 'SAP Business Technology Platform', url: urlLinks.FOOTER.BTP },
    ],
    advance: [
      {
        title: 'FAQ',
        url: urlLinks.FOOTER.FAQ_PF,
      },
      { title: 'Privacy', url: urlLinks.FOOTER.PRIVACY },
      { title: 'Terms of Use', url: urlLinks.FOOTER.TERM_OF_USE },
      { title: 'Legal Disclosure', url: urlLinks.FOOTER.LEGAL_DISCLOSURE },
      { title: 'Copyright', url: urlLinks.FOOTER.COPY_RIGHT },
      { title: 'Trademark', url: urlLinks.FOOTER.TRADE_MARK },
      { title: 'Cookie Preferences', url: '#' },
    ],
    socialLinks: [
      {
        iconUrl: 'https://cdn.udex.services.sap.com/dds/design-tokens/assets/icons/facebook.svg',
        url: 'https://www.facebook.com/SAP',
      },
      {
        iconUrl: 'https://cdn.udex.services.sap.com/dds/design-tokens/assets/icons/youtube.svg',
        url: 'https://www.youtube.com/user/SAP',
      },
      {
        iconUrl: 'https://cdn.udex.services.sap.com/dds/design-tokens/assets/icons/instagram.svg',
        url: 'https://instagram.com/sap/',
      },
      {
        iconUrl: 'https://cdn.udex.services.sap.com/dds/design-tokens/assets/icons/linkedin.svg',
        url: 'https://www.linkedin.com/company/sap/',
      },
    ],
    isIE: true,
  }),
};
</script>

<template>
<div class="footer-container">
  <PFSwaComponent  class="cookie-text" v-show="false"/>
  <udex-footer
    id="footer"
    :quick-links="JSON.stringify(main)"
    :site-information-links="JSON.stringify(advance)"
    :logo-url="sapLogo"
    :logo-link="this.homePage"
    :social-links="JSON.stringify(socialLinks)"
  ></udex-footer>
</div>
</template>

<style lang="less" scoped>

.footer-container{
  width: 100%;
  height: 100%
}
</style>
