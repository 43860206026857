<script>
import PFAlternativeNamesComponent from '@/components/common/PFAlternativeNamesComponent';

export default {
  name: 'PFPartnerTitleComponent',
  components: { PFAlternativeNamesComponent },
  props: {
    title: { type: String, default: '' },
    brandNames: { type: Array, default: () => [] },
    link: { type: String, default: '' },
    toggleAlternativeNamesModal: { type: Function, default: () => {} },
  },
  data: () => ({
    isRequireAdditionalSpace: false,
  }),
  methods: {
    withoutProtocol(url) {
      return url ? url.replace(/(^\w+:|^)\/\//, '') : '';
    },
  },
  mounted() {
    this.isRequireAdditionalSpace = document.getElementById('partner_profile_name_filed').offsetHeight > 30;
  },
  computed: {
    resolveNameClass() {
      return [this.isRequireAdditionalSpace ? 'ds-m-y-l' : 'ds-m-y-xl'];
    },
    resolveUrl() {
      if (!/^https?:\/\//.test(this.link)) {
        return `http://${this.link}`;
      }
      return this.link;
    },
  },
};
</script>

<template>
<div class="ds-m-x-none ds-p-none partner-name ds-p-l-m">
  <div class="partner-name--name" id="partner_profile_name_filed" automation-id="partnerProfilePage-partnerName">
    <div class="partner-name--text">{{title}}</div>
  </div>
  <div v-if="brandNames && brandNames.length > 0" class="partner-name--brand-names">
    <PFAlternativeNamesComponent
    :brandNames="brandNames"
    :title="title"
    :toggleAlternativeNamesModal="toggleAlternativeNamesModal"
    />
  </div>
  <div class="partner-name--website">
    <a @click="$eventBus.$emit('PFCustomEvent',
      {eventName: 'NavigateExternal', custom2: 'Website'})"
      v-bind:href="resolveUrl" :target="'_blank'">{{withoutProtocol(link)}}</a>
  </div>
</div>
</template>

<style lang="less">
.partner-name {
  width: 100%;
  margin-top: auto;
  margin-bottom:auto;
  &:extend(.font-normal);
  .partner-name--name {
    font-size: 21px;
    overflow-y: hidden;
    max-height: 55px;
    width: fit-content;
    display: flex;
  }
  .partner-name--text {
    font-size: 21px;
    line-height: 27px;
    cursor: default;
  }
  .partner-name--website {
    a {
      width: 118px;
      height: 18px;
      font-size: 12px;
      line-height: 1.5;
    }
  }
}
</style>
