<script>
import { mapState } from 'vuex';
import '@sapudex/web-components/dist/Dropdown.js';
import '@sapudex/web-components/dist/List.js';
import '@sapudex/web-components/dist/ListItem.js';

export default {
  name: 'PFUserProfileComponent',
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  props: {
    editorHeader: { type: Boolean, default: false },
  },
  data() {
    return {
      dropdownEnabled: false,
    };
  },
  mounted() {
    this.$store.dispatch('userProfile/doLoginStatus');
  },
  computed: {
    ...mapState('userProfile', {
      loggedIn: (state) => state.loggedIn,
      avatarText: (state) => {
        let text = '';
        if (state.user.givenName) {
          text = text.concat(state.user.givenName[0]);
        }
        if (state.user.familyName) {
          text = text.concat(state.user.familyName[0]);
        }
        return text === '' ? 'A' : text.toUpperCase();
      },
      givenName: (state) => state.user.givenName,
      profileAuthorizations: (state) => state.user.profileAuthorizations,
    }),
  },
  methods: {
    doLogin() {
      if (!this.loggedIn) {
        this.$store.dispatch('userProfile/doLogin');
      } else {
        this.dropdownEnabled = !this.dropdownEnabled;
      }
    },
    doLogout() {
      window.location.replace('/logout');
    },
    hideDropdown() {
      this.dropdownEnabled = false;
    },
    toggleDropdown() {
      this.dropdownEnabled ? this.$refs.dropdown.onOpen() : this.$refs.dropdown.onClose();
    },
    toMyPartnerProfile() {
      const path = `/editor/${this.profileAuthorizations.pgiId}/edit`;
      window.location = path;
    },
    changeIconColor() {
      this.$refs.avatarIcon.classList.remove(this.editorHeader ? 'pe-avatar-color' : 'pf-avatar-color');
      this.$refs.avatarIcon.classList.add(this.editorHeader ? 'pe-avatar-color-hover' : 'pf-avatar-color-hover');
    },
    changeIconColorBack() {
      if (!this.dropdownEnabled) {
        this.$refs.avatarIcon.classList.remove(this.editorHeader ? 'pe-avatar-color-hover' : 'pf-avatar-color-hover');
        this.$refs.avatarIcon.classList.add(this.editorHeader ? 'pe-avatar-color' : 'pf-avatar-color');
      }
    },
    handleScroll() {
      if (document.getElementsByClassName('edit-header')[0]) {
        const headerBottom = document.getElementsByClassName('edit-header')[0].offsetHeight;
        if (!this.editorHeader && window.scrollY > headerBottom) {
          this.hideDropdown();
        } else if (this.editorHeader && window.scrollY < headerBottom) {
          this.hideDropdown();
        }
      }
    },
  },
  watch: {
    dropdownEnabled(val) {
      if (val) {
        this.$refs.rectangleIndicator.style.visibility = 'visible';
        this.changeIconColor();
      } else {
        this.$refs.rectangleIndicator.style.visibility = 'hidden';
        this.changeIconColorBack();
      }
    },
  },
};
</script>

<template>
  <div class="account-dropdown-container">
    <udex-dropdown v-if="loggedIn" @click-trigger="toggleDropdown" id="dropdown" ref="dropdown"
      horizontal-align="Center">
      <div slot="trigger" class="account-button-container">
        <button class="ds-button ds-select-button account-dropdown-btn" @click="doLogin" v-click-outside="hideDropdown"
          @mouseover="changeIconColor" @mouseleave="changeIconColorBack">
          <i class="ds-avatar--s logon_avatar" ref="avatarIcon"
            :class="editorHeader ? 'pe-avatar-color' : 'pf-avatar-color'">
            {{ avatarText }}
          </i>
        </button>
        <div class="rectangle avatarIndicator" ref="rectangleIndicator" />
      </div>
      <div class="dropdown-option-get-account">
        Welcome, {{ givenName }}
      </div>
      <udex-list>
        <udex-list-item v-if="profileAuthorizations && profileAuthorizations.pgiId" @click="toMyPartnerProfile">
          Manage My Company Profile
        </udex-list-item>
        <udex-list-item @click="doLogout">
          Logout
        </udex-list-item>
      </udex-list>
    </udex-dropdown>
    <button v-if="!loggedIn" class="ds-button ds-select-button account-dropdown-btn user-login-icon-container"
      ref="avatarIcon" @click="doLogin">
      <svg ref="helpIcon" :class="editorHeader ? 'pe-header-icon-color' : 'pf-header-icon-color'">
        <use xlink:href="@/assets/person-placeholder.svg#person-icon"></use>
      </svg>
      <div class="rectangle userIndicator" ref="userRectangleIndicator" />
    </button>
  </div>
</template>

<style scoped lang="less">
@import "../../assets/css/common.less";
#dropdown {
  display: flex;
  align-items: center;
}
.account-button-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60px;
  position: relative;
  justify-content: center;
}
.account-dropdown-container{
  display: flex;
}
.logon_avatar {
  vertical-align: middle;
  width: 28px;
  height: 28px;
  position: static;
  box-sizing: border-box;
  margin-top: 1px;
  &:hover{
    border: 1px solid @color-blue-light-1;
  }
}

.ds-select-button__dropdown_visible {
  display: block;
}
.ds-select-button__dropdown {
  min-width: 12px;
  position: absolute;
  bottom: 0;
}
.ds-select-button__dropdown__inner {
  background-color: @color-white;
  position: absolute;
}
.ds-select-button__dropdown__inner__option {
  margin: 0;
  padding: 0;
}
.account-dropdown-btn,
.account-dropdown-btn:focus,
.account-dropdown-btn:hover,
.ds-select-button__dropdown {
  background-color: transparent;
}

.account-dropdown-btn {
  text-align: center;
  padding: 0;
  line-height: 80px;
  display: inline-flex;
  border: none;
  margin: 0 7px;
  color: @color-blue-gray-light;
  svg{
    width: 18px;
    height: 18px;
    fill: currentColor;
  }
  .logon_avatar {
    line-height: 80px;
  }
}
.account-dropdown-btn:hover {
  color: @color-blue-light-1;
}

.avatarIndicator {
  position: absolute;
  bottom: 0px;
}
.rectangle {
  width: 28px;
  height: 4px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: @color-blue-light-1;
  visibility: hidden;
}
.user-login-icon-container {
  .user-login-icon {
    position: relative;
    &::before {
    font-size: 28px;
  }
  }
}
.user-login-icon:hover {
  .rectangle {
    visibility: visible !important;
  }
}
.userIndicator {
  position: absolute;
  bottom: 0px;
  width: 28px;
}

.dropdown-option-get-account {
  &:extend(.font-expanded);
  font-size: 21px;
  line-height: 27px;
  padding: 10px 12px;
}
.account-dropdown {
  width: 319px;
  height: fit-content;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.21);
  background-color: @white-color;
  right: -300%;
  margin-top: -1px;
  .ds-select-button__dropdown__inner__option {
    font-size: 16px !important;
    line-height: 24px !important;
  }
}

.pf-avatar-color,.pe-avatar-color {
  background-color: @color-grey-5;
  color: @black-color;
}

.pf-avatar-color-hover,.pe-avatar-color-hover {
  background-color: @white-color;
  color: @black-color;
}

.pf-user-icon:hover {
  color: @white-color !important;
}
.pf-user-icon {
  color: @color-grey-5 !important;
}
.pe-user-icon:hover {
  color: @color-gray-1 !important;
}
.pe-user-icon {
  color: @color-gray-3 !important;
}
</style>
