<script>
import { mapState } from 'vuex';
import '@sapudex/web-components/dist/Tile';
import '@sapudex/design-tokens/dist/css/sap_glow.css';
import PFResultConsultantComponent from '@/components/common/PFResultConsultantComponent';

const LARGESCREEN = 525;
const SMALLSCREEN = 332;
const NODE_ENV = PROCESS_NODE_ENV;
const SHOW_CLOUD_IMPL_ENV = 'none';
const NOT_FOUND_VALUE = 'not found';

export default {
  name: 'PFCardItemComponent',
  props: {
    data: { type: Object, default: () => {} },
    isLocationApplied: { type: Boolean, default: false },
    idx: { type: Number, default: 0 },
  },
  components: {
    PFResultConsultantComponent,
  },
  data: () => ({
    defaultIconText: '',
    MAX_CHAR_SHOW: 40,
    MAX_CHAR_SHOW_SHORT: 23,
    MAX_CHAR_SHOW_DESCRIPTION: 80,
    urlError: false,
    nodeEnv: NODE_ENV,
    showCloudImplEnv: SHOW_CLOUD_IMPL_ENV,
    textWidth: 267,
    maxChar: 35,
  }),
  computed: {
    query: {
      get() {
        return this.$store.state.autocomplete.query;
      },
    },
    ...mapState('profileSearch', {
      lastUsedQuery: (state) => state.lastUsedQuery,
      pageNumber: (state) => state.pageNumber,
    }),
  },
  methods: {
    imgUrlAlt() {
      this.urlError = true;
    },
    extractDataFromTitle() {
      const myStr = this.data.title ? this.data.title : NOT_FOUND_VALUE;
      const matches = myStr.trim().replace(/\s\s+/g, ' ').split(' ');
      return matches.length > 1 ? matches
        .slice(0, 2)
        .map((letter) => letter.charAt(0).toUpperCase())
        .join('') : myStr.charAt(0);
    },
    getLocation(option) {
      const countries = option.country;
      return countries ? countries.join('') : '';
    },
    getTitleSubstring(title) {
      const match = /\w/;
      if (match.test(title)) {
        return title.substring(0, this.MAX_CHAR_SHOW);
      }
      return title.substring(0, this.MAX_CHAR_SHOW_SHORT);
    },
    showTitleOverlay(title) {
      return title.length > this.getTitleSubstring(title).length;
    },
    onResize() {
      this.textWidth = this.$refs.cardItem.clientWidth;
      if (this.textWidth > LARGESCREEN) {
        this.maxChar = Math.round(this.textWidth / 10);
      } else if (this.textWidth <= LARGESCREEN && this.textWidth > SMALLSCREEN) {
        this.maxChar = Math.round(this.textWidth / 14);
      } else {
        this.maxChar = Math.round(this.textWidth / 20);
      }
    },
    openProfilePage() {
      this.$eventBus.$emit('PFAACustomEvent',
        {
          eventName: 'clickSearchResult', custom2: this.lastUsedQuery, custom3: (this.idx + (this.pageNumber - 1) * 12), custom4: this.data.profileId,
        });
      this.$store.dispatch('profileSearch/setPathChangedFromUiFlag', true);
    },
  },
};
</script>

<template>
  <div class="search-tile-container-nd">
    <resize-observer @notify="this.onResize"></resize-observer>
    <udex-tile
      level="H4"
      :interactive="true"
      :href="`/profile/${data.profileId}`"
      @click="openProfilePage()"
      class="new-ds-card__tile--inner-content" ref="cardItem">
      <div
          v-bind:class="[data.logoUrl && !urlError ? 'ds-card__tile-data__icon' : 'ds-card__tile-data__icon-default']"
          class="add-height"
        >
          <div class="ds-card__tile--link ds-card__tile--icon">
            <img class="search-partner-icon" loading="lazy" v-if="data.logoUrl && !urlError" :src="data.logoUrl" @error="imgUrlAlt"/>
            <div class="ie-tile-title no-img" v-if="!data.logoUrl || urlError">{{ extractDataFromTitle() }}</div>
          </div>
        </div>
      <div class="ds-card__tile-data">
        <div class="search-card-header">
          <div class="ds-card__tile-name">
            {{data.title}}
          </div>
          <div v-if="data.favoriteBrandName" class="ds-card__tile-brand-names">
            {{data.favoriteBrandName}}
          </div>
          <div v-if="isLocationApplied" class="ds-card__tile-counties">{{getLocation(data)}}</div>
        </div>
      </div>
     <div
      class="new-ds-search-tile-description"
      :class="[data.favoriteBrandName ? 'ds-card__tile-description-brand_names'
      : 'ds-card__tile-description', data.favoriteBrandName ? 'search-tile-description-2-line'
      : 'search-tile-description-3-line']"
      v-html="data.description ? $sanitize(data.description) : ''">
      </div>
  <div class="consultant-cloud-container">
          <PFResultConsultantComponent v-if="nodeEnv === this.$showConsultantEnv"
            :number="data.consultants" :consultantMode="true"/>
          <PFResultConsultantComponent v-if="nodeEnv === showCloudImplEnv"
            :cloudImplementationMode="true" :number="data.cloudImplementations"/>
          <PFResultConsultantComponent
            v-if="data.competencyTotal && data.competencyTotal > 0 && !this.$hideCompetencyEnv"
            :competencyMode="true" :number="data.competencyTotal"/>
      </div>
  </udex-tile>
</div>
</template>

<style lang="less">
@import "../../assets/css/common.less";
.new-ds-search-tile-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
}
.search-partner-icon-text {
  overflow-wrap: normal;
}
.new-ds-card__tile {
  display: contents;
}
.udex-tile {
  height: 360px !important;
}

.new-ds-card__tile--inner-content {
  height: 22px;
  box-sizing: border-box;

  .ds-card__tile-data {
    height: 55px;

    .ds-card__tile-data__icon {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 25px;
      width: 82px;
      height: 70px;
      .ds-card__tile--icon {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: 100%;
        max-height: 62px !important;
        object-fit: fill;
      }
      .ds-card__tile--icon img {
        max-height: none !important;
      }
    }

    .ds-card__tile-data__icon-default {
      margin-left: auto;
      margin-right: auto;
      width: 62px;
      height: 62px;
      background-color: #eaeaea;

      div {
        &:extend(.font-bold-expanded);
        width         : 56px;
        height        : 56px;
        font-size     : 27px;
        font-stretch  : normal;
        font-style    : normal;
        line-height   : 56px;
        letter-spacing: normal;
        text-align    : center;
        color         : @color-gray-3;
        margin        : 2px 3px 2px 3px;
      }
    }
    .search-card-header {
      height: fit-content;
      margin-top: auto;
      margin-bottom: auto;
    }
    .ds-card__tile-name {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box !important;
      -webkit-line-clamp: 2 !important;
      -webkit-box-orient: vertical !important;
      &:extend(.HeadingMediumXXS-size);
      color: #000000;
    }
    .ds-card__tile-brand-names {
      &:extend(.HeadingMediumM-size);
      color: @color-gray-2;
      margin-top: 8px;
      margin-bottom: 12px;
      line-height: 14px;
      font-size: 15px;
      color: #475E75;
    }

    .ds-card__tile-counties {
      height        : 18px;
      overflow      : hidden;
      font-size     : 12px;
      font-stretch  : normal;
      font-style    : normal;
      line-height   : 1.5;
      letter-spacing: normal;
      color: #222222;
    }
  }

  .ds-card__tile--icon img {
    max-width: 112px;
    max-height: 56px;
  }

  .ds-card__tile--icon {
    height: 56px;
    margin: 1.5rem auto 2rem;
    max-height: 56px !important;
  }

  .ds-card__tile-description {
    &:extend(.body-xs-size);
    overflow      : hidden;
    text-overflow : ellipsis;
    width         : 100%;
    letter-spacing: normal;
    color: #223548;
    height: 64px;
  }

  .ds-card__tile-description-brand_names {
    &:extend(.body-xs-size);
    overflow      : hidden;
    text-overflow : ellipsis;
    width         : 100%;
    letter-spacing: normal;
    color: #223548;
    height: 44px;
    margin-top: 20px;
  }

  .search-tile-description-2-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
  .search-tile-description-3-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical !important;
  }

  .ds-card-rating-block {
    display: flex;
    margin-top: 5%;
    text-align: center;

    .ds-card-rating__stars {
      display: block;
      margin-right: auto;
      width: 40%;
    }
  }
}
.fade-overlay {
  position: relative;
}

.fade-overlay::before{
  content: "";
  background-image: linear-gradient(90deg,hsla(0,0%,100%,0),@color-white);
  width: 2rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}
.consultant-cloud-container {
  display: -moz-box;
  display: -webkit-box;
  justify-content: center;
  margin-top: 31px;
}
.search-partner-icon {
  object-fit: contain;
  width: 100%;
}
.no-img {
  &:extend(.HeadingMediumS-size);
  color: #475E75;
  background-color: #F5F6F7;
  width: 112px;
  text-align: center;
  height: 56px;
  padding: 13px;
  margin-top: -10px;
  border-radius: 6px;
}

@media (max-width: 1279px) {
  udex-tile {
    height: 300px !important;
  }

  .consultant-cloud-container {
    margin-top: 28px;
    min-height: 57px !important;
  }
}
@media (max-width: 639px) {
  .ds-card__tile--icon img {
    max-width: 100px !important;
    max-height: 50px !important;
    margin-bottom: 8px;
  }
  .new-ds-card__tile--inner-content .ds-card__tile-data .ds-card__tile-name {
    font-size: 16px;
    line-height: 18px;
    height: 36px;
  }
  .new-ds-card__tile--inner-content .ds-card__tile--icon {
    height: 40px;
    margin: 10px auto 10px;
  }
  .new-ds-card__tile--inner-content .ds-card__tile-data {
    height: unset;
  }
  .new-ds-card__tile--inner-content .ds-card__tile-description {
    height: 45px;
  }
  .new-ds-card__tile--inner-content .search-tile-description-2-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
  }
  .new-ds-card__tile--inner-content .search-tile-description-3-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
  .new-ds-card__tile--inner-content .ds-card__tile-description-brand_names {
    margin-top: unset;
    height: 23px;
    display: -webkit-box;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
  }
  .no-img {
    &:extend(.HeadingMediumXXS-size);
    width: 84px;
    height: 42px;
    padding: 6px;
    margin-top: -10px;
  }
}
</style>
