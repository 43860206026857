<script>
import urlLinks from '../../plugins/linkUrl';

import '@sapudex/web-components/dist/Modal.js';
import '@sapudex/web-components/dist/Button.js';
import '@ui5/webcomponents-fiori/dist/Bar.js';
import '@sapudex/web-components/dist/ControlButton.js';

export default {
  name: 'PFRiseWithSAPModalComponent',
  data: () => ({
    learnMore: urlLinks.HELP_TEXT_LEARN_MORE.RISE_WITH_SAP,
    learnMoreRiseValidated: urlLinks.HELP_TEXT_LEARN_MORE.RISE_WITH_SAP_VALIDATED_PARTNER,
    maxWidth: 544,
    isOverflownFlag: false,
  }),
  props: {
    closePopup: { type: Function, default: () => {} },
    isRiseValidated: { type: Boolean, default: false },
  },
  computed: {
    maxWidthStyle() {
      return `${this.maxWidth}px`;
    },
  },
};
</script>

<template>
  <udex-modal automation-id="profileRecognitionModal" type="default" :open="true" @keydown.esc="closePopup">
    <ui5-bar class="header" slot="header" design="Header" data-sap-ui-fastnavgroup="true">
      <h3 class="HeadingMediumXS-size" slot="startContent" automation-id="profileRecognitionModal-header">
        {{ $t('rise.SAP.validated.title') }}
      </h3>
      <udex-control-button
        automation-id="profileRecognitionModal-closeDialog"
      @click="closePopup"
        class="closeDialog"
        slot="endContent"
        icon-only
        icon="decline"
        has-icon
        accessible-name="close"
      ></udex-control-button>
    </ui5-bar>

        <div automation-id="profileRecognitionModal-container" class="rise-container" ref="riseContainer">
          <div class="solution-section-title">
              <img automation-id="profileRecognitionModal-logo" class="RwS-validated-logo" src="@/assets/RISEwithSAP_logo.png"/>
          </div>
          <div class="body-s-size" automation-id="profileRecognitionModal-description">
            {{$t('rise.SAP.validated.text') }}
            <udex-link :href="learnMoreRiseValidated" target="_blank" rel="noopener noreferrer" accessibleRole="Link">
              {{$t('learn.more')}}
            </udex-link>          
          </div>
        </div>

    <ui5-bar class="footer" slot="footer" design="Footer" data-sap-ui-fastnavgroup="true">
      <udex-button automation-id="profileRecognitionModal-closeButton"
      @click="closePopup" design="Emphasized" size="Large" slot="endContent">
        {{ $t("button.close") }}
      </udex-button>
    </ui5-bar>
  </udex-modal>
</template>

<style lang="less" scoped>
.RwS-validated-logo {
 width: 148px;
 height: 54px;
}
.solution-section-title {
  text-align: center;
  margin-bottom: 20px;
}
.rise-container {
  max-width: v-bind('maxWidthStyle');
}

.closeDialog {
  margin-left: 30px;
  --udex-button-icon-l-size: 24px;
  --udex-button-color-tertiary-standard-default-text-and-icon: var(--udexColorGrey7, #475E75);
}

 .footer udex-button {
  margin: 0 0 0 16px;
}

 ui5-bar::part(bar) {
  /* Alternativelly header and footer paddings can be reduced to utilize 'ui5-bar' paddings.
  In this case overwrite following variables:
    --udexModalAndOverlayModalFooterPaddingHorizontal
    --udexModalAndOverlayModalHeaderPaddingHorizontal
  */
  margin-left: -12px;
  margin-right: -12px;
  width: auto;
}

 h3 {
  margin: 0px;
}

</style>
